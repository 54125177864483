import React, { useState } from 'react';
import { Radio, Tooltip, CircularProgress } from "@mui/material";
import { switchDefaultNetworkAddress } from '../../api/defaultNetworkWallet';
import { useAuth } from '../web3/context/AuthContext';
import { toast } from "react-hot-toast";


const WalletMenuOption = ({ selection, loading, setRecommendedWalletWithNetwork, switchDefaultWallet, switchWallet }) => {
    const [checked, setChecked] = useState(false);
    const [checkedNetwork, setCheckedNetwork] = useState(false);
    const { network, entityInfo } = useAuth()
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    React.useEffect(() => {
        const ChainName = network.chain == "CASPER" ? "CASPER" : "EVM"
        if (entityInfo.userWalletsData[ChainName]) {
            setChecked(
                entityInfo.userWalletsData[ChainName].defaultReceivingAddress ==
                selection.title
            );
            setCheckedNetwork(
                entityInfo.userWalletsData[ChainName][network.chain]?.receivingAddress ==
                selection.title || false
            );
        }
    }, []);
    React.useEffect(() => {
        const handleResize = () => {
          const screenWidth = window.innerWidth;
          if (screenWidth < 922) {
            setIsSmallScreen(true);
          } else {
            setIsSmallScreen(false);
          }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
    return (
        <div className='d-flex'>

            <Tooltip
                placement="top"
                title={"Make this wallet the default receiver"}
            >
                <div>
                    {loading ? <CircularProgress size={28} /> :
                        <Radio
                            checked={checked}
                            onChange={() => {
                                switchDefaultWallet(selection)
                            }}
                            name="default-wallet"
                            color="primary"
                            inputProps={{ "aria-label": "select default wallet" }}
                        />
                    }
                </div>
            </Tooltip>
            <Tooltip
                placement="top"
                title={"Make this wallet recommended receiver with this network"}
            >
                <div>
                    {loading ? <CircularProgress size={28} /> :
                        <Radio
                            checked={checkedNetwork}
                            onChange={(e) => {
                                setRecommendedWalletWithNetwork(selection)
                            }}
                            name="default-wallet"
                            color="primary"
                            inputProps={{ "aria-label": "select recommended wallet" }}
                        />}
                </div>
            </Tooltip>
            <div onClick={() => switchWallet(selection)} className='d-flex justify-content-center align-items-center ms-2'>
                <span className='selected-wallet mouse-cursor'>{isSmallScreen ? `...${selection.title.slice(-12)}`: selection.title}</span>
            </div>
        </div>
    );
};

export default WalletMenuOption;
