import { useState } from "react";
import { useAuth } from "../../components/web3/context/AuthContext";
import BackButton from "../../components/common/backButton";

import ClaimNfts from "./claim-nft";
import MyNfts from "./my-nft";
import MintSection from "./mint/mint-section";
import CollectionManager from "../../components/collections-manager/create-collection/collection-manager";

export default function NftGallery() {

  const { isLoggedIn, loginOption } = useAuth();
  const [activeClass, setActiveClass] = useState("mynfts");

  const handleFilterKeyChange = (key) => () => {
    setActiveClass(key);
  };

  const handleActiveClass = (key) => {
    if (key === activeClass) return "active";
  };

  return (
    <div className="fugu--portfolio-section fugu--section-padding">
      <div className="container">
        <BackButton />
        {isLoggedIn ? (
          <div className="container">
            <div className="fugu-gallery-menu">
              <ul id="watch-filter-gallery" className="option-set clear-both">
                <li
                  onClick={handleFilterKeyChange("mynfts")}
                  className={handleActiveClass("mynfts")}
                >
                  My NFTs
                </li>
                {loginOption != "non_custodial" && (
                  <li
                    onClick={handleFilterKeyChange("claimnfts")}
                    className={handleActiveClass("claimnfts")}
                  >
                    Claim NFTs
                  </li>
                )}

                <li
                  onClick={handleFilterKeyChange("mycollections")}
                  className={handleActiveClass("mycollections")}
                >
                  Collections
                </li>
                {/* <li
                  onClick={handleFilterKeyChange("mintnfts")}
                  className={handleActiveClass("mintnfts")}
                >
                  Mint NFTs
                </li> */}
              </ul>
            </div>
            {activeClass === "mynfts" && <MyNfts />}
            {activeClass === "claimnfts" && <ClaimNfts />}
            {activeClass === "mintnfts" && <MintSection />}
            {activeClass === "mycollections" && <CollectionManager />}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="header-btn">
              <a className="fugu--btn fugu--menu-btn1" href="/">
                Connect with system
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
