import React, { useState, useEffect, useRef } from "react";
import { Modal, CloseButton, ModalHeader } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import { Checkbox } from "@mui/material";
import Confetti from 'react-confetti';
import { useAuth } from "../web3/context/AuthContext";
import { claimNft } from "../web3/nfts-utils/nfts-proxy/NftsProxy_Utils";
import { openPasswordModel } from "../social-wallet/PasswordModal";
import toast from "react-hot-toast";
import OutLineButtonLoader from "../common/preloader/buttonLoader";
function CongratsModal({
    show,
    handleCloseParent,
    benefits
}) {
    const modalRef = useRef(null);
    const [showModal, setShowModal] = useState(show);
    const [passedBenefits, setPassedBenefits] = useState(benefits)
    const [modalWidth, setModalWidth] = useState(0);
    const [renderConfitti, setRenderConfitti] = useState(false)
    const [slide, setSlide] = useState(true)
    const [confittiHeight, setConfittiHeight] = useState()
    const [dontShow , setDontShow] = useState(false)
    const [disableBtn , setDisableBtn] = useState(false)
    const {
        balance,
        email,
        hasPassword,
    } = useAuth()

    const handleClose = () => {
        handleCloseParent();
        setShowModal(false)
    };
    useEffect(() => {
        setPassedBenefits(benefits)
        console.log(benefits)
    }, [benefits])
    const [screenSize, setScreenSize] = useState(3)
    useEffect(() => {
        const handleResize = () => {
          const screenWidth = window.innerWidth;
          if (screenWidth >= 1200) {
            setScreenSize(3);
          }else if (screenWidth >= 768){
            setScreenSize(2)
          }else {
            setScreenSize(1)
          }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
    useEffect(() => {
        if (modalRef.current) {
            const width = modalRef.current.clientWidth;
            setModalWidth(width);
            setConfittiHeight(window?.innerHeight)
            setTimeout(() => {
                setRenderConfitti(true)
            }, 1000);

        }
    }, [show]);
    const updateNftAfterMint = (elem, objectIndex) => {
        // Remove the claimed NFT from passedBenefits.nftsArr[objectIndex].nftsPerChain
        const updatedNftsArr = passedBenefits.nftsArr.map((item, index) => {
            if (index === objectIndex) {
                // Filter out the claimed NFT from the nftsPerChain array
                const updatedNftsPerChain = item.nftsPerChain.filter(nft => nft !== elem);
                return { ...item, nftsPerChain: updatedNftsPerChain };
            }
            return item;
        });
        // Update the state with the modified nftsArr
        setDisableBtn(false)
        setPassedBenefits(prev => ({ ...prev, nftsArr: updatedNftsArr }));
    }

    const claimUserNft = async (elem, network, objectIndex) => {
        try {

            setDisableBtn(true)
            setSlide(false)
            if (hasPassword) {
                openPasswordModel(claimPassSecureNft, {
                    email: email,
                    elem: elem,
                    network: network.chain,
                    objectIndex: objectIndex
                },
                setDisableBtn
                );
            } else {
                await claimNft(email, elem, network.chain);
                setSlide(true)
                updateNftAfterMint(elem, objectIndex)
                //remove the minted nft
            }
        } catch (e) {
            toast.error(e.message)
            setDisableBtn(false)
        }
    };
    const claimPassSecureNft = async (password, input) => {
        if (password == null) return;
        if (password === "") return toast.error("Password is required");
        console.log("input", input);
        try {
            await claimNft(email, input.elem, input.network, password);
            updateNftAfterMint(input.elem, input.objectIndex)
        } catch (err) {
            console.log(err, "Error on claim nft");
            setDisableBtn(false)
        }
        setSlide(true)
    };
    const handleDontShow = async(e)=>{
        setDontShow(e.target.checked)
        let localData = localStorage.getItem(`userDepositedNfts-address-${email}`)
        localData = JSON.parse(localData)
        localData.showMeAgain = !e.target.checked
        // check if there are already have deprecated values 
        // debugger
        if(localData.deprecated && localData.deprecated?.nftsArr.length > 0 ){
                // If deprecated values exist, merge the new nftsArr with the existing deprecated nftsArr based on chain
                localData.nftsArr.forEach(newNftObj => {
                    const index = localData.deprecated.nftsArr.findIndex(oldNftObj => oldNftObj.chain.chain === newNftObj.chain.chain);
                    if (index !== -1) {
                        // If the chain.chain already exists in deprecated nftsArr, merge nftsPerChain arrays
                        localData.deprecated.nftsArr[index].nftsPerChain.push(...newNftObj.nftsPerChain);
                    } else {
                        // If chain.chain doesn't exist, add the new nft object to deprecated nftsArr
                        localData.deprecated.nftsArr.push(newNftObj);
                    }
                });
        }else{
            localData.deprecated = {
                nftsArr: localData.nftsArr,
                tokensFundArr: localData.tokensFundArr
            }
        }

        // Remove nftsArr and tokensFundArr from localData
        delete localData.nftsArr;
        delete localData.tokensFundArr;
        console.log(localData)
        localStorage.setItem(`userDepositedNfts-address-${email}` , JSON.stringify(localData))
        handleClose()
    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            aria-labelledby="contained-modal-title-vcenter"
            size="xl"
            className="bordered-modal"
            id="password-modal"

        >
            <ModalHeader>
                <CloseButton
                    onClick={handleClose}
                    className="btn-close-white"
                    style={{ position: "absolute", right: "20px", top: "20px" }}
                />
            </ModalHeader>
            <Modal.Title>
                <h1>CONGRATULATIONS</h1>
            </Modal.Title>
            <Modal.Body ref={modalRef}>
                {renderConfitti && (
                    <Confetti
                        width={modalWidth}
                        height={confittiHeight}
                        recycle={true}
                    />
                )}
                <div className="justify-center mb-5">
                    {passedBenefits && passedBenefits.nftsArr?.length > 0 &&
                        <div>
                            <h2 className="ms-5 mt-5">NFTS</h2>
                            <Carousel controls={true} slide={slide}>
                                {passedBenefits.nftsArr.map((item, itemIndex) => { // Add itemIndex parameter
                                    const chunks = [];
                                    for (let i = 0; i < item.nftsPerChain.length; i += screenSize) {
                                        chunks.push(item.nftsPerChain.slice(i, i + screenSize));
                                    }
                                    return chunks.map((chunk, chunkIndex) => ( // Change index to chunkIndex
                                        <Carousel.Item key={`${itemIndex}-${chunkIndex}`} className="mb-5"> {/* Use a combination of itemIndex and chunkIndex */}
                                            <div className="d-flex justify-content-around">
                                                {chunk.map((elem, indexWithinChunk) => (
                                                    <div className={`claim-nft-item wiggle`} key={indexWithinChunk}>
                                                        <div className="fugu--card-wrap">
                                                            <div className="fugu--card-thumb">
                                                                <img
                                                                    src={elem.image}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="fugu--card-data mt-0">
                                                                <h4>
                                                                    {elem.name
                                                                        ? `${elem.name} - #${elem.tokenId}`
                                                                        : "Undefined"}
                                                                </h4>
                                                                <div className="fugu--card-footer">
                                                                    <button
                                                                        className="fugu--btn btn-sm bg-white"
                                                                        onClick={() => claimUserNft(elem, item, itemIndex)}
                                                                        disabled={disableBtn}
                                                                    >
                                                                        {disableBtn && <OutLineButtonLoader />}
                                                                        Claim
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Carousel.Item>
                                    ));
                                })}
                            </Carousel>
                            <div className='d-flex align-items-center'>
                            <Checkbox 
                            className='ms-5  text-warning-600'
                            checked={dontShow}
                           onChange={(e) => handleDontShow(e)}
                            />
                            <h4 className="mb-0">DON'T SHOW AGAIN</h4>
                            
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CongratsModal