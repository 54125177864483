import { ethers, Contract, Signer } from "ethers";
import toast from "react-hot-toast";
import axiosToken from "../../../social-wallet/AxiosToken";
import {
  PROXY_PAYMENT_CONTRACT_DICT,
  PROXY_PAYMENT_CONTRACT_ABI,
} from "./ProxyPayment_Constants";
import { CasperLegacyWallet } from "casper-storage";
import { sendNewUserEmail } from "../../../../api/sendEmail";
import { ERC20_ABI } from "./HorusCoin_Constants";
import { SUPPORTED_OPTIONS, getEthersProvider} from "../../utils/Providers";
import {
  signTransaction,
  getHashedWalletId,
  hashWalletIds,
} from "../../utils/utils";
import { displayMessageWithLink } from "../../../../helper/helperFunc";
import {
  writeToContractByWagmi,
} from "../../utils/utils";
import { parseEther } from "viem";
import { getDepositBalances } from "../../utils/utils";

// metamask user send from wallet to email
export const depositToken = async (
  walletAddress,
  walletIds, //emails
  amount,
  network
) => {
  try {
    if (!walletIds.length) {
      toast.error("Error: No recipient valid emails detected");
      return;
    }

    if (network.tokenAddress != ethers.constants.AddressZero)
      await writeToContractByWagmi(
        {
          address: network.tokenAddress,
          abi: ERC20_ABI,
        },
        {
          functionName: "approve",
          args: [
            PROXY_PAYMENT_CONTRACT_DICT[network.chain][process.env.REACT_APP_NETWORK_TYPE],
            parseEther((+amount * walletIds.length).toString()),
          ],
        },
        network.chain,
        "Approving Transaction"
      );

    const hashedWalletIds = hashWalletIds(walletIds, network.chain);
    await writeToContractByWagmi(
      {
        address: PROXY_PAYMENT_CONTRACT_DICT[network.chain][process.env.REACT_APP_NETWORK_TYPE],
        abi: PROXY_PAYMENT_CONTRACT_ABI,
      },
      {
        functionName: "depositFunds",
        args: [
          walletAddress,
          hashedWalletIds,
          network.tokenAddress,
          parseEther(amount),
        ],
        value:
          network.tokenAddress == ethers.constants.AddressZero
            ? parseEther((+amount * walletIds.length).toString())
            : 0,
      },
      network.chain,
      "Depositing Transaction"
    );

    for (const walletId of walletIds) {
      sendNewUserEmail(walletId, amount, network?.symbol , "Tokens");
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

export const transferByWallet = async (
  walletAddress,
  recepinets,
  tokenAddress,
  amount,
  network
) => {
  for (const recepinet of recepinets) {
    if (!ethers.utils.isAddress(recepinet)) {
      toast.error("Invalid address");
      return;
    }
  }

  try {
    if (tokenAddress != ethers.constants.AddressZero) {
      await writeToContractByWagmi(
        {
          address: tokenAddress,
          abi: ERC20_ABI,
        },
        {
          functionName: "approve",
          args: [
            PROXY_PAYMENT_CONTRACT_DICT[network][process.env.REACT_APP_NETWORK_TYPE],
            ethers.utils.parseEther((+amount * recepinets.length).toString()),
          ],
        },
        network,
        "Approving Transaction"
      );
    }
    await writeToContractByWagmi(
      {
        address: PROXY_PAYMENT_CONTRACT_DICT[network][process.env.REACT_APP_NETWORK_TYPE],
        abi: PROXY_PAYMENT_CONTRACT_ABI,
      },
      {
        functionName: "batchTrasnfer",
        args: [
          walletAddress,
          tokenAddress,
          recepinets,
          ethers.utils.parseEther(amount),
        ],
        value:
          tokenAddress == ethers.constants.AddressZero
            ? ethers.utils.parseEther((+amount * recepinets.length).toString())
            : 0,
      },
      network,
      "Transferring Transaction"
    );
  } catch (err) {
    console.log(err);
  }
};

// social user send from email to wallet
//
export const transferByEmail = async (
  email,
  walletAddress,
  walletIndex,
  recepinets,
  amount,
  network,
  password
) => {
  for (const recepinet of recepinets) {
    if (!ethers.utils.isAddress(recepinet)) {
      toast.error("Invalid address");
      return;
    }
  }
  try {
    if (network.tokenAddress != ethers.constants.AddressZero) {
      const approveHash = await signTransaction(
        email,
        walletIndex,
        network.chain,
        password,
        [
          {
            contractAddress: network.tokenAddress,
            abi: ERC20_ABI,
            functionName: "approve",
            params: [
              PROXY_PAYMENT_CONTRACT_DICT[network.chain][process.env.REACT_APP_NETWORK_TYPE],
              ethers.utils.parseEther((amount * recepinets.length).toString()),
            ],
          },
        ],
        "Approving Transaction"
      );

      if (!approveHash) {
        toast.error("Transaction Failed");
        return;
      }
    }

    const hash = await signTransaction(
      email,
      walletIndex,
      network.chain,
      password,
      [
        {
          contractAddress: PROXY_PAYMENT_CONTRACT_DICT[network.chain][process.env.REACT_APP_NETWORK_TYPE],
          abi: PROXY_PAYMENT_CONTRACT_ABI,
          functionName: "batchTrasnfer",
          params: [
            walletAddress,
            network.tokenAddress,
            recepinets,
            ethers.utils.parseEther(amount),
          ],
          value:
            network.tokenAddress == ethers.constants.AddressZero
              ? ethers.utils.parseEther(
                  (+amount * recepinets.length).toString()
                )
              : null,
        },
      ],
      "Sending Transaction"
    );

    console.log(hash);
  } catch (error) {
    console.log(error);
  }
};

// transfer by email to batch of mails
export const depositByEmail = async (
  walletId,
  walletAddress,
  walletIndex,
  recipientEmails,
  amount,
  network,
  password
) => {
  try {
    if (!recipientEmails.length) {
      toast.error("Error: No recipient valid emails detected");
      return;
    }
    const hashedEmails = hashWalletIds(recipientEmails, network.chain);
    if (network.tokenAddress != ethers.constants.AddressZero) {
      try {
        const hash = await signTransaction(
          walletId,
          walletIndex,
          network.chain,
          password,
          [
            {
              contractAddress: network.tokenAddress,
              abi: ERC20_ABI,
              functionName: "approve",
              params: [
                PROXY_PAYMENT_CONTRACT_DICT[network.chain][process.env.REACT_APP_NETWORK_TYPE],
                ethers.utils.parseEther(
                  (+amount * recipientEmails.length).toString()
                ),
              ],
            },
            {
              contractAddress: PROXY_PAYMENT_CONTRACT_DICT[network.chain][process.env.REACT_APP_NETWORK_TYPE],
              abi: PROXY_PAYMENT_CONTRACT_ABI,
              functionName: "depositFunds",
              params: [
                walletAddress,
                hashedEmails,
                network.tokenAddress,
                ethers.utils.parseEther(amount),
              ],
            },
          ],
          "Depositing Tokens"
        );
        console.log(hash);
      } catch (error) {
        throw error;
      }
    } else {
      //TASK: Prevent email sending on signTransaction failure
      await signTransaction(
        walletId,
        walletIndex,
        network.chain,
        password,
        [
          {
            contractAddress: PROXY_PAYMENT_CONTRACT_DICT[network.chain][process.env.REACT_APP_NETWORK_TYPE],
            abi: PROXY_PAYMENT_CONTRACT_ABI,
            functionName: "depositFunds",
            params: [
              walletAddress,
              hashedEmails,
              network.tokenAddress,
              ethers.utils.parseEther(amount),
            ],
            value: ethers.utils.parseEther(
              (+amount * recipientEmails.length).toString()
            ),
          },
        ],
        "Signing"
      );
    }

    for (const email of recipientEmails) {
      sendNewUserEmail(email, amount, network?.symbol ,"Tokens");
    }
  } catch (error) {
    console.log(error);
  }
};

export const withdraw = async (email, network, tokenAddress, value) => {
  try {
    const txPromise = axiosToken.post("withdraw", {
      network: network,
      networkType: process.env.REACT_APP_NETWORK_TYPE,
      walletId: email,
      contractAddress: PROXY_PAYMENT_CONTRACT_DICT[network][process.env.REACT_APP_NETWORK_TYPE],
      abi: PROXY_PAYMENT_CONTRACT_ABI,
      method: "claimFunds",
      methodArgs: [tokenAddress, ethers.utils.parseEther(value)],
    });

    const tx = await toast.promise(txPromise, {
      loading: "Claiming funds",
      success: (res) =>
        displayMessageWithLink(res.data.signedDeployHash.hash, "", network),
      error: "Claiming Failed",
    });

    const provider = getEthersProvider(network);

    await provider.waitForTransaction(tx.data.signedDeployHash.hash);

    console.log("signedDeployHash", tx.data.signedDeployHash);

    return true;
  } catch (error) {
    console.log(error);
    toast.error("Error in claiming funds");
    return false;
  }
};

export const getWalletDetails = async (
  email,
  network,
  walletIndex,
  password
) => {
  try {
    const tx = await axiosToken.get("getWalletDetails", {
      params: {
        walletId: email,
        network,
        password,
        walletIndex,
      },
    });
    const publicKey = tx.data.walletAddress.publicKey;
    let privateKey = "";
    if (network == "CASPER") {
      const walletKey = new CasperLegacyWallet(
        tx.data.walletAddress.privateKey,
        "secp256k1"
      );
      privateKey = walletKey.getPrivateKeyInPEM();
    } else {
      privateKey = "0x" + tx.data.walletAddress.privateKey;
    }
    if (!ethers.utils.isHexString(privateKey) && network != "CASPER")
      throw new Error("Invalid private key");

    CreateTextFile(publicKey, privateKey, email);
  } catch (error) {
    toast.error("Wrong password");
  }
};

export const getDepositBalance = async (walletId, network, tokenAddress) => {
  try {
    if(!SUPPORTED_OPTIONS[network]["custodial"].Claim_Tokens)
      return 0;

    const contract = new Contract(
      PROXY_PAYMENT_CONTRACT_DICT[network][process.env.REACT_APP_NETWORK_TYPE],
      PROXY_PAYMENT_CONTRACT_ABI,
      getEthersProvider(network)
    );

    console.log("contract: ", contract);

    const hashedWalletId = getHashedWalletId(walletId, network);
    const balance = await contract.getDepositBalance(
      hashedWalletId,
      tokenAddress
    );
    return balance / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const getAllDepositsBalance = async (walletId, network) => {
  try {

    const contract = new Contract(
      PROXY_PAYMENT_CONTRACT_DICT[network][process.env.REACT_APP_NETWORK_TYPE],
      PROXY_PAYMENT_CONTRACT_ABI,
      getEthersProvider(network)
    );

    const hashedWalletId = getHashedWalletId(walletId, network);

    let depositedTokens = await contract.getUserDeposits(hashedWalletId);

    //filter out zero balances except for the native token 
    depositedTokens = depositedTokens.filter(
      (token) =>
        token.depositAmount != 0 || token.tokenAddress == ethers.constants.AddressZero
    );

    const tokens = await getDepositBalances(
      depositedTokens,
      network
    );

    return tokens;
  } catch (error) {
    // console.log(error);
  }
};

function CreateTextFile(publicKey, privateKey, email) {
  const blob = new Blob(
    [`email: ${email}\npublicKey: ${publicKey}\nprivateKey: ${privateKey}`],
    {
      type: "text/plain;charset=utf-8",
    }
  );
  saveAs(blob, `${email}.txt`);
}

function saveAs(content, fileName) {
  const a = document.createElement("a");
  const isBlob = content.toString().indexOf("Blob") > -1;
  let url = content;
  if (isBlob) {
    url = window.URL.createObjectURL(content);
  }
  a.href = url;
  a.download = fileName;
  a.click();
  if (isBlob) {
    window.URL.revokeObjectURL(url);
  }
}

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
