import { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import NftsLibrary from "./nfts-library";
import UploadMintCsv from "./upload-mint-csv";

export default function MintSection() {
    let initialNftProps = {
        name: "",
        description: ""
    }
    let initialReceivers = {
        Email: [],
        Phone: [],
        Wallet: []
    }
    const [addressesReceivers, setAddressesReceivers] = useState(initialReceivers)
    const [nftProps, setNftProps] = useState(initialNftProps)
    const [selectedCollection, setSelectedCollection] = useState(null);
    // get the uploaded lists from the child component
    const HandleEmailArr = (newArray) => {
        // console.log(newArray  , "from mint page ")
        setAddressesReceivers(newArray);
    };


    const handleCollectionSelect = (collection) => {
        console.log(collection)
        setSelectedCollection(collection);
    };

    return (
        <div className={`fugu--inner-section  dark-version`}>
            <div className="container">
                <div className="fugu--breadcrumbs-section">
                    <div className="fugu--breadcrumbs-data">
                        <h1>NFTs Airdrops</h1>
                        {/* <p>
                            Your source of market analysis, news, developments, and project reviews for the NFT ecosystem.
                            Discover and keep up to date with the latest NFT.
                        </p> */}
                    </div>
                </div>

                <div className="fugu--blog-sidebar-section">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                <NftsLibrary onCollectionSelect={handleCollectionSelect} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="fugu--blog-sidebar">

                                <UploadMintCsv receiversArr={HandleEmailArr} selectedCollection={selectedCollection} />

                                {/* <div className="fugu--blog-sidebar-item">
                                    <h4>Latest Minted NFTs:</h4>
                                    <div className="fugu--blog-post-wrap">
                                        <div className="fugu--blog-post-thumb">
                                            <Link href={"#"}>
                                                <img src={require("../../../assets/images/all-img/blog2/dark/blog1.png")} alt="" />
                                            </Link>
                                        </div>
                                        <div className="fugu--blog-post-data">
                                            <Link href={"#"}>
                                                <p>July 27, 2022</p>
                                            </Link>
                                            <Link href={"#"}>
                                                <h5>We’ve valued one of the biggest NFT portfolios in the world!</h5>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="fugu--blog-post-wrap">
                                        <div className="fugu--blog-post-thumb">
                                            <Link href={"#"}>
                                                <img src={require("../../../assets/images/all-img/blog2/dark/blog2.png")} alt="" />
                                            </Link>
                                        </div>
                                        <div className="fugu--blog-post-data">
                                            <Link href={"#"}>
                                                <p>July 27, 2022</p>
                                            </Link>
                                            <Link href={"#"}>
                                                <h5>One global or several tiny NFT communities? Traders version</h5>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="fugu--blog-post-wrap">
                                        <div className="fugu--blog-post-thumb">
                                            <Link href={"#"}>
                                                <img src={require("../../../assets/images/all-img/blog2/dark/blog3.png")} alt="" />
                                            </Link>
                                        </div>
                                        <div className="fugu--blog-post-data">
                                            <Link href={"#"}>
                                                <p>July 27, 2022</p>
                                            </Link>
                                            <Link href={"#"}>
                                                <h5>Always be on time on your metaverse event with Dwiss!</h5>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="fugu--blog-sidebar-item">
                                    <div className="fugu--newsletter fugu--search">
                                        <input type="email" placeholder="Search..." />
                                        <button type="submit" id="fugu--submit-btn">
                                            Search
                                        </button>
                                        <button id="fugu--search-btn">
                                            <img src="/images/svg2/search.svg" alt="" />
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}