import React, { useState } from "react";
import { Modal, CloseButton, ModalHeader } from "react-bootstrap";
import { chainProviders } from "../../chainsStaticObject/chainProviders";


function HConnectModal({
    show,
    handleCloseParent,

}) {
    const [loading, setLoading] = useState(false)
    const [showSubWallets, setShowSubWallets] = useState(false);
    const [selectedElement, setSelectedElement] = useState(chainProviders[0]);
    const [showModal, setShowModal] = useState(show);
    const handleClose = () => {
        console.log("Close modale")
        handleCloseParent();
        setShowModal(false)
    };

    const handleElementClick = (element) => {
        setSelectedElement(element);
    };
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="bordered-modal"
            id="password-modal"
        >
            <ModalHeader>
                <CloseButton
                    onClick={handleClose}
                    className="btn-close-white"
                    style={{ position: "absolute", right: "20px", top: "20px" }}
                />
            </ModalHeader>           

            <Modal.Body>
                <div className="justify-center">
                    <div>{"Horus connect allow you to select a wallet provider for  the  supported chains"}</div>
                    <div className="">
                        <div className="d-flex w-100 justify-center" style={{justifyContent: "center"}}>
                            <div className="d-flex my-5 ">
                                {chainProviders.map((element, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleElementClick(element)}
                                        style={{ cursor: 'pointer', margin: '0 10px' }}
                                    >
                                        <img src={element.image} alt={element.name} width="50" height="50" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex w-100 my-3 py-3" style={{ justifyContent: "center", border: "1px solid #0092B4", borderRadius: 12 }}>
                        {selectedElement && (
                            <div>
                                <h4 className="text-center">{selectedElement.name}</h4>
                                <div className="d-flex justify-center mt-3">
                                    {selectedElement.walletsProviders.map((wallet, index) => (
                                        <div key={index} style={{ textAlign: 'center', margin: '5 10px' }}>
                                            <img src={wallet.logo} alt={wallet.name} width="50" height="50" />
                                            <p className="m-3">{wallet.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default HConnectModal