import { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Modal, Button, Form, CloseButton } from "react-bootstrap";
import { getAuthConfig } from "./firebase";
import { PhoneInput } from "react-international-phone";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import "react-international-phone/style.css";
import toast from "react-hot-toast";
import { phone } from "phone";

export function openPhoneModal(handleSignIn = null, input = null, type = null) {
  const div = document.createElement("div");
  document.body.appendChild(div);
  const root = createRoot(div);
  const close = () => {
    root.unmount();
    div.remove();
    document.body.style.overflow = "auto";
  };
  root.render(
    <PhoneModal
      show={true}
      handleSignIn={handleSignIn}
      close={close}
      inputValue={input}
      type={type}
    />
  );
}

export function PhoneModal(props) {
  const [inputVal, setInputVal] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);

  useEffect(() => {
    setupRecaptcha();
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    };
  }, []);

  const setupRecaptcha = async () => {
    const auth = await getAuthConfig();
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {},
        auth
      );
      window.recaptchaVerifier.render();
    }
  };

  const handleInputChange = (event) => {
    setInputVal(event.target.value);
  };

  const verifyCode = async (event) => {
    event.preventDefault();
    try {
        const userCredential = await confirmationResult.confirm(inputVal);
        const user = userCredential.user;
        props.handleSignIn("phone", user);
        props.close();
    } catch (error) {
        console.log(`Error during verification: ${error}`);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const normalizedPhone = phone(inputVal);
    if (!normalizedPhone.isValid) {
      toast.error("Invalid phone number");
      return;
    }
    try {
      const auth = await getAuthConfig();
      console.log(window.recaptchaVerifier);
      try {
        const result = await signInWithPhoneNumber(
          auth,
          normalizedPhone.phoneNumber,
          window.recaptchaVerifier
        );
        setConfirmationResult(result);
        toast.success("Verification code sent. Please check your phone.");

      } catch (error) {
        console.error("Error sending verification code:", error);
      }
      setInputVal('')
    } catch (error) {
      console.error("Error sending verification code:", error);
      toast.error("Failed to send verification code. Please try again later.");
    }
  };

  const handleCancel = () => {
    props.close();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleCancel}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="password-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Horus wallet
        </Modal.Title>
        <CloseButton
          onClick={handleCancel}
          style={{ position: "absolute", right: "20px", top: "20px" }}
        />
      </Modal.Header>
      <Modal.Body>
        {!confirmationResult ? (
          <>
            <p>Enter your phone number</p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formPhoneNumber">
                <PhoneInput
                  placeholder="Enter phone number"
                  value={inputVal}
                  onChange={setInputVal}
                />
              </Form.Group>
              <br />
              <div id="recaptcha-container" style={{ marginTop: "1rem" }}></div>
            </Form>
          </>
        ) : (
          <>
            <p>Enter your verification code</p>
            <Form onSubmit={verifyCode}>
              <Form.Group controlId="formVerificiationCode">
                <Form.Control
                  type="text"
                  placeholder="Enter your verification code"
                  value={inputVal}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <br />
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="fugu--portfolio-btn">
          {!confirmationResult ? (
            <button className="fugu--outline-btn" onClick={handleSubmit}>
              <span>Send Code</span>
            </button>
          ) : (
            <button className="fugu--outline-btn" onClick={verifyCode}>
              <span>Verify</span>
            </button>
          )}
        </div>
        <div className="fugu--portfolio-btn">
          <button className="fugu--outline-btn" onClick={handleCancel}>
            <span>Close</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
