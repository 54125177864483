import { useState, useEffect } from 'react'
import { Modal, CloseButton } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import CustomCalenderInput from '../../components/customCalender';
import { convertSecondsToDaysAndMinutes, convertToSeconds } from '../../helper/helperFunc';


function EditScheduleTransactionModal({
    show,
    handleCloseParent,
    scheduleData,
    editSchedule
}) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [milliSecond, setMillisecond] = useState()
    const [days, setDays] = useState()
    const [minutes, setMinutes] = useState()
    const handleClose = () => {
        handleCloseParent();
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const convertBigNumberToDate = (executionDate) => {
        const unixTimestampInSeconds = executionDate.toNumber();
        const unixTimestampInMilliseconds = unixTimestampInSeconds * 1000;
        const date = new Date(unixTimestampInMilliseconds);
        return date;
    };

    useEffect(() => {
        if (scheduleData) {
            const currentDate = convertBigNumberToDate(scheduleData.executionTime)

            setMillisecond(parseInt(scheduleData.repeatingTime.toString()))
            setDays(convertSecondsToDaysAndMinutes(scheduleData.repeatingTime.toString()).days)
            setMinutes(convertSecondsToDaysAndMinutes(scheduleData.repeatingTime.toString()).minutes)
            setSelectedDate(currentDate)
        }
    }, [scheduleData])

    const calculateMilliSeconds = ()=>{
        const milliSeconds = convertToSeconds(days, minutes)
        editSchedule(selectedDate, milliSeconds)
    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="password-modal"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className=" mt-2 text-center">Edit this schedule</Modal.Title>
                <CloseButton
                    onClick={handleClose}
                    style={{ position: "absolute", right: "20px", top: "20px" }}
                />
            </Modal.Header>
            <Modal.Footer className="justify-content-around">
                <div className="row p-1">
                    <div className='col-lg-6 col-12 justify-center'>
                        <div className=" fugu-date-picker ">
                            <p>Execution Date</p>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => handleDateChange(date)}
                                showTimeSelect
                                timeIntervals={1}
                                dateFormat="Pp"
                                minDate={new Date()}
                                minTime={new Date()}
                                maxTime={new Date(0, 0, 0, 23, 59, 59)}
                                className="mx-5 fugu-date-picker text-gray-10"
                                customInput={
                                    <CustomCalenderInput
                                        onClick={handleDateChange}
                                        value={selectedDate}
                                    />
                                }
                            />
                        </div>
                        <p>{selectedDate.toLocaleString()}</p>
                    </div>
                    <div className='col-lg-6 col-12 '>
                        <p>Time gap between transactions</p>
                        {milliSecond && (
                            <div className=' d-flex justify-content-around'>
                                <div>
                                    <label className='text-white pb-1 mx-2'>
                                        Days:
                                        <input type="number"
                                            className="text-gray-10 m-2 mr-2"
                                            value={days}
                                            name='days'
                                            onChange={(e) => setDays(e.target.value < 0 ? 0 : e.target.value)}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className='text-white mx-2'>
                                        Minutes:
                                        <input
                                            className='text-gray-10 m-2'
                                            type="number"
                                            name="minutes"
                                            value={minutes}
                                            onChange={(e) => setMinutes(e.target.value < 0 ? 0 : e.target.value)}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex mb-1">
                    <div className="fugu--portfolio-btn mx-4 ">
                        <button
                            className="fugu--outline-btn"
                            onClick={() => calculateMilliSeconds()}
                        >
                            <span>Confirm</span>
                        </button>
                    </div>
                    <div className="fugu--portfolio-btn">
                        <button
                            className="fugu--outline-btn"
                            onClick={handleClose}
                        >
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default EditScheduleTransactionModal