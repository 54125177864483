import Isotope from "isotope-layout";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../components/web3/context/AuthContext";
import nftPlaceHolder from "../../assets/images/all-img/v3/card9.jpg";
import { toast } from "react-hot-toast";
import {
  claimAllNft,
  claimNft,
  getDepositedNfts,
} from "../../components/web3/nfts-utils/nfts-proxy/NftsProxy_Utils";
import { openPasswordModel } from "../../components/social-wallet/PasswordModal";
import Preloader from "../../components/common/preloader/preloader";
import ReactGA from "react-ga4";
const ClaimNfts = () => {
  const [pendingNfts, setPendingNfts] = useState([]);
  const [isBrokenImage, setIsBrokenImage] = useState([]);
  const { network, balance, email, hasPassword, isLoading , setIsLoading } = useAuth();

  const isotope = useRef();
  const handleImageError = (index) => {
    setIsBrokenImage((prevBrokenImages) => {
      const updatedBrokenImages = [...prevBrokenImages];
      updatedBrokenImages[index] = true;
      return updatedBrokenImages;
    });
  };
  const initializeIsotope = () => {
    isotope.current = new Isotope("#fugu--four-column-2", {
      itemSelector: ".claim-nft-item",
      resizable: false,
      masonry: {
        columnWidth: ".claim-nft-item",
        gutterWidth: 0,
      },
    });
  };
  const fetchPendingNfts = async () => {
    try {
      setIsLoading(true);
      const nfts = await getDepositedNfts(email, network);
      console.log("nfts", nfts);
      nfts && setPendingNfts(nfts);
      setIsLoading(false);
    } catch (err) {
      console.log(err, "Error on fetch pending nfts");
      setIsLoading(false);
    }
  };
  const claimUserNft = async (index) => {
    try{
      if (hasPassword) {
        openPasswordModel(claimPassSecureNft, {
          email: email,
          index: index,
        });
      } else {
        setIsLoading(true);
        await claimNft(email, pendingNfts[index], network);
        setIsLoading(false);
        fetchPendingNfts();
      }
    }catch(e){
      setIsLoading(false)
      toast.error(e.message)
    }
  };
  const claimPassSecureNft = async (password, input) => {
    if (password == null) return;
    if (password === "") return toast.error("Password is required");
    console.log("input", input);
    setIsLoading(true);
    try {
      await claimNft(email, pendingNfts[input.index], network, password);
    } catch (err) {
      console.log(err, "Error on claim nft");
    }
    setIsLoading(false);
    fetchPendingNfts();
  };

  const claimUserAllNfts = async () => {
    if (hasPassword) {
      openPasswordModel(claimPassSecureAllNft, {
        email: email,
      });
    } else {
      setIsLoading(true);
      await claimAllNft(email, network);
      setIsLoading(false);
      fetchPendingNfts();
    }
  };

  const claimPassSecureAllNft = async (password, input) => {
    if (password === "") return toast.error("Password is required");
    if (password == null) return;
    setIsLoading(true);
    await claimAllNft(email, network, password);
    setIsLoading(false);
    fetchPendingNfts();
  };
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/ClaimNFT",
      title: "Claim NFT Page",
    });
    fetchPendingNfts();
  }, []);
  useEffect(() => {
    if (pendingNfts) {
      initializeIsotope();
    }
  }, [pendingNfts]);

  useEffect(() => {
    fetchPendingNfts();
  }  , [network?.chain])
  
  return (
    <div className="pt-4">
      <Preloader show={isLoading} />
      <div className="fugu--section-title">
        <div className="fugu--default-content content-sm">
          <h2>Claim your most unique NFTs</h2>
          <p>
            NFTs are usually associated with non-physical art but in reality,
            there are several different types of NFTs and are explained in this
            guide.
          </p>
        </div>
      </div>
      {pendingNfts && pendingNfts.length > 0 && (
        <div className="fugu--card-footer mb-4">
          <button
            className="fugu--btn btn-sm bg-white"
            onClick={claimUserAllNfts}
          >
            Claim All
          </button>
        </div>
      )}

      <div id="fugu--four-column-2">
        {pendingNfts &&
          pendingNfts.map((elem, index) => (
            <div className={`claim-nft-item `} key={index}>
              <div className="fugu--card-wrap">
                <div className="fugu--card-thumb">
                  <img
                    src={isBrokenImage[index] ? nftPlaceHolder : elem.image}
                    alt=""
                    onError={() => handleImageError(index)}
                  />
                </div>
                <div className="fugu--card-data">
                  <h3>
                    {elem.name
                      ? `${elem.name} - #${elem.tokenId}`
                      : "Undefined"}
                  </h3>
                  <div className="fugu--card-footer">
                    <button
                      className="fugu--btn btn-sm bg-white"
                      onClick={() => claimUserNft(index)}
                    >
                      Claim
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default ClaimNfts;
