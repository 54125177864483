import { GelatoRelay } from "@gelatonetwork/relay-sdk-viem";
import toast from "react-hot-toast";
import axios from "axios";
import { ethers } from "ethers";
import { displayMessageWithLink } from "../../../../helper/helperFunc";
import {
  CHAIN_ID_NETWORK,
  CHAIN_ID_NETWORK_INSTACNE,
  getEthersProvider,
} from "../../utils/Providers";
import { createPublicClient, http } from "viem";
import { POAP_ABI } from "./constatns";
export async function mintPoapNft(params) {
  try {
    console.log("Minting POAP NFT...");
    const provider = getEthersProvider(CHAIN_ID_NETWORK[params.chainId]);
    const poapContract = new ethers.Contract(
      params.poapAddress,
      POAP_ABI,
      provider
    );

    const { data } = await poapContract.populateTransaction.safeMint(params.to);

    const request = {
      data: data,
      chainId: params.chainId,
      target: params.poapAddress,
    };

    const relay = new GelatoRelay();

    const relayResponsePromise = relay.sponsoredCall(request, params.poapId);
    const relayResponse = await toast.promise(relayResponsePromise, {
      loading: "Initiating POAP NFT mint...",
      success: "POAP NFT mint initiated!",
      error: "Error initiating POAP NFT mint",
    });

    const hashPromise = getTransactionHash(relayResponse.taskId);
    const hash = await toast.promise(hashPromise, {
      loading: "Minting POAP NFT...",
      success: (hash) =>
        displayMessageWithLink(
          hash,
          "POAP NFT minted successfully!",
          CHAIN_ID_NETWORK[params.chainId]
        ),
      error: "Error minting POAP NFT",
    });

    console.log("Transaction Hash:", hash);

    // if (hash) {
    //   const deployment = waitForTransaction({ hash });
    //   toast.promise(deployment, {
    //     loading: "Minting you POAP NFT...",
    //     success: displayMessageWithLink(
    //       hash,
    //       "POAP NFT minted successfully!",
    //       CHAIN_ID_NETWORK[params.chainId]
    //     ),
    //     error: "Error minting POAP NFT",
    //   });
    //   await deployment;
    // } else {
    //   throw new Error("Error in Minting POAP NFT");
    // }

    return hash;
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}

async function getTransactionHash(taskId, timeout = 60000, interval = 1000) {
  const endTime = Date.now() + timeout;

  while (Date.now() < endTime) {
    try {
      const url = `https://api.gelato.digital/tasks/status/${taskId}`;
      const response = await axios.get(url);
      console.log("Task Response:", response.data);
      const taskState = response.data.task.taskState;
      const transactionHash = response.data.task.transactionHash;

      if (taskState === "Cancelled") {
        console.log("Task has been cancelled. Stopping the wait.");
        throw new Error("Task has been cancelled.");
      }

      if (transactionHash) {
        console.log(`Transaction Hash: ${transactionHash}`);
        return transactionHash;
      }
    } catch (error) {
      console.error(`An error occurred: ${error}`);
      throw new Error("Error fetching transaction hash");
    }

    await new Promise((resolve) => setTimeout(resolve, interval));
  }

  console.log("Timeout reached. Transaction hash could not be retrieved.");
  return null;
}

export async function getContractStats(contractAddress, chainId) {
  const chain = CHAIN_ID_NETWORK_INSTACNE[chainId];
  const publicClient = createPublicClient({
    chain,
    transport: http(),
  });
  const stats = await publicClient.readContract({
    address: contractAddress,
    abi: POAP_ABI,
    functionName: "getContractStats",
    args: [],
  });
  return stats;
}

export async function isClaimedNft(contractAddress, walletAddress, chainId) {
  const chain = CHAIN_ID_NETWORK_INSTACNE[chainId];
  const publicClient = createPublicClient({
    chain,
    transport: http(),
  });
  const isClaimed = await publicClient.readContract({
    address: contractAddress,
    abi: POAP_ABI,
    functionName: "isClaimed",
    args: [walletAddress],
  });
  return isClaimed;
}
