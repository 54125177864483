import React, { useState } from "react";
import { Modal, Form, CloseButton } from "react-bootstrap";
import { ethers } from "ethers";
import { toast } from "react-hot-toast";
import { useAuth } from "../web3/context/AuthContext";
import { openNewPasswordModel } from "../social-wallet/NewPasswordModal";
import axiosToken from "../social-wallet/AxiosToken";
import { switchDefaultNetworkAddress } from "../../api/defaultNetworkWallet";
import { Buffer } from "buffer";
export default function PopUpModal({
  show,
  handleCloseParent,
  title,
  placeHolder,
  type,
}) {
  const [showModal, setShowModal] = React.useState(show);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const { entityInfo, email, refreshAuth, setIsLoggedIn, network } = useAuth();
  const [isValidKey, setIsValidKey] = useState(true);
  //handle closing modal
  const handleClose = () => {
    setLoading(false);
    setShowModal(false);
    setInputValue("");
    handleCloseParent();
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (type == "import") {
      setIsValidKey(validatePrivateKey("0x" + event.target.value));
    } else {
      setIsValidKey(validatePublicAddress(event.target.value));
    }
  };
  const handleSubmit = (event) => {
    console.log("Type", type, inputValue);
    if (inputValue && inputValue != "") {
      setLoading(true);
      if (type == "import") {
        openNewPasswordModel(importWallet);
      } else if (type == "link") {
        linkWallet(inputValue);
      }
      setLoading(false);
    }
  };

  const linkWallet = async (address) => {
    let chain = network.chain;
    try {
      //Note: this func responsible to set this wallet as a default receiver associated to the current network
      let change = await switchDefaultNetworkAddress(
        email,
        address,
        false,
        chain
      );

      toast.success(
        `Recommended Wallet to ${network.chain} is set successfully`
      );
    } catch (e) {
      toast.error(e.message);
    }
  };

  const importWallet = async (password) => {
    const network = "POLYGON";
    const apiName = "importWallet";
    if (password === null) return;
    const privateKeyBuffer = Buffer.from(inputValue, "hex");

    // Pad the buffer with leading zeros to make it 32 bytes long
    const privateKey = ethers.utils.hexZeroPad(privateKeyBuffer, 32);
    try {
      await axiosToken.post(apiName, {
        walletId: email,
        network,
        privateKey,
        password,
      });
      const wallet = new ethers.Wallet(privateKey);
      let newWallets = entityInfo.userWalletsData;
      newWallets["EVM"].wallets.push({
        publicKey: wallet.address,
        hasPassword: password != "",
        index: newWallets["EVM"].wallets.length,
      });

      refreshAuth();
      handleCloseParent();
      setShowModal(false);
      setInputValue("");
      toast.success("Wallet imported successfully");
    } catch (error) {
      console.log("error", error);
      toast.error("Wallet is already imported");
    }
  };
  function validatePrivateKey(privateKey) {
    if (!ethers.utils.isHexString(privateKey)) {
      return false;
    }
    return true;
  }
  function validatePublicAddress(address) {
    if (!ethers.utils.isAddress(address)) {
      return false;
    }
    return true;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="password-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        <CloseButton
          className="btn-close-white"
          onClick={handleClose}
          style={{ position: "absolute", right: "20px", top: "20px" }}
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="privateKey">
            <Form.Control
              type="default"
              placeholder={`${placeHolder}`}
              value={inputValue}
              onChange={handleInputChange}
              disabled={loading}
            />
          </Form.Group>
          {!isValidKey && inputValue != "" && (
            <p className="fugu-error-text p1-1 mb-0">
              {type == "import"
                ? "invalid private key ..."
                : "invalid Public key ..."}
            </p>
          )}
          <br />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="fugu--portfolio-btn">
          <button
            className="fugu--outline-btn"
            onClick={handleSubmit}
            disabled={loading || !isValidKey}
          >
            {loading && (
              <div className="fugu-button-spinner">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="50" cy="50" r="46" />
                </svg>
              </div>
            )}
            <span>Submit</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
