import React, { useState, useRef } from 'react';
import { Modal, CloseButton } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import logo from "../assets/images/logo/logo-white.png";
import testLogo from "../assets/images/logo/logo-test.png";

export default function QrCodeModal({ show, handleCloseParent, userWallet }) {
  const [showModal, setShowModal] = React.useState(show);
  const deployEnv = process.env.REACT_APP_DEPLOY_ENV
  const qrCodeImage = deployEnv == "dev"? testLogo : logo
  const handleClose = () => {
    setShowModal(false);
    handleCloseParent();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="password-modal"
    >
      <Modal.Header>
        <CloseButton
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-center align-items-center m-5'>
          <QRCode value={userWallet}
          size={300}
            imageSettings={{
              src:qrCodeImage,
              height: 100,
              width: 100
            }}
          />
        </div>

      </Modal.Body>
    </Modal>
  );
}

