import React, { useState } from 'react';
import BackButton from '../../components/common/backButton';
import CollectionImageAndProps from '../../components/collections-manager/collection-props';
import CollectionTraits from '../../components/collections-manager/collection-traits';
import LayersNamesController from '../../components/collections-manager/generative-nfts/generate-image-by-layers/layers-name-controller';
import NftImgController from '../../components/collections-manager/generative-nfts/generate-image-by-layers/nft-imgs-controller';
import mintHolder from '../../assets/images/all-img/blog2/dark/blog14.png';
function GenerativeLayersCollection() {
  const initialCollectionProps = {
    collectionName: '',
    nftCount: '',
    collectionSymbol: '',
  };
  const [layerPropsFromLayersName, setLayerPropsFromLayersNameController] =
    useState(null);
  const [collectionProps, setCollectionPros] = useState(initialCollectionProps);
  const [nftCount, setNftCountValue] = useState();
  const [displayImageArr, setDisplayImageArr] = useState([]);
  const [checking, setChecking] = useState(false);

  const handleCollectionPropsChange = (e) => {
    const { value, name } = e.target;
    setCollectionPros((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="fugu--hero-section container mb-2">
      <BackButton selectedTab={"CreateNewCollection"} />
      <h1 className="text-center text-white mb-2">
        Create Generative Layers Collection
      </h1>
      <div className="mx-1 mt-5 pb-5 generative-row-box">
        <div className=" col-md-4 col-12 ">
          <LayersNamesController
            setLayerProps={setLayerPropsFromLayersNameController}
            layersIndexChanged={(layers) => {
              const newArrangeArr = [...displayImageArr]; // Create a new array to avoid mutating the original array
              newArrangeArr.forEach((item) => {
                const layer = layers.find(
                  (elem) => elem.name === item.layerName || elem.id === item.id
                );
                if (layer) {
                  item.layerIndex = layer.index;
                  item.selectedImageInAlbum = item.selectedImageInAlbum;
                }
              });
              setDisplayImageArr(newArrangeArr);
              setChecking((prevState) => !prevState); // Toggle state to trigger re-render
            }}
          />
        </div>
        <div className="col-md-4 col-12">
          <NftImgController
            layerProps={layerPropsFromLayersName}
            setImageProps={setDisplayImageArr} //func pass the new display images arr from controller
            newArrange={displayImageArr} // get new value of arranged images arr
            check={checking}
            getNftCount={nftCount}
            passGeneratedNftUrl={(url) => {
              // handleImageCreated(url, nftCount)
              // handleClose()
            }}
            getNftProps={collectionProps}
          />
        </div>
        <div className="col-md-4 col-12">
          <div className="sortable-list">
            {displayImageArr && displayImageArr.length > 0 && (
              <div className="position-relative generative-image-holder">
                {displayImageArr &&
                  displayImageArr.length > 0 &&
                  displayImageArr
                    .slice() // Create a copy of the array to avoid mutating the original array
                    .sort((a, b) => a.layerIndex - b.layerIndex) // Sort the array based on the 'layerIndex' property
                    .map((elem, index) =>
                      elem.selectedImageInAlbum?.file ? (
                        <img
                          key={index}
                          src={URL.createObjectURL(
                            elem.selectedImageInAlbum?.file
                          )}
                          alt={`Uploaded Image ${index + 1}`}
                          className="position-absolute"
                        />
                      ) : (
                        <></>
                      )
                    )}
              </div>
            )}
            {!displayImageArr ||
              (displayImageArr.length == 0 && (
                <img
                  src={require('../../assets/images/collections/generativeLayersPlaceHolder.jpg')}
                  alt={`Holder nft Image`}
                  className=""
                />
              ))}
            <div className="new-item d-flex justify-content-center align-items-center mt-4 mr-3">
              <input
                type="text"
                placeholder="Collection Name"
                name="collectionName"
                value={collectionProps.collectionName}
                onChange={handleCollectionPropsChange}
                className="input-field mb-2 text-white col"
              />
            </div>
            <div className="new-item d-flex justify-content-center align-items-center mt-4 mr-3">
              <input
                type="text"
                placeholder="Collection Symbol"
                name="collectionSymbol"
                value={collectionProps.collectionSymbol}
                onChange={handleCollectionPropsChange}
                className="input-field mb-2 mx-1 text-white col"
              />

              <input
                type="number"
                placeholder="NFT Count"
                value={collectionProps.nftCount}
                name="nftCount"
                onChange={handleCollectionPropsChange}
                className="input-field mb-2 mx-1 text-white col"
                onWheel={(event) => event.currentTarget.blur()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerativeLayersCollection;
