import { CLPublicKey, DeployUtil, Signer } from 'casper-js-sdk';
import { Deploy } from 'casper-js-sdk/dist/lib/DeployUtil';
import {toast} from "react-hot-toast";

export const signDeploy = async (deploy: Deploy, publicKey: CLPublicKey) => {
  const publicKeyHex = publicKey.toHex().toLowerCase();
  return await signDeployHex(deploy, publicKeyHex);
};

export const signDeployHex = async (deploy: Deploy, publicKeyHex: string) => {
  const deployJSON = DeployUtil.deployToJson(deploy);
  const signedDeployJSON = await Signer.sign(
    deployJSON,
    publicKeyHex,
    publicKeyHex
  ).catch((err) => {err.message.includes("User Cancelled")?toast.error("You have canceled the transaction"):console.log(err);});
  const signedDeploy = DeployUtil.deployFromJson(signedDeployJSON).unwrap();

  return signedDeploy;
};
