export const joyRideStepsContent = [
    {
      content: <h2>Discover the amazing and easiest way wallet management! </h2>,
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'center',
      target: 'body',
    },
    {
      content: <h2>Connect your wallet with your wallet </h2>,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: '.fugu-web3-button',
    },
    {
      content: (
        <div>
          You can register using one of those social providers 
          <br />
          this feature provide to create and manage a new wallet
        </div>
      ),
      placement: 'bottom',
      styles: {
        options: {
          width: 300,
        },
      },
      target: '.fugu-social-login',
    },
    {
      content: (
        <div>
          You can switch between test net and main net from here 
        </div>
      ),
      placement: 'bottom',
      styles: {
        options: {
          width: 300,
        },
      },
      target: '.joyride-switch-network',
    },
    {
      content: (
        <div>
          View your wallet balance in your dashboard
        </div>
      ),
      placement: 'top',
      target: '.joyride-wallet-balance',
      title: 'Our',
    },
    {
      content: (
        <div>
          You can charge to a single wallet/mail OR multiple mails separated with comma. 
        </div>
      ),
      placement: 'left',
      target: '.joyride-fugu-user-receivers-input',
    },
    
    {
      content: (
        <div>
          You can select your receiver address.
          <br/> 
          you can pay to wallet address or E-mail
        </div>
      ),
      placement: 'left',
      target: '.joyride-fugu-select-pay-method-holder',
    },
        
    {
      content: (
        <div>
          <h3>Set Amount here!!</h3>
        </div>
      ),
      placement: 'left',
      target: '.fugu-transfer-amount-box',
    },
    {
      content: (
        <div>
          You could schedule your transaction.
        </div>
      ),
      placement: 'left',
      target: '.joyride-schedule-tx',
    },
    {
      content: (
        <div>
          <h3>AND SEND</h3>
        </div>
      ),
      placement: 'left',
      target: '.fugu-send-payment-btn',
    },
    {
      content: (
        <div>
          <h3>You can switch networks from here</h3>
        </div>
      ),
      placement: 'left',
      target: '.fugu-switch-network',
    },
    {
      content: (
        <div>
          When you connect with your selected social provider, our system is created a wallet for you
        </div>
      ),
      placement: 'left',
      target: '.fugu-wallet-dropdown',
    },
    {
      content: (
        <div>
          You can download your wallet from here if you decide to manage your wallet by yourself or if you need to use it out
        </div>
      ),
      placement: 'left',
      target: '.fugu-download-wallet',
    },
    {
      content: (
        <div>
          You can  import your wallet by sharing the private key
        </div>
      ),
      placement: 'left',
      target: '.fugu-import-wallet',
    },
    {
      content: (
        <div>
          Or just link your wallet by sharing only the public key
        </div>
      ),
      placement: 'left',
      target: '.fugu-link-wallet',
    },
    {
      content: (
        <div>
          Check your fund and claim with just one click
        </div>
      ),
      placement: 'left',
      target: '.fugu-fund-wallet',
    }
    ,{
      content: (
        <div>
          You can create unlimited count of wallets
        </div>
      ),
      placement: 'left',
      target: '.fugu-create-wallet',
    },
  ]
  