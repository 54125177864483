import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './assets/styles/css/main.css';
import './assets/styles/css/app.css';
import './assets/styles/css/animate.css';
import 'bootstrap/scss/bootstrap.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';
import Layout from './components/layout';
import ReactGA from 'react-ga4';
import { AuthProvider } from './components/web3/context/AuthContext';
import { joyRideStepsContent } from './joySteps/joyRideStaticSteps';
import toast, { Toaster } from 'react-hot-toast';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';

import { WagmiConfig } from 'wagmi';
import {
  mainnet,
  sepolia,
  polygon,
  polygonMumbai,
  bsc,
  bscTestnet,
  telos,
  telosTestnet,
  flare,
  flareTestnet,
  base,
  baseSepolia
} from 'viem/chains';
//pages
import Terms from './pages/privacy-terms-condition/terms';
import TransferDashboard from './pages/logged-in-home/loggedInHome';
import IndexThree from './pages/logged-out-home/loggedOutHome';
import PrivacyPolicy from './pages/privacy-terms-condition/privacy-policy';
import ClaimFund from './pages/claim-fund/claim-fund';
import ScheduleTransaction from './pages/schedule-transaction/scheduleTransaction';
import NftGallery from './pages/nft-gallery/nft-gallery';
import MintManager from './pages/nft-gallery/mint/collections-controller/mint-manager';
import DirectScheduleTransfer from './pages/schedule-transaction/directScheduleTransfer';
import MySchedules from './pages/schedule-transaction/mySchedules';
import Livechat from './components/live-chat/liveChat';
import SingImageCollection from './pages/create-new-collection/single-image-collection';
import SingleAiGenerated from './pages/create-new-collection/single-ai-generated';
import GenerativeLayersCollection from './pages/create-new-collection/generative-layers-collection';
import ComingSoon from './pages/coming-soon';
import GenerativeAiLayersCollection from './pages/create-new-collection/generative-ai-layers-collection';
import QrNft from './pages/qrNft/QrNft';
function App() {
  const networkType = process.env.REACT_APP_NETWORK_TYPE;
  const chains =
    networkType === 'TESTNET'
      ? [sepolia, polygonMumbai, bscTestnet, telosTestnet, baseSepolia]
      : [mainnet, polygon, bsc, telos, base];

  const projectId = process.env.REACT_APP_PROJECT_ID;

  const metadata = {
    name: 'Horus wallet',
    description: 'Ultimate wallet for all your needs.',
    url: 'https://horuswallet.com',
    icons: [
      'https://horuswallet.com/static/media/logo-white.b4898638e9b311ead489.png',
    ],
  };

  const wagmiConfig = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
  });

  createWeb3Modal({ wagmiConfig, projectId, chains });
  window.csprclick?.once('csprclick:loaded', () => {
    //do something here
    console.log('?? casper click loaded');
  });
  const googleAnaId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
  console.log(googleAnaId);
  ReactGA.initialize(`${googleAnaId}`);

  //   const { publicClient } = configureChains(chains, [
  //     w3mProvider({ projectId }),
  //   ]);

  const [{ run, steps }, setState] = React.useState({
    run: false,
    steps: joyRideStepsContent,
  });

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <AuthProvider>
          <Router>
            <div>
              <Toaster
                position="bottom-center"
                reverseOrder={false}
                toastOptions={{
                  style: {
                    background: '#037FFF',
                    color: '#fff',
                    fontSize: '1.4em',
                  },
                }}
              />
            </div>
            <Routes>
              <Route element={<Layout />}>
                <Route path="/" exact element={<IndexThree />} />
                <Route
                  path="/transferDashboard"
                  element={<TransferDashboard />}
                />
                <Route path="/claimFund" element={<ClaimFund />} />
                <Route
                  path="/scheduleTransaction"
                  element={<ScheduleTransaction />}
                />
                <Route path="/nftGallery" element={<NftGallery />} />
                <Route path="/mintNfts" element={<MintManager />} />
                <Route
                  path="DirectScheduleTransfer"
                  element={<DirectScheduleTransfer />}
                />
                <Route path="/MySchedules" element={<MySchedules />} />
                <Route
                  path="/CreateSingleImageCollection"
                  element={<SingImageCollection />}
                />
                <Route
                  path="/CreateSingleAiGeneratedCollection"
                  element={<SingleAiGenerated />}
                />
                <Route
                  path="/GenerativeLayersCollection"
                  element={<GenerativeLayersCollection />}
                />
                <Route
                  path="/GenerativeAiLayers"
                  element={<GenerativeAiLayersCollection />}
                />
                <Route path="/ComingSoon" element={<ComingSoon />} />
              </Route>
              <Route path="QrNfts" element={<QrNft />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<Terms />} />
            </Routes>
          </Router>
          <Livechat />
        </AuthProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
