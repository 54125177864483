import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="section mob-section privacy-container">
        <div className="_w-container diff-container w-container">
          <div
            href="https://cspr.live/#interest"
            className="rich-text-block-2 policy-text w-richtext"
          >
            <h1>
              <strong>Privacy Policy</strong>
            </h1>
            <h4>
              <strong>Last Updated: July 13, 2022</strong>
            </h4>
            <p>
              At Horus Wallet, we know you care about how your personal information is
              used and shared, and we take your privacy seriously. This Privacy
              Policy describes how your personal or behavioral data is
              collected, used, and stored when you access our website, the web
              application or mobile application (collectively the “Site”).
            </p>
            <p>
              We may modify this Privacy Policy from time to time which will be
              indicated by changing the date at the top of this page. If we make
              any material changes, we will notify you by email (sent to the
              email address specified in your account), by means of a notice on
              our Services prior to the change becoming effective, or as
              otherwise required by law. Your use of the Site is at all times
              subject to the Horus Wallet User Agreement, which incorporates this
              Privacy Policy. Any terms we use in this Privacy
            </p>

            <h4 className="mt-5">1. ACCEPTANCE OF THE PRIVACY POLICY</h4>
            <p>
              {' '}
              By the Site, you signify acceptance of the terms of this Privacy
              Policy. Where we require your consent to process your information,
              we will ask for your consent to the collection and use of your
              information as described further below.
            </p>
            <p>
              We provide additional "just-in-time" disclosures or information
              about the data processing practices of specific services. These
              notices may supplement or clarify our privacy practices or may
              provide you with additional choices about how we process your
              data. If you do not agree with or you are not comfortable with any
              aspect of this Privacy Policy, you should immediately discontinue
              access or use of the Site.
            </p>
            <p>
              If you are under the age of majority in your jurisdiction of
              residence, you may use the Services only with the consent of or
              under the supervision of your parent or legal guardian. Consistent
              with the requirements of the Children's Online Privacy Protection
              Act (COPPA), if we learn that we have received any information
              directly from a child under age 13 without first receiving his or
              her parent's verified consent, we will use that information only
              to respond directly to that child (or his or her parent or legal
              guardian) to inform the child that he or she cannot use the Site
              and subsequently we will delete that information.
            </p>
            <h4 className="mt-5">2. WHAT DOES THIS PRIVACY POLICY COVER?</h4>
            <p>
              This Privacy Policy sets forth our policy for collecting or using
              personal or behavioral data in connection with users access and
              using of the Site.
            </p>
            <h4 className="mt-5">3. THE INFORMATION WE COLLECT</h4>
            <p>
              <strong>● Horus Wallet does not track IP addresses.</strong> Where third
              parties collect this information by default, we take a three-step
              approach: 1) request manual removal of IP tracking, 2) prevent
              this data from being sent to our product analytics services, and
              3) give users the choice to opt-out of analytics entirely.
            </p>
            <p>
              <strong>
                ● Google Analytics is not used on Horus Wallet web application.
              </strong>{' '}
              We use <a href='https://plausible.io/' target='_blank'>Plausible</a>, an open-source alternative that only tracks
              what’s necessary. Plausible doesn’t have an ad-based business
              model and ensures privacy compliance. You can see how it compares
              to Google Analytics <a href='https://plausible.io/vs-google-analytics' target='_blank'>here</a>.
            </p>
            <p>
              <strong>
                ● Horus Wallet does not share user data with third parties, ever.
              </strong>
            </p>
            <p>
              <strong>● Data Storage.</strong> On our web app, aggregated lists
              get passed to our backend to calculate the total value of your
              portfolio in real-time, but this information is not stored.
              Specifically, we trim timestamps on server logs to the hour, so
              cross-associating wallet data is not possible. If I have multiple
              wallets connected to Horus Wallet, one cannot be used to identify
              others.
            </p>
            <p>
              <strong>
                ● We do not collect Personally Identifiable Information.
              </strong>{' '}
              You are not required to provide any PII to use the basic features
              of the Site. You should understand that because of the nature of
              the Ethereum network, your entire transactional history conducted
              from your Wallet is provided to us or otherwise publicly
              accessible by default and is necessary to support your usage of
              the Services.
            </p>
            <p>
              <strong>
                ● We do not automatically collect Personal Information or other
                nonpublic information in connection with transactions you
                conduct using our Services, and we do not use any automatic
                tracking technologies in these parts of our Services unless you
                explicitly agree to the use of these tracking technologies.
              </strong>
            </p>
            <p>
              <strong>● Do Not Track Policy: </strong>Some Internet browsers -
              like Internet Explorer, Chrome Firefox, and Safari - include the
              ability to transmit "Do Not Track" or "DNT" signals. Since uniform
              standards for "DNT" signals have not been adopted, we do not
              currently process or respond to "DNT" signals.
            </p>
            <p>
              <strong>● Information we will never collect:</strong> We will
              never ask you to share your private keys to your Wallet or other
              security information that could be used to access your Wallet
              without your explicit consent and action. Never trust anyone or
              any site that asks you to enter your private keys or similar
              security information.
            </p>
            <h4 className="mt-5">4. STORAGE FOR OUR MOBILE APPLICATION</h4>
            <p>
              For our mobile users, push notifications are slightly different
              because we rely on Apple and Google’s push notification systems.
              In order to send notifications to a given wallet, we need to
              persistently store device tokens (an anonymized string) and the
              list of wallet addresses subscribed to notifications from that
              device. Anyone who wishes to avoid this data being stored entirely
              has the choice to disable push notifications on the Settings
              screen.
            </p>
            <h4 className="mt-5">5. USE USAGE DATA</h4>
            <p>
              As a general policy, we aim to protect your privacy and personal
              information by collecting, using or sharing as little personal
              information as possible. We do not collect such information even
              for “better UX” or the usual argument the collection of such
              information improves your experience.
            </p>
            <h4 className="mt-5">6. SHARING OF THE PERSONAL INFORMATION</h4>
            <p>
              We do not share or sell the Personal Information that you provide
              us with other organizations without your express consent, except
              as described in this Privacy Policy.
            </p>
            <h4 className="mt-5"> 7. HOW WE PROTECT AND STORE INFORMATION</h4>
            <p>
              We endeavor to protect the privacy of the Personal Information we
              hold in our records, but unfortunately, we cannot guarantee
              complete security. The safety and security of your Personal
              Information also depends on you. Unauthorized entry or use,
              hardware or software failure, and other factors may compromise the
              security of user information at any time. Your Wallet is protected
              by your password, private key, and/or seed phrase, and we urge you
              to take steps to keep this and other Personal Information safe by
              not disclosing your security credentials or leaving your Wallet
              open in an unsecured manner. We further protect your Personal
              Information from potential security breaches by implementing
              certain technological security measures including encryption,
              firewalls, and secure socket layer technology. We also seek to
              protect Personal Information by refraining from collecting
              Personal Information where possible. However, these measures do
              not guarantee that your Personal Information will not be accessed,
              disclosed, altered or destroyed by a breach of such firewalls and
              secure server software. By using our Services, you acknowledge
              that you understand and agree to assume these risks.
            </p>
            <p>
              WE DO NOT AND WILL NEVER STORE YOUR WALLET PASSWORD, PRIVATE KEY,
              OR SEED PHRASE TO YOUR WALLET. IF YOU LOSE ACCESS TO BOTH YOUR
              PASSWORD AND SEED PHRASE, WE WILL BE UNABLE TO HELP YOU ACCESS
              YOUR WALLET, AND ANY ASSETS HELD IN THE WALLET MAY BE PERMANENTLY
              IRRETRIEVABLE.
            </p>
            <p>
              We may use any aggregated data derived from or incorporating your
              Personal Information after you update or delete it, but not in a
              manner that would identify you personally.
            </p>
            <h4 className="mt-5"> 8. INTERNATIONAL TRANSFERS</h4>
            <p>
              If you are a resident of the European Economic Area (“EEA”) or
              Switzerland, you may have additional rights under the General Data
              Protection Regulation (the “GDPR”) and other applicable law with
              respect to your Personal Data, as outlined below.
            </p>
            <p>
              For this section, we use the terms “Personal Data” and
              “processing” as they are defined in the GDPR, but “Personal Data”
              generally means information that can be used to individually
              identify a person, and “processing” generally covers actions that
              can be performed in connection with data such as collection, use,
              storage, and disclosure. Horus Wallet will be the controller of your
              Personal Data processed in connection with the Services.
            </p>
            <p>
              If there are any conflicts between this section and any other
              provision of this Privacy Policy, the policy or portion that is
              more protective of Personal Data shall control to the extent of
              such conflict. If you have any questions about this section or
              whether any of the following applies to you, please contact us at 
              <a href="mailto:horus@gmail.com"> horus@gmail.com</a>.
            </p>
            <h4 className="mt-5">
              9. WHAT PERSONAL DATA DO WE COLLECT FROM YOU?
            </h4>
            <p>
              We collect Personal Data about you when you provide such
              information directly to us, when third parties such as our
              business partners or service providers provide us with Personal
              Data about you, or when Personal Data about you is automatically
              collected in connection with your use of our Services.
            </p>
            <p>
              <strong>• Information we collect directly from you:</strong> We
              receive Personal Data directly from you when you provide us with
              such Personal Data, including without limitation, Personal
              Information as described above under “Information You Provide to
              Us” and “Information Collected from Other Sources”, and any other
              information you may elect to submit in your communications with us
              while using our Services. You are not required to submit Personal
              Data solely in connection with your use of your Wallet through our
              Services, but certain of our other Services may require submission
              of Personal Data to fully exploit those Services.
            </p>
            <p>
              <strong>
                • Information we automatically collect when you use our
                Services:
              </strong>{' '}
              Some Personal Data is automatically collected when you use our
              Services, including without limitation, the information described
              above under “Information Collected Automatically.”
            </p>
            <h4 className="mt-5">
              {' '}
              10. YOUR RIGHTS REGARDING YOUR PERSONAL DATA
            </h4>
            <p>
              You have certain rights with respect to your Personal Data,
              including those set forth below. For more information about these
              rights, or to submit a request, please email <a href="mailto:horus@gmail.com">horus@gmail.com</a>.
              Please note that in some circumstances, we may not be able to
              fully comply with your request, such as if it is frivolous or
              extremely impractical, if it jeopardizes the rights of others, or
              if it is not required by law, but in those circumstances, we will
              still respond to notify you of such a decision. In some cases, we
              may also need you to provide us with additional information, which
              may include Personal Data, if necessary, to verify your identity
              and the nature of your request.
            </p>
            <p>
              <strong>• Access:</strong> You can request more information about
              the Personal Data we hold about you and request a copy of such
              Personal Data. You can also access certain of your Personal Data
              by visiting the Horus Wallet Interface or the Horus Wallet App.
            </p>
            <p>
              <strong>• Rectification:</strong> If you believe that any Personal
              Data we are holding about you is incorrect or incomplete, you can
              request that we correct or supplement such data. You can correct
              some of this information directly by editing them on the Horus Wallet
              Interface or the Horus Wallet App.
            </p>
            <p>
              <strong>• Erasure:</strong> You can request that we erase some or
              all your Personal Data from our systems, provided that this will
              not erase any Personal Data you have submitted to the Ethereum
              network.
            </p>
            <p>
              <strong>• Withdrawal of Consent:</strong> If we are processing
              your Personal Data based on your consent (as indicated at the time
              of collection of such data), you have the right to withdraw your
              consent at any time. Please note that if you exercise this right,
              you may have to then provide express consent on a case-by-case
              basis for the use or disclosure of certain of your Personal Data,
              if such use or disclosure is necessary to enable you to utilize
              some or all of our Services.
            </p>
            <p>
              <strong>• Portability:</strong> You can ask for a copy of your
              Personal Data in a machine-readable format. You can also request
              that we transmit the data to another controller where technically
              feasible.
            </p>
            <p>
              <strong>• Objection:</strong> You can contact us to let us know
              that you object to the further use or disclosure of your Personal
              Data for certain purposes.
            </p>
            <p>
              <strong> • Restriction of Processing: </strong>You can ask us to
              restrict further processing of your Personal Data.
            </p>
            <p>
              <strong>• Right to File Complaint:</strong> You have the right to
              lodge a complaint about Horus Wallet practices with respect to your
              Personal Data with the supervisory authority of your country or
              European Union Member State.
            </p>
            <h4 className="mt-5"> 11. TRANSFERS OF PERSONAL DATA</h4>
            <p>
              Certain of the Services are hosted and operated in part in the
              United States (“U.S.”) through Horus Wallet and its service providers,
              and if you do not reside in the U.S., laws in the U.S. may differ
              from the laws where you reside. By using the Services and
              providing your information, you acknowledge that any Personal Data
              about you, regardless of whether provided by you or obtained from
              a third party, may be provided to Horus Wallet in the U.S. and may be
              hosted on U.S. servers. You hereby consent to and authorize Horus Wallet
              to transfer, store and process your information to and in the
              U.S., and possibly other countries. We will take all steps
              reasonably necessary to ensure that your information is treated
              securely and in accordance with this Policy. One such step we may
              take to ensure the security of your Personal Information in the
              event that a transfer may not be subject to the same protection in
              the EEA or Switzerland, is to enter into specific contract clauses
              approved by the European Commission which ensure your personal
              information is given the same protection it has in Europe.
            </p>
            <h4 className="mt-5">
              12. WHAT IF YOU HAVE QUESTIONS REGARDING YOUR PERSONAL DATA?
            </h4>
            <p>
              If you have any questions about this section or our data practices
              generally, please contact us using the following information:
            </p>
            <p>
              <strong>• Designated Representative Name:</strong> Evgeny Yurtaev
            </p>
            <p>
              <strong>• Physical address:</strong> 1412 Market Street, San
              Francisco, CA 94102
            </p>
            <p>
              <strong>• Email address for contact: </strong> <a href="mailto:horus@gmail.com">horus@gmail.com</a>
            </p>
            <h4 className="mt-5">13. CALIFORNIA PRIVACY RIGHTS</h4>
            <p>
              Under the CCPA, you may have the following consumer rights. Please
              note that these rights are not absolute and in certain cases are
              subject to conditions or limitations as specified in the CCPA:
            </p>
            <p>
              • For personal information collected by us during the preceding 12
              months preceding your request that is not otherwise subject to an
              exception, California residents have the right to access and
              delete their personal information. Horus Wallet will not discriminate
              against those who exercise their rights. Specifically, if you
              exercise your rights, we will not deny you services, charge you
              different prices for services or provide you a different level or
              quality of services.
            </p>
            <p>
              • To the extent we sell your personal information to third
              parties, you also have the right to request that we disclose to
              you: (i) the categories of your personal information that we sold,
              and (ii) the categories of third parties to whom your personal
              information was sold. You have the right to direct us not to sell
              your personal information. Horus Wallet does not sell your personal
              information in its ordinary course of business and will never sell
              your personal information to third parties without your explicit
              consent.
            </p>
            <p>
              Should Horus Wallet engage in any of the activities listed in this
              section, your ability to exercise these rights will be made
              available to you in your account settings. You can exercise your
              rights by going to your Privacy Rights Dashboard or contacting us
              via our Support Portal so that we may consider your request. We
              will need to verify your identity before processing your request.
              In order to verify your identity, we will generally either require
              the successful login to your account or the matching of sufficient
              information you provide us to the information we maintain about
              you in our systems. Although we try to limit the personal
              information collected in connection with a request to exercise
              your rights, certain requests may require us to obtain additional
              personal information from you. In certain circumstances, we may
              decline a request to exercise the right to know and right to
              deletion, particularly where we are unable to verify your
              identity.
            </p>
            <p>
              If you are a California resident, you may designate an authorized
              agent to make a request to access or a request to delete on your
              behalf. To do so, you must: (1) provide that authorized agent
              written and signed permission to submit such a request; and (2)
              verify your own identity directly with us. Please note, we may
              deny a request from an authorized agent that does not submit proof
              that they have been authorized by you to act on your behalf. We
              will respond to your authorized agent's request if they submit
              proof that they are registered with the California Secretary of
              State to be able to act on your behalf, or submit evidence you
              have provided them with power of attorney pursuant to California
              Probate Code section 4121 to 4130. We may deny requests from
              authorized agents who do not submit proof that they have been
              authorized by you to act on their behalf, or are unable to verify
              their identity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
