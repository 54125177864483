import { Alchemy, Network } from "alchemy-sdk";

export const ALCHEMY_CONFIG_DICT = {
  AMOY: {
    apiKey: "wnqFuXyCuUgYvBEwfHEJMZqmIsEMTmOk",
    network: Network.MATIC_AMOY,
  },
  ETHEREUM_TESTNET: {
    apiKey: "1XhXKXcfprffTmOYWF0VOPgDmKMCib5j",
    network: Network.ETH_SEPOLIA,
  },
  ETHEREUM: {
    apiKey: "QDrY1bvoAUUEYqvKoBxQldKOUDGTdBKn",
    network: Network.ETH_MAINNET,
  },
  POLYGON: {
    apiKey: "QDrY1bvoAUUEYqvKoBxQldKOUDGTdBKn",
    network: Network.MATIC_MAINNET,
  },
  BASE_SEPOLIA: {
    apiKey: "7ftmoARfpp68SFWpOvkBLiP02hXMwWSb",
    network: Network.BASE_SEPOLIA,
  },
  BASE: {
    apiKey: "QDrY1bvoAUUEYqvKoBxQldKOUDGTdBKn",
    network: Network.BASE_MAINNET,
  },
};

export const ALCHEMY_APIS_DICT = {
  ETHEREUM: {
    TESTNET: new Alchemy(ALCHEMY_CONFIG_DICT.ETHEREUM_TESTNET),
    MAINNET: new Alchemy(ALCHEMY_CONFIG_DICT.ETHEREUM),
  },
  POLYGON: {
    TESTNET: new Alchemy(ALCHEMY_CONFIG_DICT.AMOY),
    MAINNET: new Alchemy(ALCHEMY_CONFIG_DICT.POLYGON),
  },
  BASE: {
    TESTNET: new Alchemy(ALCHEMY_CONFIG_DICT.BASE_SEPOLIA),
    MAINNET: new Alchemy(ALCHEMY_CONFIG_DICT.BASE),
  },
};

export const UNIBLOCK_CHAIN_IDS_DICT = {
  ETHEREUM: {
    MAINNET: 1,
    TESTNET: 11155111,
  },
  BSC: {
    MAINNET: 56,
    TESTNET: 97,
  },
  POLYGON: {
    MAINNET: 137,
    TESTNET: 80002,
  },
  BASE: {
    MAINNET: 8453,
    TESTNET: 84532,
  },
};

export const UNIBLOCK_OPTIONS = {
  method: "GET",
  headers: {
    accept: "application/json",
    "X-API-KEY": process.env.REACT_APP_UNIBLOCK_API_KEY,
  },
};
