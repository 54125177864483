import toast from "react-hot-toast";
import axiosToken from "../../../social-wallet/AxiosToken";
import {
  NFTS_PROXY_CONTRACT_ADDRESS,
  NFTS_PROXY_ABI,
  ERC721_ABI,
  ERC1155_ABI,
} from "./NftsProxy_Constants";
import { signTransaction, getHashedWalletId } from "../../utils/utils";
import { displayMessageWithLink } from "../../../../helper/helperFunc";
import { sendNewUserEmail } from "../../../../api/sendEmail";
import fetch from "node-fetch";
import { writeToContractByWagmi, readFromContract } from "../../utils/utils";

export const depositNft_nonCustodial = async (email, nftInfo, network) => {
  if (email == null || email == undefined || email == "") {
    toast.error("Error: No recipient valid email detected");
    return;
  }
  try {
    await getNftApproval_nonCustodial(network, nftInfo);
    const hashedEmail = await getHashedWalletId(email, network.chain);
    await writeToContractByWagmi(
      {
        address:
          NFTS_PROXY_CONTRACT_ADDRESS[network.chain][
            process.env.REACT_APP_NETWORK_TYPE
          ],
        abi: NFTS_PROXY_ABI,
      },
      {
        functionName: "depositNfts",
        args: [
          [hashedEmail],
          [
            {
              contractAddress: nftInfo.contractAddress,
              name: nftInfo.name,
              tokenUri: nftInfo.tokenUri,
              image: nftInfo.metadata.image,
              tokenId: nftInfo.tokenId,
            },
          ],
        ],
      },
      network.chain,
      "Depositing NFT"
    );
    sendNewUserEmail(email, "", nftInfo.name + " NFT", "NFT");
    return true;
  } catch (err) {
    console.log(err);
    toast.error("Transaction Failed");
    return false;
  }
};

const getNftApproval_nonCustodial = async (network, nftInfo) => {
  if (nftInfo.tokenType == "ERC1155") {
    await writeToContractByWagmi(
      {
        address: nftInfo.contractAddress,
        abi: ERC1155_ABI,
      },
      {
        functionName: "setApprovalForAll",
        args: [
          NFTS_PROXY_CONTRACT_ADDRESS[network.chain][
            process.env.REACT_APP_NETWORK_TYPE
          ],
          true,
        ],
      },
      network.chain,
      "Approving Transaction"
    );
  } else {
    await writeToContractByWagmi(
      {
        address: nftInfo.contractAddress,
        abi: ERC721_ABI,
      },
      {
        functionName: "approve",
        args: [
          NFTS_PROXY_CONTRACT_ADDRESS[network.chain][
            process.env.REACT_APP_NETWORK_TYPE
          ],
          nftInfo.tokenId,
        ],
      },
      network.chain,
      "Approving Transaction"
    );
  }
};

export const depositNft_custodial = async (
  walletId,
  walletIndex,
  to,
  nftInfo,
  network,
  password
) => {
  if (walletId == null || walletId == undefined || walletId == "") {
    toast.error("Error: No recipient valid detected");
    return;
  }
  try {
    console.log("Depositing NFT");
    console.log(nftInfo);
    const hashedEmail = await getHashedWalletId(to, network.chain);

    const depositTx = await signTransaction(
      walletId,
      walletIndex,
      network.chain,
      password,
      [
        nftInfo.tokenType == "ERC1155"
          ? {
              contractAddress: nftInfo.contractAddress,
              abi: ERC1155_ABI,
              functionName: "setApprovalForAll",
              params: [
                NFTS_PROXY_CONTRACT_ADDRESS[network.chain][
                  process.env.REACT_APP_NETWORK_TYPE
                ],
                true,
              ],
            }
          : {
              contractAddress: nftInfo.contractAddress,
              abi: ERC721_ABI,
              functionName: "approve",
              params: [
                NFTS_PROXY_CONTRACT_ADDRESS[network.chain][
                  process.env.REACT_APP_NETWORK_TYPE
                ],
                nftInfo.tokenId,
              ],
            },
        {
          contractAddress:
            NFTS_PROXY_CONTRACT_ADDRESS[network.chain][
              process.env.REACT_APP_NETWORK_TYPE
            ],
          abi: NFTS_PROXY_ABI,
          functionName: "depositNfts",
          params: [
            [hashedEmail],
            [
              {
                contractAddress: nftInfo.contractAddress,
                name: nftInfo.name,
                tokenUri: nftInfo.tokenUri,
                image: nftInfo.metadata.image,
                tokenId: nftInfo.tokenId,
              },
            ],
          ],
        },
      ],
      "Depositing NFT"
    );

    sendNewUserEmail(walletId, "", nftInfo.name + " NFT", "NFT");

    return depositTx != null;
  } catch (err) {
    if (err.message.includes("User canceled operation")) {
      toast.error("NFT deposit canceled");
    } else if (err.message.includes("user rejected transaction")) {
      toast.error("User rejected transaction");
    } else {
      console.error("Error while depositing NFT:", err);
      toast.error(err.message);
    }
    return false;
  }
};

export const getDepositedNfts = async (walletId, network) => {
  const hashedWalletId = await getHashedWalletId(walletId, network.chain);
  const res = await readFromContract(
    {
      address:
        NFTS_PROXY_CONTRACT_ADDRESS[network.chain][
          process.env.REACT_APP_NETWORK_TYPE
        ],
      abi: NFTS_PROXY_ABI,
    },
    {
      functionName: "getUserNfts",
      args: [hashedWalletId],
    },
    network
  );
  console.log(res);
  let nfts = [];
  for (let i = 0; i < res.length; i++) {
    if (res[i].contractAddress == "0x0000000000000000000000000000000000000000")
      continue;
    let nftMetadataURI = formatURI(res[i].tokenUri);
    if (!nftMetadataURI) continue;
    try {
      const response = await fetch(nftMetadataURI);
      let json = await response.json();
      nfts.push({
        ...res[i],
        image: formatURI(json.image),
      });
    } catch (err) {
      console.log(err);
      continue;
    }
  }
  return nfts;
};

export function formatURI(uri) {
  try {
    if (!uri) {
      // Handle null or undefined URIs
      console.error("formatURI was called with a null or undefined URI");
      return null;
    }

    if (typeof uri !== "string") {
      // Handle non-string URIs
      console.error("formatURI was called with a non-string URI");
      return null;
    }
    if (uri.startsWith("ipfs://")) {
      uri = uri.replace("ipfs://", "https://ipfs.io/ipfs/");
    }
    if (uri.endsWith("/") || uri.endsWith("\u0001")) {
      uri += "0";
    }

    return uri;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export const claimNft = async (walletId, nftInfo, network, password) => {
  const txPromise = axiosToken.post("withdraw", {
    walletId: walletId,
    network: network.chain,
    networkType: process.env.REACT_APP_NETWORK_TYPE,
    abi: NFTS_PROXY_ABI,
    method: "claimNft",
    contractAddress:
      NFTS_PROXY_CONTRACT_ADDRESS[network.chain][
        process.env.REACT_APP_NETWORK_TYPE
      ],
    methodArgs: [nftInfo],
    password: password,
  });
  await toast.promise(txPromise, {
    loading: "Claim NFT",
    success: (res) =>
      displayMessageWithLink(res.data.signedDeployHash.hash, "", network.chain),
    error: "Claiming Failed",
  });
};

export const claimAllNft = async (walletId, network, password) => {
  try {
    const txPromise = axiosToken.post("withdraw", {
      walletId: walletId,
      network: network.chain,
      networkType: process.env.REACT_APP_NETWORK_TYPE,
      abi: NFTS_PROXY_ABI,
      method: "claimAllNfts",
      contractAddress:
        NFTS_PROXY_CONTRACT_ADDRESS[network.chain][
          process.env.REACT_APP_NETWORK_TYPE
        ],
      password: password,
    });
    await toast.promise(txPromise, {
      loading: "Claiming NFTs",
      success: (res) =>
        displayMessageWithLink(
          res.data.signedDeployHash.hash,
          "",
          network.chain
        ),
      error: "Claiming Failed",
    });
  } catch (err) {
    console.log(err);
  }
};

export const transferNft_custodial = async (
  walletId,
  walletIndex,
  from,
  to,
  nftInfo,
  network,
  password
) => {
  if (walletId == null || walletId == undefined || walletId == "") {
    toast.error("Error: No recipient valid detected");
    return;
  }
  try {
    const transferTx = await signTransaction(
      walletId,
      walletIndex,
      network.chain,
      password,
      [
        nftInfo.tokenType == "ERC1155"
          ? {
              contractAddress: nftInfo.contractAddress,
              abi: ERC1155_ABI,
              functionName: "safeTransferFrom",
              params: [from, to, nftInfo.tokenId, 1, "0x"],
            }
          : {
              contractAddress: nftInfo.contractAddress,
              abi: ERC721_ABI,
              functionName: "transferFrom",
              params: [from, to, nftInfo.tokenId],
            },
      ],
      "Transferring NFT"
    );
    console.log(transferTx);
    return transferTx;
  } catch (err) {
    toast.error(err.message);
  }
};

export const transferNft_nonCustodial = async (from, to, nftInfo, network) => {
  if (to == null || to == undefined || to == "") {
    toast.error("Error: No recipient valid detected");
    return;
  }
  try {
    if (nftInfo.tokenType == "ERC1155") {
      await writeToContractByWagmi(
        {
          address: nftInfo.contractAddress,
          abi: ERC1155_ABI,
        },
        {
          functionName: "safeTransferFrom",
          args: [from, to, nftInfo.tokenId, 1, "0x"],
        },
        network.chain,
        "Transferring NFT"
      );
    } else {
      await writeToContractByWagmi(
        {
          address: nftInfo.contractAddress,
          abi: ERC721_ABI,
        },
        {
          functionName: "transferFrom",
          args: [from, to, nftInfo.tokenId],
        },
        network.chain,
        "Transferring NFT"
      );
    }
  } catch (err) {
    toast.error(err.message);
  }
};
