import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/web3/context/AuthContext";
import defaultTokenLogo from "../../assets/images/logo/Coin.svg";
import ButtonLoader from "../../components/common/preloader/buttonLoader";
import { withdraw } from "../../components/web3/ERC20-utils/proxy-payment/ProxyPayment_Utils";
import WalletTooltip from "../../components/social-wallet/tooltip";
import { toast } from "react-hot-toast";
import { openPasswordModel } from "../../components/social-wallet/PasswordModal";
import { vaultClient } from "../../components/web3/CASPER-utils/vaultClient";
import { CLPublicKey } from 'casper-js-sdk';
import ReactGA from 'react-ga4';
import { ChainsObjectsArr } from "../../chainsStaticObject/chainsStaticData";
import Preloader from "../../components/common/preloader/preloader";
const ClaimFund = () => {
  const {
    loginOption,
    entityInfo,
    isLoggedIn,
    network,
    email,
    hasPassword,
    depositBalance,
    isLoading,
    refreshBalance,
    refreshDepositBalance,
    refreshAuth,
  } = useAuth();
  const [fundArr, setFundArr] = React.useState();
  const navigate = useNavigate();
  const [loadingStates, setLoadingStates] = React.useState({});
  const [isMaxActive, setIsMaxActive] = React.useState(false);
  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/ClaimFund', title: 'Claim Fund Page' });
    refreshAuth();
  }, [network]);

  React.useEffect(() => {
    if (isLoggedIn) {
      setFundArr([])
      let updatedFundArr;
      if (network.supportedOptions[loginOption].Claim_Tokens) {
        console.log("entityInfo: ", entityInfo);
        updatedFundArr = entityInfo.depositFund?.map((item) => ({
          ...item,
          value: "",
        }));
      } 
      setFundArr(updatedFundArr);
    }
  }, [isLoggedIn, network, entityInfo, depositBalance]);

  const handleClaimFunds = async (item) => {
    if (item.value > item.balance) {
      toast.error("Your requested amount exceeds your available funds");
      return;
    }
    if (item.value == 0) return;
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [item.contractAddress]: true,
    }));
    try {
      if (hasPassword) {
        openPasswordModel(claimFundWithSecuredWallet, { item: item });
      } else {
        let isSuccess;
        const claimAmount = item.value != "" ? item.value : item.balance;
        if (network.chain != "CASPER") {

          isSuccess = await withdraw(email, network.chain, item.contractAddress, claimAmount);
        } else {
          // isSuccess = await vaultClient.claimDeposit(CLPublicKey.fromHex(entityInfo.activePublicKey),parseInt(claimAmount) , email,CLPublicKey.fromHex(entityInfo.activePublicKey))
          //Task: call claim casper deposit 
        }

        if (isSuccess) {
          const receiverWallet = entityInfo.userWalletsData.EVM[network.chain]?.receivingAddress ?
            entityInfo.userWalletsData.EVM[network.chain]?.receivingAddress : entityInfo.userWalletsData.EVM.defaultReceivingAddress
          let splitWallet = receiverWallet.slice(-6);
          toast.success(`Your fund transferred to wallet ....${splitWallet}`)
          refreshBalance();
          refreshDepositBalance();
          refreshAuth();
        } else {
          toast.error("Something went wrong!! please try again later..")
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message)
    }
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [item.contractAddress]: false,
    }));
  };
  const selectMaxBalance = (item) => {
    const updatedArray = fundArr.map((fund) => {
      if (fund.name === item.name) {
        const maxBalance = parseFloat(fund.balance);
        return { ...fund, value: maxBalance.toString() };
      }
      return fund;
    });
    setFundArr(updatedArray);
  };
  const handleChange = async (e) => {
    const { value, name } = e.target;
    const updatedArray = fundArr.map((item) => {
      if (item.name === name) {
        const balance = Number(item.balance);
        const newValue = Number(value);
        // Check if the input value exceeds the available balance
        const validatedValue = newValue > balance ? "0" : value;
        return { ...item, value: validatedValue };
      }
      return item;
    });

    setFundArr(updatedArray);
  };
  const claimFundWithSecuredWallet = async (password, input) => {
    try {
      const claimAmount = input.item.value != "" ? input.item.value : input.item.balance;
      let isSuccess;
      if (network.chain != "CASPER") {
        isSuccess = await withdraw(email, network.chain, input.item.contractAddress, claimAmount, password);
      } else {
        // isSuccess = await vaultClient.claimDeposit(CLPublicKey.fromHex(entityInfo.activePublicKey),parseInt(claimAmount) , email,CLPublicKey.fromHex(entityInfo.activePublicKey))
        //Task: call claim casper deposit api
      }

      if (isSuccess) {
        const receiverWallet = entityInfo.userWalletsData.EVM[network.chain]?.receivingAddress ?
          entityInfo.userWalletsData.EVM[network.chain]?.receivingAddress : entityInfo.userWalletsData.EVM.defaultReceivingAddress
        let splitWallet = receiverWallet.slice(-6);
        toast.success(`Your fund transferred to wallet ....${splitWallet}`)
        refreshBalance();
        refreshDepositBalance();
        refreshAuth();
      } else {
        toast.error("Something went wrong!! please try again later..")
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message)
    }

  }
  return (
    <>
      <Preloader show={isLoading} />
      <div className="mt-5">
        <div className="container-flued">
          <>
          {isLoggedIn ? (
            <>
              {fundArr &&
                fundArr.length && fundArr.length > 0 ?(
                  <div className="fugu-fund-table-wrapper">
                    {fundArr.map((elem, index) => (

                      <div
                        className="row mx-2 py-2 justify-content-center align-items-center d-flex text-warning-600"
                        key={index}
                      >
                        <div className="col-lg-1 col-md-1 col-auto">
                          <img
                            src={elem.logo ? ChainsObjectsArr.find(chain => chain.symbol === elem.symbol)?.image : defaultTokenLogo}
                            className="fugu-network-icons mx-2"
                          />
                        </div>
                        <div className="col-lg-2 col-md-2 col-auto">
                          <WalletTooltip title="Token name">
                            <div>{elem.name}</div>
                          </WalletTooltip>
                        </div>
                        <div className="col-lg-2 col-md-2 col-auto">
                          <WalletTooltip title={"Token symbol"}>
                            <div>{elem.symbol}</div>
                          </WalletTooltip>
                        </div>
                        <div className={`col-lg-2 col-md-2 col-auto ${elem.balance == 0 ? "" : "text-warning-500"}`}>
                          <div className='row justify-content-center align-items-center'>

                            <div className='col'>
                              <WalletTooltip title="available balance to claim">
                                <div>
                                  {elem.balance}
                                </div>
                              </WalletTooltip>
                            </div>
                            <div className='col'>
                              <WalletTooltip title={"pay max balance"}>

                                <button
                                  className={`fugu-max-btn ${isMaxActive ? "active" : ""}`}
                                  onClick={() => selectMaxBalance(elem)}
                                >
                                  <span>Max</span>
                                </button>

                              </WalletTooltip>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 my-2 col-auto fugu-transfer-amount-box ps-1">
                          <WalletTooltip title={"set claim amount "}>
                            <div className="fugu-to-receiver">
                              <span>
                                <input
                                  name={elem.name}
                                  type="number"
                                  placeholder="Amount"
                                  value={elem.value}
                                  onChange={(e) => handleChange(e)}
                                  id="fundAmount"
                                  disabled={elem.balance == 0}
                                  onWheel={(event) => event.currentTarget.blur()}
                                />
                              </span>
                            </div>
                          </WalletTooltip>
                        </div>
                        <div className="col-lg-2 col-md-2 col-auto fugu-user-balance fugu-send-payment-btn">
                          <button
                            type="submit"
                            id={`fugu--submit-btn`}
                            className="fugu-send-payment ms-1"
                            onClick={() => elem.value != "" && handleClaimFunds(elem)}
                            disabled={elem.balance == 0}
                          >
                            <span>Claim</span>
                            {loadingStates[elem.contractAddress] && <ButtonLoader />}
                          </button>
                        </div>
                      </div>

                    ))}
                  </div>
                ):<h1 className='text-white text-center mt-5 pt-5'>NO Funds To CLaim</h1>}

            </>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="header-btn">
                <a className="fugu--btn fugu--menu-btn1" href="/">
                  Connect with system
                </a>
              </div>
            </div>
          )}
          </>
        </div>
      </div>
    </>
  );
};

export default ClaimFund;
