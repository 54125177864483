import NFTS_PROXY from "./NftsProxy.json";
import ERC721 from "./ERC721.json";
import ERC1155 from "./ERC1155.json";

export const NFTS_PROXY_CONTRACT_ADDRESS = {
  POLYGON: {
    TESTNET: "0x1E39dB59426ffCb5c0b79E33b466dc41660bb801",
    MAINNET: "0x83a0FFfEb0bcD9CfBba0e7E927DBBF719e97b6b3",
  },
  ETHEREUM: {
    TESTNET: "0xC61272D208c106b291d41dE7781aB4EeB88967B0",
    MAINNET: "",
  },
  BSC: {
    TESTNET: "",
    MAINNET: "",
  },
  TELOS: {
    TESTNET: "",
    MAINNET: "",
  },
  FLARE: {
    TESTNET: "",
    MAINNET: "",
  },
  BASE: {
    TESTNET: "0xAb8D4213115Dd543bAC7d6591E6057C6ABEE4909",
    MAINNET: "0x046235053Fa4131A9B628210e2A3182D13e7c04c",
  },
};

export const NFTS_LA = {
  contractAddress: "0xd4684535d4FbAda35BC3Ed7d904E4a5E8611b166",
  metadata: {
    image:
      "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmawP5eP3CcCmVw19uQq2LvoCDTcBCnHxsELRTCCQTWGfm/image.jpeg",
    name: "NFTLA Wk 2024 Commemorative NFT",
    attributes: [
      {
        trait_type: "Event",
        value: "NFT | LA Community Week Commemorative",
      },
      {
        trait_type: "Year",
        value: "2024",
      },
      {
        trait_type: "Artist",
        value: 'Michael Brooks "The Rook" 1986-2024',
      },
    ],
  },
  name: "NFTLA WK",
  tokenId: 0,
};
export const NFTS_PROXY_ABI = NFTS_PROXY.abi;

export const ERC721_ABI = ERC721.abi;
export const ERC1155_ABI = ERC1155.abi;
