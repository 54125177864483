import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

function CustomCalenderInput({ value, onClick }, ref) {
    return (
        <div className='p-3'>
            <input
                ref={ref}
                type="text"
                className="fugu-date-picker d-none"
                value={value}
                onClick={onClick}
                readOnly
            />
            <div className="fugu-schedule-icon text-gray-10 " onClick={onClick}>
                <FontAwesomeIcon icon={faCalendar} />
            </div>
        </div>
    )
}

export default React.forwardRef(CustomCalenderInput)