import React, { useState } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import defaultBg from '../../assets/images/all-img/v3/dashboardBg.jpg';
import OptionsController from '../../components/collections-manager/generative-nfts/generative-image-by-ai-layers/options-controller';
import BackButton from '../../components/common/backButton';
import toast from 'react-hot-toast';
import Preloader from '../../components/common/preloader/preloader';
import { createCollection } from '../../components/collections-manager/create-collection/collection-ui-utils';
import { useAuth } from '../../components/web3/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ButtonLoader from '../../components/common/preloader/buttonLoader';
import aiApiIssue from '../../assets/images/collections/aiIssuePlaceholder.png';
import aiplaceholder from '../../assets/images/collections/aiPlaceholder.jpg';
import PreviewCollectionModalModal from '../../components/collections-manager/generative-nfts/generative-image-by-ai-layers/previewCollectionModal';
function GenerativeAiLayersCollection({ cancel }) {
  const {
    isLoggedIn,
    loginOption,
    hasPassword,
    network,
    balance,
    entityInfo,
    email,
  } = useAuth();
  let initialNftProps = {
    name: '',
    symbol: '',
    nftCount: '',
    mainCharacter: '',
  };
  let navigate = useNavigate();
  const [collectionProps, setCollectionProps] = useState(initialNftProps);
  const [layersData, setLayersData] = useState();
  const [AllLayersInfo, setAllLayersInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [generateClicked, setGenerateClicked] = useState(false);
  const [generateClickedSpinner, setGenerateClickedSpinner] = useState(false);
  const [showPreviewCollectionModal, setShowPreviewCollectionModal] =
    useState(false);
  const [
    generatePreviewCollectionClickedSpinner,
    setGeneratePreviewCollectionClickedSpinner,
  ] = useState(false);
  const [generatedImageForDisplay, setGeneratedImageForDisplay] = useState();
  const [errorInApi, setErrorInAPI] = useState();
  const [confirmedURI, setConfirmedURI] = useState();

  const handleChange = (e) => {
    const { value, name } = e.target;
    setCollectionProps((prevNftProps) => ({
      ...prevNftProps,
      [name]: value,
    }));
  };
  const handleMintCollection = async () => {
    try {
      const layersInfo = layersData.map((item) => {
        return {
          name: item.layer.name,
          number: item.layer.index,
          options: item.traits.map((obj) => obj.traitOption),
          probs: item.traits.map((obj) => Number(obj.traitProb) / 100),
        };
      });
      const collectionData = {
        mainCharacter: collectionProps.mainCharacter,
        layersInfo,
        nftsCount: collectionProps.nftCount,
      };
      await createCollection({
        balance,
        isLoggedIn,
        loginOption,
        hasPassword,
        network,
        collectionProps: { layers: collectionData, ...collectionProps },
        collectionTraits: [],
        isGeneratedImageLayers: false,
        isGeneratedImageAILayersHasURI: confirmedURI ? true : false,
        isGeneratedImageAILayers: confirmedURI ? false : true,
        confirmedURI,
        email,
        entityInfo,
        setLoading,
      });
      setLoading(false);
      navigate('/transferDashboard?selectedTab=Collections');
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };
  const previewImage = async () => {
    setGenerateClickedSpinner(true);
    setGenerateClicked(true);
    let dummyLayersSentence = 'that has';
    layersData.forEach((layer, index) => {
      dummyLayersSentence =
        dummyLayersSentence +
          ' ' +
          layer.traits[0].traitOption +
          ' ' +
          layer.layer.name +
          index ==
          layersData.length - 1 && ' and';
    });
    const content = ` I want an image of a ${collectionProps.mainCharacter} ${dummyLayersSentence}`;
    const endpoint = `${process.env.REACT_APP_GENERATE_AI_IMAGE_API}myAiChat`;
    const data = { content, type: 'image' };
    try {
      const response = await axios.post(endpoint, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setGenerateClickedSpinner(false);
      setGeneratedImageForDisplay(
        `data:image/png;base64,${response.data.generated_image}`
      );
      setErrorInAPI(false);
    } catch (error) {
      toast.error('Error querying the AI bot:', error);
      console.error('Error querying the AI bot:', error);
      setGenerateClickedSpinner(false);
      setErrorInAPI(true);
      return null;
    }
  };

  const handlePreviewImages = () => {
    const layersInfo = layersData.map((item) => {
      return {
        name: item.layer.name,
        number: item.layer.index,
        options: item.traits.map((obj) => obj.traitOption),
        probs: item.traits.map((obj) => Number(obj.traitProb) / 100),
      };
    });
    const collectionData = {
      userAddress: entityInfo?.activePublicKey,
      mainCharacter: collectionProps.mainCharacter,
      layersInfo,
      nftsCount: Number(collectionProps.nftCount),
    };
    setAllLayersInfo(collectionData);
    setShowPreviewCollectionModal(true);
  };

  return (
    <div className="fugu--hero-section container">
      <BackButton selectedTab={'CreateNewCollection'} />
      <h1 className="text-center text-white mb-2">
        Create Generative AI Layers Collection
      </h1>
      <Preloader show={loading} />
      <div className="row mt-5">
        <div className="col-lg-6 ps-0">
          <div className="row">
            <div className="col-lg-6 ps-0">
              <input
                type="default"
                placeholder="Collection Main Character"
                className="text-gray-700 mb-5"
                name="mainCharacter"
                value={collectionProps.mainCharacter}
                onChange={handleChange}
                disabled={confirmedURI !== undefined}
              />
            </div>
            <div className="col-lg-6 ps-0">
              <div className="fugu--portfolio-btn mt-0 mb-5">
                <button
                  className="fugu--outline-btn"
                  disabled={
                    layersData === undefined ||
                    layersData.length == 0 ||
                    collectionProps.mainCharacter == ''
                  }
                  onClick={() => {
                    previewImage();
                  }}
                >
                  <span>
                    {generateClickedSpinner ? (
                      <Spinner />
                    ) : generateClicked ? (
                      'Preview Again'
                    ) : (
                      'Preview'
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <OptionsController
            setLayerProps={setLayersData}
            mainCharacter={collectionProps.mainCharacter}
            isDisabled={confirmedURI !== undefined}
          />
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="fugu--blog-content pt-0">
              <div className="fugu--blog-title">
                <div className="row justify-content-center">
                  <div className="col text-center">
                    <img
                      src={
                        errorInApi
                          ? aiApiIssue
                          : generatedImageForDisplay
                          ? generatedImageForDisplay
                          : aiplaceholder
                      }
                      alt={`Holder nft Image`}
                      className=""
                    />
                  </div>
                </div>
                <div className="row mt-3 ">
                  <div className="col">
                    <input
                      type="default"
                      placeholder="Collection Name"
                      className="text-gray-700"
                      name="name"
                      value={collectionProps.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-3 ">
                  <div className="col">
                    <input
                      type="number"
                      placeholder="Collection NFT Count"
                      className="text-gray-700"
                      name="nftCount"
                      value={collectionProps.nftCount}
                      onChange={handleChange}
                      disabled={confirmedURI !== undefined}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="default"
                      placeholder="Collection Symbol"
                      className="text-gray-700 text-uppercase"
                      name="symbol"
                      value={collectionProps.symbol}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-auto">
          <button
            className="fugu--outline-btn"
            onClick={() => {
              handleMintCollection();
            }}
            disabled={
              loading ||
              collectionProps.mainCharacter == '' ||
              collectionProps.name === '' ||
              collectionProps.symbol === '' ||
              layersData == undefined ||
              (layersData && layersData?.length == 0) ||
              (layersData &&
                layersData?.length > 0 &&
                layersData.every((obj) => obj.sumOfProbs === false))
            }
          >
            <span>Mint Collection</span>
            {loading && <ButtonLoader />}
          </button>
        </div>
        <div className="col-lg-6 ps-0">
          <div className="fugu--portfolio-btn mt-0 mb-5">
            <button
              className="fugu--outline-btn"
              disabled={
                collectionProps.mainCharacter == '' ||
                layersData == undefined ||
                collectionProps.nftCount === '' ||
                (layersData && layersData?.length == 0) ||
                (layersData &&
                  layersData?.length > 0 &&
                  layersData.every((obj) => obj.sumOfProbs === false))
              }
              onClick={() => {
                handlePreviewImages();
              }}
            >
              <span>
                {generatePreviewCollectionClickedSpinner ? (
                  <Spinner />
                ) : generateClicked ? (
                  'Preview Collection Again'
                ) : (
                  'Preview Collection'
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
      {showPreviewCollectionModal && (
        <PreviewCollectionModalModal
          show={showPreviewCollectionModal}
          handleCloseParent={() => setShowPreviewCollectionModal(false)}
          layerInfo={AllLayersInfo}
          setGeneratedURI={(uri) => {
            setConfirmedURI(uri);
          }}
        />
      )}
    </div>
  );
}

export default GenerativeAiLayersCollection;
