import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  TwitterAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  onAuthStateChanged,
} from "firebase/auth";
import { ethers } from "ethers";
import { getAuthConfig } from "./firebase";
import WalletTooltip from "./tooltip";
import google_icon from "./icon/google-icon.png";
import facebook_icon from "./icon/facebook-icon.png";
import twitter_icon from "./icon/twitter-icon.png";
import email_icon from "./icon/email-icon.png";
import phone_icon from "./icon/phone-icon.png";
import toast from "react-hot-toast";
import { openNewPasswordModel } from "./NewPasswordModal";
import axiosToken, { setAxiosToken } from "./AxiosToken";
import { useAuth } from "../web3/context/AuthContext";
import { openPhoneModal } from "./PhoneModal";
import { openEmailModal } from "./EmailModal";

const LoginButtons = ({ freeNft = false }) => {
  // State to store the authenticated user
  const [walletAddress, setWalletAddress] = useState("");
  const [providerName, setProviderName] = useState("");
  const [userWalletsData, setUserWalletsData] = useState();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [newUser, setNewUser] = useState(false)
  const { search } = location;
  const {
    REACT_APP_FACEBOOK_AVAILABLE,
    REACT_APP_TWITTER_AVAILABLE,
    REACT_APP_GOOGLE_AVAILABLE,
  } = process.env;

  const {
    setEmail,
    setToken,
    setIsLoggedIn,
    setHasPassword,
    network,
    entityInfo,
    isLoggedIn,
    setIsLoading,
  } = useAuth();
  const [auth, setAuth] = useState(null);

  // Function to handle authentication with Google
  const handleSignIn = async (loginOption, user = null) => {
    try {
      // Create AuthProvider instance
      let provider;
      let authFlag = true;
      //Replace all if else statements with one line by indexing a map:
      //provider = mapName[loginOption];
      if (loginOption === "google") provider = new GoogleAuthProvider();
      else if (loginOption === "facebook")
        provider = new FacebookAuthProvider();
      else if (loginOption === "twitter") provider = new TwitterAuthProvider();
      else if (loginOption === "phone" || loginOption === "email") {
        authFlag = false;
        // Update user state with the result of signInWithPopup function
        setProviderName(loginOption);
        const accessToken = user.stsTokenManager.accessToken;
        const walletId = user.phoneNumber || user.email;
        setEmail(walletId);
        setNewUser(true)
        setToken(accessToken);
        setAxiosToken(accessToken);
        getWallet(walletId, true);
      }
      if (authFlag) {
        // Get the auth object from firebase
        const auth = await getAuthConfig();
        // Call signInWithPopup function with GoogleAuthProvider instance as argument
        const result = await signInWithPopup(auth, provider);
        // Update user state with the result of signInWithPopup function
        setProviderName(loginOption);
        setEmail(result.user.email);
        setToken(result.user.accessToken);
        setAxiosToken(result.user.accessToken);
        getWallet(result.user.email, true);
      }
    } catch (error) {
      // Log error in the console if any
      console.log(error);
    }
  };
  const initAuth = async () => {
    const authConfig = await getAuthConfig();
    setAuth(authConfig);
  };
  useEffect(() => {
    if (!isLoggedIn && !auth) {
      initAuth();
    } else {
      if (!isLoggedIn&&!newUser) {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          // Handle user state changes
          if (user) {
            console.log("User:", user?.email);
            const walletId = user?.phoneNumber || user?.email;
            // console.log('Access Token:', user?.stsTokenManager?.accessToken);
            if (user?.providerData.length > 0) {
              const providerId = user.providerData[0].providerId;
              const providerName = providerId.split(".")[0];
              setProviderName(providerName);
              // Use the provider information as needed
            }
            setEmail(walletId);
            setToken(user?.stsTokenManager?.accessToken);
            setAxiosToken(user?.stsTokenManager?.accessToken);
            getWallet(walletId, false);
          }
        });
        return () => {
          unsubscribe();
        };
      }
    }
  }, [auth]);
  const getWallet = async (email, isFreshLogin) => {
    setIsLoading(true);
    const apiName = "getWallet";
    try {
      const response = await axiosToken.get(apiName, {
        params: {
          walletId: email,
          network: "",
        },
      });
      console.log("wallet data", response.data);
      //handel vi users case
      if (!response.data.wallets["EVM"]) {
        openNewPasswordModel(createNewAVMWallet, {
          email: email,
          wallets: response.data.wallets,
        });
      } else {
        //check network to handle in case user stand on casper wallet in login
        // const chainHolder =  network?.chain != "CASPER" ? "EVM" : "CASPER"
        const chainHolder = "EVM";
        setHasPassword(
          response.data.wallets[chainHolder].wallets[0].hasPassword
        );
        setWalletAddress(
          response.data.wallets[chainHolder].wallets[0].publicKey
        );
        setUserWalletsData(response.data.wallets);
        setIsLoading(false);

        if (location.pathname !== "/QrNfts") navigate("/transferDashboard");
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error.hasOwnProperty("response") &&
        error.response &&
        error.response.status === 500 &&
        isFreshLogin
      )
        openNewPasswordModel(createWallet, email);
      else {
        isFreshLogin && toast.error("Error getting wallet");
      }
    }
  };
  // this func is  using to create another  wallet  on evm handling the vi users that created new  horus account with casper
  const createNewAVMWallet = async (password, input) => {
    if (password === null) return;
    try {
      // Create a new random wallet
      const apiName = "getWallet";
      try {
        // step1: call get wallet with request new evm wallet
        let response = await axiosToken.get(apiName, {
          params: {
            walletId: input.email,
            network: "POLYGON",
            password: password,
          },
        });
        // step2: append the casper wallets object to user wallets
        response.data.wallets["CASPER"] = input.wallets["CASPER"];
        if (response.status == 200) {
          toast.success("New EVM Wallet is created successfully");
          setHasPassword(response.data.wallets["EVM"].wallets[0].hasPassword);
          setWalletAddress(response.data.wallets["EVM"].wallets[0].publicKey);
          setUserWalletsData(response.data.wallets);
          setIsLoading(false);
          if (location.pathname !== "/QrNfts") navigate("/transferDashboard");
        }
      } catch (error) {
        console.log("error", error);
        toast.error("Error creating wallet");
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Error creating wallet");
    }
  };
  const createWallet = async (password, email) => {
    const apiName = "createWallet";
    const network = "POLYGON";
    if (password === null) return;

    setIsLoading(true);

    try {
      const response = await axiosToken.post(apiName, {
        walletId: email,
        network: network,
        password: password,
      });
      toast.success("Wallet created successfully");
      setHasPassword(response.data.wallets["EVM"].wallets[0].hasPassword);
      setWalletAddress(response.data.wallets["EVM"].wallets[0].publicKey);
      setUserWalletsData(response.data.wallets);

      setIsLoading(false);
      if (location.pathname !== "/QrNfts") navigate("/transferDashboard");
    } catch (error) {
      console.log("error", error);
      toast.error("Error creating wallet");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (walletAddress !== "") {
      let modifiedWalletsWithIndex = {};
      Object.keys(userWalletsData).forEach((key) => {
        let value = userWalletsData[key];
        modifiedWalletsWithIndex[key] = {
          ...value,
          wallets: value.wallets.map((w, index) => ({ ...w, index: index })),
        };
        const wallets = modifiedWalletsWithIndex[key].wallets;
      });
      setIsLoggedIn(
        true,
        walletAddress,
        "custodial",
        providerName,
        0, //active wallet index 0 evm
        modifiedWalletsWithIndex
      );
      setIsLoading(false);
    }
  }, [walletAddress, userWalletsData, entityInfo]);
  useEffect(() => {
    console.log(entityInfo, "user entity");
  }, [entityInfo]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 922) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const checkUserAuthState = async () => {
      if (!user) {
        // user is not signed in but the link is valid
        const auth = await getAuthConfig();
        if (isSignInWithEmailLink(auth, window.location.href)) {
          // now in case user clicks the email link on a different device, we will ask for email confirmation
          let email = localStorage.getItem("email");
          if (email) {
            // after that we will complete the login process
            signInWithEmailLink(auth, email, window.location.href)
              .then((result) => {
                // we can get the user from result.user but no need in this case
                console.log(JSON.stringify(result.user, null, 4));
                setUser(result.user);
                localStorage.removeItem("email");
                // call handleSignIn with email method
                handleSignIn("email", result.user);
              })
              .catch((err) => {
                console.log(`Error: ${err}`);
              });
          } else {
            console.log(`No email found, try again`);
          }
        }
      }
    };
    checkUserAuthState();
  }, [user, search, navigate]);

  return (
    <div
      className={` mx-4  ${
        isSmallScreen && !freeNft ? "mt-5" : freeNft ? "d-flex" : "row"
      } `}
    >
      <div
        className={`${
          !freeNft ? "col-12" : "mx-2"
        }  col-md-auto d-flex my-2 align-items-center mouse-cursor`}
        onClick={() =>
          REACT_APP_GOOGLE_AVAILABLE == "true" ? handleSignIn("google") : null
        }
      >
        <WalletTooltip title="Connect with Google" className="mx-4">
          <img
            alt="google"
            src={google_icon}
            width={45}
            className={`${isSmallScreen && !freeNft && "me-2"}`}
          />
        </WalletTooltip>
        {isSmallScreen && !freeNft && (
          <p className="mx-1 text-warning-600">Connect with Google</p>
        )}
      </div>
      <div
        className={`${
          !freeNft ? "col-12" : ""
        }  col-md-auto d-flex my-2 align-items-center mouse-cursor`}
        onClick={() =>
          REACT_APP_FACEBOOK_AVAILABLE === "true"
            ? handleSignIn("facebook")
            : null
        }
      >
        <WalletTooltip
          title={
            REACT_APP_FACEBOOK_AVAILABLE === "true"
              ? "Connect with Facebook"
              : "Coming soon"
          }
          className="mx-4"
        >
          <div
            className={`image-overlay ${
              REACT_APP_FACEBOOK_AVAILABLE === "true" ? "" : "disabled"
            }`}
          >
            <img
              alt="facebook"
              src={facebook_icon}
              width={45}
              height={45}
              className={`${isSmallScreen && !freeNft && "me-2"}`}
            />
          </div>
        </WalletTooltip>
        {isSmallScreen && !freeNft && (
          <p className="mx-1 text-warning-600">
            {REACT_APP_FACEBOOK_AVAILABLE === "true"
              ? "Connect with Facebook"
              : "Coming soon"}
          </p>
        )}
      </div>

      <div
        className={`${
          !freeNft ? "col-12" : "mx-2"
        }  col-md-auto d-flex my-2 align-items-center mouse-cursor`}
        onClick={() =>
          REACT_APP_TWITTER_AVAILABLE == "true" ? handleSignIn("twitter") : null
        }
      >
        <WalletTooltip
          title={
            REACT_APP_TWITTER_AVAILABLE == "true"
              ? "Connect with Twitter"
              : "Coming soon"
          }
          className="mx-4"
        >
          <div
            className={`image-overlay ${
              REACT_APP_TWITTER_AVAILABLE === "true" ? "" : "disabled"
            }`}
          >
            <img
              alt="twitter"
              src={twitter_icon}
              width={45}
              className={`${isSmallScreen && !freeNft && "me-2"}`}
            />
          </div>
        </WalletTooltip>
        {isSmallScreen && !freeNft && (
          <p className="mx-1 text-warning-600">
            {REACT_APP_TWITTER_AVAILABLE == "true"
              ? "Connect with Twitter"
              : "Coming soon"}
          </p>
        )}
      </div>
      <div
        className={`${
          !freeNft ? "col-12" : "mx-2"
        }  col-md-auto d-flex my-2 align-items-center mouse-cursor`}
        onClick={() => openEmailModal()}
      >
        <WalletTooltip title="Connect with Email" className="mx-4">
          <img
            alt="email"
            src={email_icon}
            width={45}
            className={`${isSmallScreen && !freeNft && "me-2"}`}
          />
        </WalletTooltip>
        {isSmallScreen && !freeNft && (
          <p className="mx-1 text-warning-600">Connect with Email</p>
        )}
      </div>
      <div
        className={`${
          !freeNft ? "col-12" : "mx-2"
        }  col-md-auto d-flex my-2 align-items-center mouse-cursor`}
        onClick={() => openPhoneModal(handleSignIn)}
      >
        <WalletTooltip title="Connect with Phone" className="mx-4">
          <img
            alt="phone"
            src={phone_icon}
            width={45}
            className={`${isSmallScreen && "me-2"}`}
          />
        </WalletTooltip>
        {isSmallScreen && !freeNft && (
          <p className="mx-1 text-warning-600">Connect with Phone</p>
        )}
      </div>
    </div>
  );
};

export default LoginButtons;
