import {Link} from "react-router-dom";
import logoBlock from '../../assets/images/logo/logo-black.svg'

export default function LogoSection() {
	return (
		<Link href="/">
			<img src={logoBlock} alt="" />
		</Link>
	);
}
