import { useState, useRef, useEffect } from 'react';
import ReactGA from 'react-ga4';
import CollectionCreationManager from './collection-creation-manager';

export default function CollectionManager() {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/CollectionManager',
      title: 'Collection Manager Page',
    });
  }, []);

  const collectionLibraryChild = useRef();

  return (
    <div className={`fugu--inner-section opacity-bg `}>
      {/* <Preloader show={loading} /> */}
      <div>
        <div className="fugu--breadcrumbs-section d-flex justify-content-center text-center">
          <div className="fugu--breadcrumbs-data">
            <h1>Manage your collections</h1>
            <p>
              Your source of market analysis, news, developments, and project
              reviews for the NFT ecosystem. Discover and keep up to date with
              the latest NFT.
            </p>
          </div>
        </div>
        <CollectionCreationManager/>
      </div>
    </div>
  );
}
