import HORUS_FACTORY from "./HorusFactory.json";
import HORUS_NFT from "./HorusNFT.json";

export const HORUS_FACTORY_CONTRACT_ADDRESS = {
  POLYGON: {
    TESTNET: "0x32087e722229BE9134da4524D74c00492a77aaC7",
    MAINNET: "0xB8c0b04509e9476a951053CCD8Dc5892A8d7d4c2",
  },
  ETHEREUM: {
    TESTNET: "0xaA1a3b324B6079D71083Bb34F981634c6fb44579",
    MAINNET: "",
  },
  BSC: {
    TESTNET: "",
    MAINNET: "",
  },
  TELOS: {
    TESTNET: "",
    MAINNET: "",
  },
  FLARE: {
    TESTNET: "",
    MAINNET: "",
  },
  BASE: {
    TESTNET: "0xC61272D208c106b291d41dE7781aB4EeB88967B0",
    MAINNET: "0xe1696f8B54B28f8bAff68b538bE73BaEadec6758",
  },
};

export const HORUS_FACTORY_ABI = HORUS_FACTORY.abi;

export const HORUS_NFT_ABI = HORUS_NFT.abi;
