import { CHAIN_SCAN, getEthersProvider } from "./Providers";
import { ethers } from "ethers";
import { BlockchainNetwork } from "./BlockchainNetwork";
import { getDepositBalance, getAllDepositsBalance, depositByEmail, transferByEmail } from "../ERC20-utils/proxy-payment/ProxyPayment_Utils";
import { isValidWalletAddress, validateWallet } from "../../../helper/helperFunc";
export class EvmNetwork extends BlockchainNetwork {
    provider: ethers.providers.JsonRpcProvider;
    constructor(chain: string) {
      super(chain);
      this.provider = getEthersProvider(chain);
    }
  
    async getBalance(address: string, network: any): Promise<number> {
      if (!network) return 0;
    
      let rawBalance = network.tokenAddress === ethers.constants.AddressZero
        ? await this.provider.getBalance(address)
        : await new ethers.Contract(network.tokenAddress, [
            "function balanceOf(address owner) view returns (uint256)"
          ], this.provider).balanceOf(address);
    
      const formattedBalance = ethers.utils.formatUnits(rawBalance, network.decimals);
      return Number(formattedBalance);
    }
  
    async getDeposit(email: string, tokenAddress: string): Promise<any> {
      return await getDepositBalance(email, this.chainName, tokenAddress);
    }

    async getAllDepositsBalance(email: string): Promise<any> {
      return await getAllDepositsBalance(email, this.chainName);
    }

    isValidAddress(address: string): any {
      return isValidWalletAddress(address);
    }

    validateWalletAddress(addresses: []): any {
      return validateWallet(addresses)
    }

    async depositToken(email: string, walletAddress: string, walletIndex: number, receiversIds: [string], amount: string, network: string, password: string ): Promise<any> {
      return depositByEmail(email, walletAddress, walletIndex, receiversIds, amount, network, password);
    }

    async transferToken(email: string, walletAddress: string, walletIndex: number, receiversIds: [string], amount: string, network: string, password: string ): Promise<any> {
      return transferByEmail(email, walletAddress, walletIndex, receiversIds, amount, network, password);
    }

    getChainScanUrlAddress () {
      const chain = CHAIN_SCAN[this.chainName as keyof typeof CHAIN_SCAN]
      return chain[process.env.REACT_APP_NETWORK_TYPE as keyof typeof chain] + 'address/';
    }
  }