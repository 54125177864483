import React, { createContext, useState, useEffect } from 'react'
import { signOut } from 'firebase/auth'
import { getAuthConfig } from '../../social-wallet/firebase'
import { useAccount, useDisconnect, useChainId } from 'wagmi' 
import { getAllBalances } from '../utils/utils';
import { ChainsObjectsArr } from '../../../chainsStaticObject/chainsStaticData'
import { getSupportedOption } from '../utils/Providers';
import { useClickRef } from '@make-software/csprclick-ui';
import { CSPR_CLICK_PROVIDERS_NETWORKS_DIC } from '../../../chainsStaticObject/csprClickProvidersDic'
interface Wallet {
  activePublicKey: string;
  hasPassword: boolean;
}

interface Blockchain {
  defaultReceivingAddress: string;
  wallets: Wallet[];
}

interface UserWalletsData {
  EVM: Blockchain;
  CASPER: Blockchain;
}
interface IEntityInfo {
  activePublicKey: string,
  userWalletsData?: UserWalletsData | null,
  indexWallet: number,
  depositFund: any
}
//interface of the context object
interface IAuthContextValue {
  isLoggedIn: boolean
  balance: string
  depositBalance: string
  entityInfo: IEntityInfo
  email: string;
  token: string;
  loginOption: string;
  loginProvider: string;
  hasPassword: boolean;
  network: any;
  selectedPaymentMethod: string | null;
  userWalletsData?: UserWalletsData | null;
  supportedOptions: any;
  setSupportedOptions: (options: any) => void;
  isLoading: boolean;
  setBalance: (balance: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  setNetwork: (network: string) => void;
  setPaymentMethod: (method: string) => void;
  setHasPassword: (hasPassword: boolean) => void;
  setEmail: (email: string) => void
  setToken: (token: string) => void
  setLoginOption: (signOption: string) => void;
  setLoginProvider: (loginProvider: string) => void;
  login: () => void
  logout: () => void
  refreshAuth: () => void
  refreshBalance: () => void
  refreshDepositBalance: () => void
  setIsLoggedIn: (isLoggedIn: boolean, pubKey: object | any, loginOption: string | undefined, loginProvider: string | undefined, indexWallet: number, userWallets?: UserWalletsData) => void
}



//inital values for the context object
const AuthContext = createContext<IAuthContextValue>({
  isLoggedIn: false,
  balance: "",
  depositBalance: "",
  entityInfo: { activePublicKey: "", userWalletsData: null,indexWallet:0, depositFund: {} },
  email: '',
  token: '',
  loginOption: '',
  loginProvider: '',
  hasPassword: false,
  network: null,
  selectedPaymentMethod: "",
  supportedOptions: null,
  setSupportedOptions: () => { },
  isLoading: false,
  setIsLoading: () => { },
  setBalance: () => { },
 //setUserWalletsData: (walletsData: UserWalletsData | null) => {},
  setNetwork: () => { },
  setPaymentMethod: () => { },
  setHasPassword: () => { },
  setEmail: () => { },
  setToken: () => {},
  setLoginOption: () => { },
  setLoginProvider: () => { },
  login: () => {},
  logout: () => {},
  refreshAuth: () => {},
  refreshBalance: () => { },
  refreshDepositBalance: () => { },
  setIsLoggedIn: () => { },
})

const AuthProvider = (props: any) => {
  const { isConnected, address,connector } = useAccount()
  const chainId = useChainId()
  const { disconnect } = useDisconnect()
  const clickRef = useClickRef();
  const [loggedIn, setLoggedIn] = useState(false);
  const [balance, setBalance] = useState("0");
  const [depositBalance, setDepositBalance] = useState("0");
  const [userWalletsData , setUserWalletsData] = useState<UserWalletsData>()
  const [entityInfo, setEntityInfo] = useState<IEntityInfo>({
    activePublicKey: "",
    userWalletsData: null,
    indexWallet: 0,
    depositFund:{}
  })
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [loginOption, setLoginOption] = useState('');
  const [loginProvider, setLoginProvider] = useState('');
  const [hasPassword, setHasPassword] = useState(false);
  const [network, setNetwork] = useState<any | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null);
  const [supportedOptions, setSupportedOptions] = useState<any>();
  const [isLoading ,setIsLoading] = useState(false)
  //a flag to indicate to open or close the dialog box
  /**
  * Updates the login status of the user, if they are logged in or not.
  * @param isLoggedIn - boolean value to indicate if the user is logged in or not.
  * @param pubKey - the public key of the user.
  * @param loginOption - the login option, either evm or walletconnect.
  * @param loginProvider - the login provider, either evm or walletconnect.
  */

async function updateLoginStatus(
    isLoggedIn: boolean,
    pubKey: string | any,
    loginOption: string = "non_custodial",
    loginProvider: string = "wallet_connect",
    indexWallet: number,
    userWallets?: UserWalletsData,
  ) {
    // debugger
    let fundArr:any = []
      if (loginOption === 'custodial'  && network) 
        fundArr = await network?.network.getAllDepositsBalance(email); 
      setEntityInfo({ activePublicKey: pubKey, userWalletsData: userWallets, indexWallet,depositFund:fundArr })
      console.log("EntityInfo", entityInfo)
      setLoginOption(loginOption);
      setLoginProvider(loginProvider);
      setLoggedIn(isLoggedIn)
      setUserWalletsData(userWallets)//this const update to use it in refreshEntity func
      setIsLoading(false)
  }

  // this function refreshes the entity info by setting the entity info to the current public key if logged in

  async function refreshEntityInfo() {
    if (loggedIn  && network) {
      const fundArr:any = await network?.network.getAllDepositsBalance(email);
      setEntityInfo({ activePublicKey: entityInfo.activePublicKey,indexWallet: entityInfo.indexWallet,  userWalletsData: userWalletsData , depositFund: fundArr })
    }
    else setEntityInfo({ activePublicKey: "" ,indexWallet: 0 , depositFund: {}})
  }

  //this func call with social user only to refresh user balance after claiming 
  async function refreshDepositBalance() {
    if (entityInfo.activePublicKey !== "" && loggedIn && network) {
      // debugger
      const depositBalance = await network?.network.getDeposit(email, network.tokenAddress);
      setDepositBalance(`${depositBalance}`);
    }
  }

  //This function is used to get the balance of the entity that is currently logged in. It is used to display the balance in the UI and to check if the entity has enough balance to make a transaction.

  async function refreshEntityBalance() {
    if (entityInfo.activePublicKey !== "" && loggedIn && network) {
      try {
        const balance = await network?.network.getBalance(entityInfo.activePublicKey, network);
        setBalance(balance);
      } catch (error) {
        console.error(error);
      }
    }
  }
  
  async function getAllTokens(pubKey: any, network: string) { 
    getAllBalances(pubKey, network)
  }
       

// If the user is connected to the wallet, update the login status
useEffect(() => {
    if (isConnected){
      updateLoginStatus(true, address, "non_custodial", "wallet_connect",entityInfo.indexWallet ,userWalletsData )
    }
      else{
        updateLoginStatus(false, "", "", "",entityInfo.indexWallet, undefined)
      }
}, [isConnected])


// csprClick sign in listener
useEffect(() => {
  clickRef?.on('csprclick:signed_in', async (evt) => {
    console.log(evt.account)
    updateLoginStatus(true, evt.account.public_key, "non_custodial", evt.account.provider,entityInfo.indexWallet ,userWalletsData )
    const currentNetwork = ChainsObjectsArr.find((item) => item.chain === CSPR_CLICK_PROVIDERS_NETWORKS_DIC[evt.account.provider as keyof typeof CSPR_CLICK_PROVIDERS_NETWORKS_DIC]);
    setNetwork(currentNetwork)
  });
  clickRef?.on('csprclick:switched_account', async (evt) => {
    updateLoginStatus(true, evt.account.public_key, "non_custodial", evt.account.provider,entityInfo.indexWallet ,userWalletsData )
    console.log(evt.account)
  });
  clickRef?.on('csprclick:signed_out', async (evt) => {
    updateLoginStatus(false, "", "", "",entityInfo.indexWallet, undefined)
  });
  clickRef?.on('csprclick:disconnected', async (evt) => {
    updateLoginStatus(false, "", "", "",entityInfo.indexWallet, undefined)
  });
}, [clickRef?.on]);
  const login = async () => {
    
  }

const logout = async () => {
    if (!loggedIn) {
      console.log('Already logged out !')
      return false
    }
    if (loginOption === 'custodial') {
      const auth = await getAuthConfig()
      await signOut(auth)
      setEntityInfo({ activePublicKey: "" ,indexWallet:0 , depositFund: {}})
      setLoginOption('');
      setLoginProvider('');
      setBalance('');
      setLoggedIn(false)
    } else if (CSPR_CLICK_PROVIDERS_NETWORKS_DIC.hasOwnProperty(loginProvider)){
      clickRef?.signOut()
      setEntityInfo({ activePublicKey: "" ,indexWallet:0 , depositFund: {}})
      setLoginOption('');
      setLoginProvider('');
      setLoggedIn(false)
      setBalance('');
      disconnect()
    }else {
      setEntityInfo({ activePublicKey: "",indexWallet:0, depositFund: {} })
      setLoginOption('');
      setLoginProvider('');
      setLoggedIn(false)
      setBalance('');
      disconnect()
    }
    return true
  }

  useEffect(() => {
    if(isConnected){
      const currentNetwork = ChainsObjectsArr.find((item: any) => item.networkId == chainId)
      const options = getSupportedOption(currentNetwork?.chain, "non_custodial")
      setSupportedOptions(options)
      setNetwork(currentNetwork)
    }
  }, [isConnected, chainId])

  useEffect(() => {
    if (isConnected) {
      updateLoginStatus(true, address, "non_custodial", "wallet_connect",entityInfo.indexWallet ,userWalletsData )
      refreshEntityBalance();
      getAllTokens(address, network?.chain)
    }
  }, [address])

  useEffect(() => {
    if(loggedIn && loginOption === 'custodial' && network?.chain ){
      const chainId = network?.chain
      const currentNetwork = ChainsObjectsArr.find((item: any) => item.networkId == chainId)
      const options = getSupportedOption(currentNetwork?.chain, "custodial")
      setSupportedOptions(options)
    }
  }, [network])
  
  useEffect(() => { 
    if (loggedIn)
      getAllTokens(entityInfo.activePublicKey, network?.chain as string)

  }, [entityInfo?.activePublicKey, network?.chain])
  
  useEffect(() => {
    if (loginOption === 'custodial') {
      console.log(
        '\n\nAuth details changed\n',
        {
          isLoggedIn: loggedIn,
          signType: loginOption,
          pubKey: entityInfo.activePublicKey,
          network: network?.chain,
          user: email,
          wallet: entityInfo.indexWallet,
          hasPassword: hasPassword,
        },
        '\n\n\n',
      )
      refreshEntityBalance();
      refreshDepositBalance();
    } else if(loginOption === 'non_custodial') {
      console.log(
        '\n\nAuth details changed\n',
        {
          isLoggedIn: loggedIn,
          signType: loginOption,
          pubKey: entityInfo.activePublicKey,
          network: network?.chain,
        },
        '\n\n\n',
      )
      refreshEntityBalance();
      refreshDepositBalance();
    }
  }, [loggedIn , network?.chain, entityInfo?.activePublicKey, address])

  const contextValue: IAuthContextValue = {
    isLoggedIn: loggedIn,
    balance: balance,
    depositBalance: depositBalance,
    entityInfo: entityInfo,
    email: email,
    token: token,
    loginOption: loginOption,
    loginProvider: loginProvider,
    hasPassword: hasPassword,
    network: network,
    selectedPaymentMethod: selectedPaymentMethod,
    userWalletsData: userWalletsData,
    supportedOptions: supportedOptions,
    setSupportedOptions: setSupportedOptions,
    isLoading: isLoading,
    setIsLoading: setIsLoading,
    setBalance: setBalance,
    setNetwork: setNetwork,
    setPaymentMethod: setSelectedPaymentMethod,
    setHasPassword: setHasPassword,
    setEmail: setEmail,
    setToken: setToken,
    setLoginOption: setLoginOption,
    setLoginProvider: setLoginProvider,
    login: login,
    logout: logout,
    refreshAuth: refreshEntityInfo,
    refreshBalance: refreshEntityBalance,
    refreshDepositBalance: refreshDepositBalance,
    setIsLoggedIn: updateLoginStatus,
  }

  return <AuthContext.Provider value={contextValue} {...props} />
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
