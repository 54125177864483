import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CsprClickInitOptions } from '@make-software/csprclick-core-client';
import { ClickProvider } from '@make-software/csprclick-ui';
import { CONTENT_MODE } from '@make-software/csprclick-core-types';
const clickOptions: CsprClickInitOptions = {
	appName: 'CSPR.app',
	contentMode: "iframe",
	providers: ['casper-wallet', 'ledger', 'casperdash', 'casper-signer'],
	appId: 'csprclick-template',
	//csprclickHost: 'https://casper-click-static.dev.make.services',
};
const root = ReactDOM.createRoot(document.getElementById('root')as HTMLElement);
root.render(
	<React.StrictMode>
		<ClickProvider options={clickOptions}>
			<App />
		</ClickProvider>
	</React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
