import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileUpload,
  faFolderPlus,
  faCancel,
} from '@fortawesome/free-solid-svg-icons';
import WalletTooltip from '../../../social-wallet/tooltip';
import JSZip from 'jszip';
import ButtonLoader from '../../../common/preloader/buttonLoader';
import toast from 'react-hot-toast';
import {
  validateCollectionProps,
  createCollection,
} from '../../create-collection/collection-ui-utils';
import { useAuth } from '../../../web3/context/AuthContext';
import { useNavigate } from 'react-router-dom';
function NftImgController({
  layerProps,
  setImageProps,
  newArrange,
  check,
  closeModal,
  passGeneratedNftUrl,
  getNftProps,
}) {
  const folderUploadRef = useRef(null);
  const fileUploadRef = useRef(null);
  let navigate = useNavigate();
  const [generativeImagesArr, setGenerativeImagesArr] = useState([]);
  const [userAlbumFolderPropsArr, setUserAlbumFolderPropsArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [collectionProps, setCollectionProps] = useState();
  const {
    balance,
    isLoggedIn,
    loginOption,
    hasPassword,
    network,
    email,
    entityInfo,
  } = useAuth();
  // setImageProps expose object arr contains image source and it's index in the layer
  // {[file,index,layerName]}
  // layerIndex = the layer image index
  // layerName = layerProps.name

  // generativeImagesArr = the arr that display in the image component on the right screen.
  // userAlbumFolderPropsArr = the Album that contains the images folder and layer name and layer index  that display in the middle of screen.
  // layerProps = the object that pass from the layers name controller component contains the layer name , layer indx.

  //select image to append to the mages arr
  const handleRowSelect = (rowId) => {
    setUserAlbumFolderPropsArr((prevAlbums) => {
      // Get the index of the current layer in generativeImagesArr
      const objectIndexInGenerativeImagesArr = generativeImagesArr.findIndex(
        (elem) => elem.id === layerProps.id
      );
      // Create the updated generative image object
      const updatedGenera = {
        layerName: layerProps.name,
        layerIndex: layerProps.index,
        selectedImageInAlbum:
          userAlbumFolderPropsArr[layerProps.index].folderImage[rowId],
        id: layerProps.id,
      };
      setGenerativeImagesArr((prev) => {
        const updatedArr = [...prev]; // Make a copy of the previous array
        updatedArr[objectIndexInGenerativeImagesArr] = updatedGenera; // Replace the element at objectIndexInGenerativeImagesArr
        console.log(updatedArr);
        return updatedArr; // Return the updated array
      });

      // Update the generativeImagesArr with the updated object
      const updatedGenerativeImagesArr = [...generativeImagesArr];
      updatedGenerativeImagesArr[objectIndexInGenerativeImagesArr] =
        updatedGenera;

      // Update the image props with the updated generativeImagesArr
      setImageProps(updatedGenerativeImagesArr);
      // setGenerativeImagesArr(updatedGenerativeImagesArr);
      // Update the selected image index in userAlbumFolderPropsArr
      const updatedAlbums = [...prevAlbums];
      updatedAlbums[layerProps.index] = {
        ...updatedAlbums[layerProps.index],
        id: layerProps.id,
        index: layerProps.index,
        selectedImageIndex: rowId,
      };
      return updatedAlbums;
    });
  };

  useEffect(() => {
    generativeImagesArr && setImageProps && setImageProps(generativeImagesArr);
  }, []);
  useEffect(() => {
    setCollectionProps(getNftProps);
  }, [getNftProps]);

  useEffect(() => {
    console.log(newArrange, 'rearrange layers from image controller');
    newArrange && setImageProps && setImageProps(newArrange);
    newArrange &&
      setImageProps &&
      updateUserFolderArrWithNewArrange(newArrange);
  }, [newArrange, check]);

  // this func responsible to update the current user album with the new arranged object layer
  const updateUserFolderArrWithNewArrange = (newImageArr) => {
    if (!newArrange || newArrange.length == 0) return;
    setUserAlbumFolderPropsArr((prevAlbum) => {
      const updatedAlbums = prevAlbum.map((elem) => {
        const getNewV = newImageArr.find(
          (album) => album.layerName === elem.name
        );
        if (!getNewV) {
          // drop of this item from images layerArr
          console.log(newArrange);
          return { ...elem, folderImage: [] };
        } else {
          return { ...elem, index: getNewV.layerIndex };
        }
      });
      return updatedAlbums;
    });
  };

  useEffect(() => {
    if (!layerProps || !layerProps.name) return;
    // Check if the album with the given name already exists in userAlbumFolderPropsArr
    const existingAlbumIndex = userAlbumFolderPropsArr.findIndex(
      (album) => album.name === layerProps.name
    );
    if (existingAlbumIndex !== -1) {
      // If the album already exists, update its properties
      setUserAlbumFolderPropsArr((prevAlbums) => {
        const updatedAlbums = [...prevAlbums];
        updatedAlbums[existingAlbumIndex] = {
          ...updatedAlbums[existingAlbumIndex],
          id: layerProps.id,
          index: layerProps.index,
        };
        return updatedAlbums;
      });
    } else {
      // If the album does not exist, add a new album object
      setUserAlbumFolderPropsArr((prevAlbums) => [
        ...prevAlbums,
        {
          name: layerProps.name,
          id: layerProps.id,
          index: layerProps.index,
          selectedImageIndex: 0,
          folderImage: [],
        },
      ]);
    }
  }, [layerProps]);

  // upload images function
  const handleFiles = (event) => {
    const files = event.target.files;
    const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
    const filteredFiles = Array.from(files).filter((file) => {
      // Check if the file's MIME type is allowed
      return allowedFormats.includes(file.type);
    });

    if (!filteredFiles) return;
    setUserAlbumFolderPropsArr((prevAlbum) => {
      const updatedAlbum = prevAlbum.find(
        (album) => album.name === layerProps.name
      );
      let newFiles;
      if (updatedAlbum) {
        //
        const rarityValue =
          100 / (files.length + updatedAlbum.folderImage.length);
        newFiles = Array.from(filteredFiles).map((file) => ({
          name: file.name,
          file: file,
          rarity: rarityValue,
        }));
        //update generative images arr
        const newItem = {
          layerName: layerProps.name,
          layerIndex: layerProps.index,
          selectedImageInAlbum: newFiles[0],
          id: layerProps.id,
        };
        setImageProps((prev) => {
          const updatedArr = prev.map((item) => {
            if (item.id === newItem.id) {
              return newItem; // Replace the item with the same id
            }
            return item;
          });
          const index = updatedArr.findIndex((item) => item.id === newItem.id);
          if (index === -1) {
            // If the item doesn't exist in the array, append it
            return [...prev, newItem];
          } else {
            // If the item exists, return the updated array
            return updatedArr;
          }
        });
        setGenerativeImagesArr((prev) => {
          // Check if newItem's id already exists in prev
          const index = prev.findIndex((item) => item.id === newItem.id);

          if (index !== -1) {
            // If newItem's id exists, replace the values at that index
            const updatedArr = [...prev];
            updatedArr[index] = newItem;
            return updatedArr;
          } else {
            // If newItem's id doesn't exist, append newItem to prev
            return [...prev, newItem];
          }
        });
        //update user album props selected image
        const updatedAlbums = [...prevAlbum];
        updatedAlbums[layerProps.index] = {
          ...updatedAlbums[layerProps.index],
          id: layerProps.id,
          index: layerProps.index,
          selectedImageIndex: 0,
        };
        updatedAlbum.folderImage.map((elem) => (elem.rarity = rarityValue));
        updatedAlbum.folderImage = [...updatedAlbum.folderImage, ...newFiles];
        return [...prevAlbum];
      }

      return [...prevAlbum];
    });
    event.target.value = '';
  };

  const handleRatingChange = (event, index) => {
    const newRating = parseInt(event.target.value, 10);
    const approvedRarities =
      userAlbumFolderPropsArr
        .find((elem) => elem.name === layerProps.name)
        ?.folderImage?.slice(0, index)
        .reduce((total, img) => total + img.rarity, 0) || 0;
    const totalRarity = newRating + approvedRarities;

    if (totalRarity >= 100) {
      console.log('user exceeded the 100 value');
      return;
    } else {
      let remainingRarity = 100 - totalRarity;
      const remainingImagesCount =
        (userAlbumFolderPropsArr.find((elem) => elem.name === layerProps.name)
          ?.folderImage.length || 0) -
        index -
        1;
      let newRarityValue =
        remainingImagesCount > 0 ? remainingRarity / remainingImagesCount : 0;

      if (
        index ===
          (userAlbumFolderPropsArr.find((elem) => elem.name === layerProps.name)
            ?.folderImage?.length || 0) -
            1 &&
        totalRarity > 100
      ) {
        const excess = totalRarity - 100;
        remainingRarity -= excess;
        newRarityValue = remainingRarity / remainingImagesCount;
      }

      setUserAlbumFolderPropsArr((prevAlbum) => {
        const updatedAlbum = [...prevAlbum];
        const currentAlbum = updatedAlbum.find(
          (elem) => elem.name === layerProps.name
        );
        if (currentAlbum) {
          const updatedFolderImage = [...currentAlbum.folderImage];
          updatedFolderImage[index] = {
            ...updatedFolderImage[index],
            rarity: newRating,
          };
          for (let i = index + 1; i < updatedFolderImage.length; i++) {
            updatedFolderImage[i].rarity = newRarityValue;
          }
          currentAlbum.folderImage = updatedFolderImage;
        }
        return updatedAlbum;
      });
    }
  };

  const handleFileNameChange = (event, index) => {
    const newName = event.target.value;
    setUserAlbumFolderPropsArr((prevAlbum) => {
      const updatedAlbum = [...prevAlbum];
      const currentAlbum = updatedAlbum.find(
        (elem) => elem.name === layerProps.name
      );
      if (currentAlbum) {
        const updatedFolderImage = [...currentAlbum.folderImage];
        updatedFolderImage[index] = {
          ...updatedFolderImage[index],
          name: newName,
        };
        currentAlbum.folderImage = updatedFolderImage;
      }
      return updatedAlbum;
    });
  };

  const removeImage = (index) => {
    // 1- check if this image is selected to display in the images layers arr
    if (
      index === userAlbumFolderPropsArr[layerProps.index].selectedImageIndex
    ) {
      //check if this element is the last item in arr
      if (
        index ===
        userAlbumFolderPropsArr[layerProps.index].folderImage.length - 1
      ) {
        if (index === 0) {
          // this element is the last image in layer
          // remove this layer from image layers before delete
          const updatedGenerativeImagesArr = [...generativeImagesArr];
          const newArr = updatedGenerativeImagesArr.filter(
            (_, i) => i !== layerProps.index
          );
          setGenerativeImagesArr(newArr);
          setImageProps(newArr);
        } else {
          // set the last element in the arr the selected image in the images layers arr instead of the removed one
          handleRowSelect(index - 1);
        }
      }
    }
    console.log(userAlbumFolderPropsArr, 'userAlbumFolderPropsArr');
    setUserAlbumFolderPropsArr((prevUserAlbum) =>
      prevUserAlbum.map((elem) => {
        if (elem.name === layerProps.name) {
          const updatedFolderImage = elem.folderImage.filter(
            (_, i) => i !== index
          );
          const remainingRarity = 100 / updatedFolderImage.length;
          const updatedImages = updatedFolderImage.map((img) => ({
            ...img,
            rarity: remainingRarity,
          }));
          return {
            ...elem,
            folderImage: updatedImages,
          };
        }
        return elem;
      })
    );
  };
  const createZipFolder = () => {
    return new Promise((resolve, reject) => {
      if (loading) {
        reject(new Error('Loading is true'));
        return;
      }

      const zip = new JSZip();
      let layersInfo = [];
      let imageDimensions;
      const firstImage = userAlbumFolderPropsArr[0].folderImage[0].file;
      const reader = new FileReader();

      reader.readAsDataURL(firstImage);

      const promise = new Promise((resolve) => {
        reader.onload = function (event) {
          const img = new Image();
          img.src = event.target.result;
          img.onload = function () {
            imageDimensions = {
              width: img.width,
              height: img.height,
            };
            resolve(imageDimensions);
          };
        };
      });

      promise
        .then((resolvedImage) => {
          userAlbumFolderPropsArr.forEach(({ name, folderImage, index }) => {
            const subFolder = zip.folder(name);
            layersInfo.push({
              name,
              number: index.toString(),
              probs: folderImage.map(({ rarity }) => Number(rarity) / 100),
            });

            folderImage.forEach((image, index) => {
              subFolder.file(image.name + index, image.file);
            });
          });

          zip.generateAsync({ type: 'blob' }).then(function (content) {
            resolve({ content, layersInfo, imageDimensions });
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const mintCollection = async () => {
    try {
      const uploadedImages = userAlbumFolderPropsArr.find(
        (elem) => elem.folderImage.length > 0
      );
      let collectionData = {
        ...collectionProps,
        image: uploadedImages,
        name: collectionProps.collectionName,
        symbol: collectionProps.collectionSymbol,
      };

      if (!validateCollectionProps(collectionData, [0])) return;
      const { content, layersInfo, imageDimensions } = await createZipFolder();
      collectionData = {
        ...collectionData,
        content: content,
        layersInfo: layersInfo,
        imageDimensions: imageDimensions,
      };
      await createCollection({
        balance,
        isLoggedIn,
        loginOption,
        hasPassword,
        network,
        collectionProps: collectionData,
        collectionTraits: [],
        isGeneratedImageLayers: true,
        isGeneratedImageAILayersHasURI: false,
        isGeneratedImageAILayers: false,
        confirmedURI: '',
        email,
        entityInfo,
        setLoading,
      });
      navigate('/transferDashboard?selectedTab=Collections');
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-2 pt-5">
        <div
          className="mb-5 mouse-cursor"
          onClick={() => folderUploadRef.current.click()}
        >
          <WalletTooltip title={'Upload Folder'}>
            <FontAwesomeIcon icon={faFolderPlus} size="3x" color="#ccc" />
          </WalletTooltip>
          <input
            type="file"
            id="file-input"
            ref={folderUploadRef}
            onChange={(event) => handleFiles(event)}
            webkitdirectory="true"
            style={{ display: 'none' }}
          />
        </div>

        <div
          className="mb-5 mouse-cursor"
          onClick={() => fileUploadRef.current.click()}
        >
          <WalletTooltip title={'Upload File'}>
            <FontAwesomeIcon icon={faFileUpload} size="3x" color="#ccc" />
          </WalletTooltip>
          <input
            type="file"
            id="single-file-input"
            ref={fileUploadRef}
            onChange={(event) => handleFiles(event)}
            style={{ display: 'none' }}
          />
        </div>
      </div>

      <div className="col-10">
        <div className="col-10 d-flex justify-content-center align-items-center">
          <div>
            <h4 className="text-white">Options</h4>
            <p className="text-center">{layerProps?.name}</p>
          </div>
        </div>
        <div className="generative-images-container">
          {userAlbumFolderPropsArr.length > 0 &&
            userAlbumFolderPropsArr.find(
              (elem) => elem.name == layerProps.name
            ) &&
            userAlbumFolderPropsArr
              .find((elem) => elem.name == layerProps.name)
              .folderImage?.map((img, index) => (
                <div className="row " key={index}>
                  <div className="col-10">
                    <div
                      className={`fugu-img-container mouse-cursor ${
                        userAlbumFolderPropsArr.find(
                          (elem) => elem.name == layerProps.name
                        ).selectedImageIndex == index
                          ? 'fugu-selected-image-card'
                          : ''
                      }`}
                      onClick={() => handleRowSelect(index)}
                    >
                      <div>
                        <input
                          value={img.name}
                          className="fugu-generative-img-input"
                          onChange={(e) => handleFileNameChange(e, index)}
                        />
                      </div>
                      <div className="container mt-1">
                        <div className="row justify-content-center">
                          <div className="col">
                            <div className="form-group">
                              <input
                                type="range"
                                className="form-range fugu-slider"
                                id={`ratingSlider-${index}`}
                                min="0"
                                max="100"
                                value={img.rarity || 0}
                                onChange={(event) =>
                                  handleRatingChange(event, index)
                                }
                              />
                            </div>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${img.rarity || 0}%`,
                                  backgroundColor: '#6f42c1',
                                }}
                                aria-valuenow={img.rarity || 0}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {img.rarity || 0}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-center align-items-center mouse-cursor">
                    <WalletTooltip title={'Remove'}>
                      <FontAwesomeIcon
                        icon={faCancel}
                        size="lg"
                        color="red"
                        onClick={() => removeImage(index)}
                      />
                    </WalletTooltip>
                  </div>
                </div>
              ))}
        </div>
      </div>

      {userAlbumFolderPropsArr.length > 0 &&
        userAlbumFolderPropsArr[0].folderImage.length > 0 && (
          <div className="d-flex justify-content-center align-items-end mb-1">
            <div className="fugu--portfolio-btn col-auto me-2">
              <button
                className={`fugu--outline-btn`}
                onClick={() => mintCollection()}
                disabled={loading}
              >
                <span>Mint </span>
                {loading && <ButtonLoader />}
              </button>
            </div>
            <div className="fugu--portfolio-btn col-auto ms-2">
              <button
                className="fugu--outline-btn"
                onClick={() => setLoading(false)}
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        )}
    </div>
  );
}

export default NftImgController;
