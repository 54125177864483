import { useEffect, useState } from 'react'
import WalletTooltip from '../../components/social-wallet/tooltip';
import { toast } from "react-hot-toast";
import { useAuth } from '../../components/web3/context/AuthContext';
import {
    getUserTransactions,
    cancelTransactionCustodial,
    cancelTransactionNonCustodial,
    getUserBalance,
    withdrawRefundNonCustodial,
    withdrawRefundCustodial,
    updateScheduledNonCustodial,
    updateScheduledCustodial
} from '../../components/web3/scheduler-utils/scheduler-utils';
import NormaAlert from '../../components/custom-modal/normal-alert';
import Preloader from '../../components/common/preloader/preloader';
import { openPasswordModel } from '../../components/social-wallet/PasswordModal';
import EditScheduleTransactionModal from './editTransactionModal';
import { convertDateToUnix } from '../../helper/helperFunc';
import { ethers } from 'ethers';
import { convertSecondsToDaysAndMinutes } from '../../helper/helperFunc';
import ReactGA from 'react-ga4';
function MySchedules() {
    const { entityInfo, network, isLoggedIn, loginOption, hasPassword, email } = useAuth()
    const [showAlert, setShowAlert] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userBalance, setUserBalance] = useState()
    const [claimLoading, setClaimLoading] = useState(false)
    const [showEditScheduleModal , setShowEditScheduleModal] = useState(false)
    const [selectedEditSchedule, setSelectedEditSchedule]  = useState()
    useEffect(()=>{
        ReactGA.send({ hitType: 'pageview', page: '/MySchedules', title: 'My Schedules Page' });
    },[])
    useEffect(() => {
        fetchUserSchedulesTransactions()
        fetchUserBalance()
    }, [entityInfo, network?.chain])
    const transactionStatusDic = {
        0: "Scheduled",
        1: "Executed",
        2: "Failed",
        3: "Canceled"
    }
    const fetchUserBalance = async () => {
        const refundBalance = await getUserBalance(network, entityInfo.activePublicKey)
        const balance =  ethers.utils.formatEther(refundBalance)
        setUserBalance(balance.toString())
        console.log(refundBalance.toString())
    }
    const [transactionsData, setTransactionsData] = useState();
    const tableKeys = [
       "TO" , "Value", "Number Of Execution", "Current Number Of Execution", "Time Gap between Transactions", "Date Of Execution", "Status", "Action"
    ]
    const fetchUserSchedulesTransactions = async () => {
        setLoading(true)
        try {
            const transactions = await getUserTransactions(entityInfo.activePublicKey, network);
            // map in transactions arr , it's returns arr of arrays , index zero it  reflected 
            // the transactions ids, the index 1 it's contains transactions data 
            // transaction status values 
            // scheduled 0 Executed 1 Failed 2 Cancel 3
            // mapping to set the transaction id inside the transaction object    
            if (transactions) {
                const processedTransactions = transactions[1].map((transactionData, index) => {
                    const transactionId = transactions[0][index];
                    const desc = JSON.parse(transactionData.description)
                    return {
                        ...transactionData,
                        transactionId: transactionId.toString(),
                        transactionDesc: desc
                    };
                });
                console.log(processedTransactions);
                
                setTransactionsData(processedTransactions);
            }
            setLoading(false)
        } catch (error) {
            console.error(error);
            setLoading(false)
        }
    };
    const convertBigNumberToDate = (executionDate)=>{
        const unixTimestampInSeconds = executionDate.toNumber();
        const unixTimestampInMilliseconds = unixTimestampInSeconds * 1000;
        const date = new Date(unixTimestampInMilliseconds);
        return date.toLocaleString("en-US")
    }

    const CancelScheduleTransaction = (elem) => {
        setShowAlert(false)
        setLoading(true)
        if (loginOption == "custodial") {
            if (hasPassword) {
                setLoading(false)
                openPasswordModel(cancelSecuredCustodialTransaction, { elem: elem })
            } else {
                cancelCustodialTransaction(elem)
            }
        } else if (loginOption == "non_custodial") {
            cancelNonCustodialTransaction(elem)
        }
    }

    const cancelSecuredCustodialTransaction = async (password, input) => {
        setLoading(true)
        try {
            const tx = {
                walletId: email,
                walletIndex: entityInfo.indexWallet,
                password: password,
                txIndex: input.elem.transactionId
            }

            await cancelTransactionCustodial(tx, network)
            setTransactionsData([])
            fetchUserSchedulesTransactions()

        } catch (e) {
            setLoading(false)
            toast.error("Cancel schedule transaction is failed ..")
        }
    }
    const cancelCustodialTransaction = async (elem) => {
        try {
            const tx = {
                walletId: email,
                walletIndex: entityInfo.indexWallet,
                txIndex: elem.transactionId
            }

            await cancelTransactionCustodial(tx, network)
            setTransactionsData([])
            fetchUserSchedulesTransactions()
        } catch (e) {
            setLoading(false)
            toast.error("Cancel schedule transaction is failed ..")
        }
    }
    const cancelNonCustodialTransaction = async (item) => {
        try {
            const tx = {
                txIndex: item.transactionId,
                network: network,
            }
            await cancelTransactionNonCustodial(tx , network)
            setTransactionsData([])
            fetchUserSchedulesTransactions()
        } catch (e) {
            console.log(e)
            setLoading(false)
            toast.error("Cancel schedule transaction is failed ..")
        }
    }
    const ClaimRefundBalance = () => {
        if (userBalance == 0) return
        setClaimLoading(true)
        if (loginOption == "custodial") {
            if (hasPassword) {
                openPasswordModel(withdrawCustodialRefund)
            } else {
                withdrawCustodialRefund()
            }
        } else if (loginOption == "non_custodial") {
            withdrawNonCustodialRefund()
        }
    }
    const withdrawNonCustodialRefund = async (password) => {
        try {
            await withdrawRefundNonCustodial(network)
            resetUserRefundBalanceState()
        } catch (e) {
            setClaimLoading(false)
            console.log(e);
        }
    }
    const withdrawCustodialRefund = async (password) => {
        const tx = {
            walletId: loginOption == "custodial"? email : entityInfo.activePublicKey,
            walletIndex: entityInfo.indexWallet,
            password: password,
        }
        try {
            await withdrawRefundCustodial(tx, network)
            resetUserRefundBalanceState()
        } catch (e) {
            setClaimLoading(false)
            console.log(e);
        }
    }

    const resetUserRefundBalanceState = () => {
        setUserBalance(0)
        fetchUserBalance()
        setClaimLoading(false)
    }

    const openEditScheduleModal = (item)=> {
        console.log(item)
        setSelectedEditSchedule(item)
        setShowEditScheduleModal(true)
    }

    const editSchedule = (newDate, timeBetween) => {
        setShowEditScheduleModal(false)
        setLoading(true)
        const date = convertDateToUnix(newDate)
        const tx = {
            walletId: loginOption == "custodial"? email : entityInfo.activePublicKey,
            walletIndex: parseInt(entityInfo.indexWallet),
            txIndex: parseInt(selectedEditSchedule.transactionId),
            executionTime: date,
            repeatingTime: parseInt(timeBetween)
        }
        if(loginOption == "custodial"){
            updateScheduleCust(tx)
            
        }else{
            updateScheduleNoneCust(tx)
        }
      }

    const updateScheduleCust = async(tx )=>{        
        try{
            if(!hasPassword){
                await updateScheduledCustodial(tx, network)
                fetchUserSchedulesTransactions()
            }else{
                await openPasswordModel(editSecureScheduleTransaction , {tx:tx})
                fetchUserSchedulesTransactions()
            }
        }catch(e){
            console.log(e)
            setLoading(false)
        }
    }

    const editSecureScheduleTransaction = async (password, input)=>{
        const tx = input.tx 
        tx.password  = password
        try{
            
            await updateScheduledCustodial(tx , network)
            fetchUserSchedulesTransactions()
        }catch(e){
            console.log(e)
            setLoading(false)
        }        
    }
    const updateScheduleNoneCust = async(tx)=>{
        try{
            await updateScheduledNonCustodial(tx, network)
            fetchUserSchedulesTransactions()
        }catch(e){
            setLoading(false)
        }
    }
    return (
        <div className="">
            <div className='container-fluid'>
                {isLoggedIn && (
                    loading ? <Preloader show={loading} /> :
                        transactionsData?.length > 0 ? (
                            <>
                                <div className='d-flex flex-row-reverse m-5 '>
                                    <WalletTooltip title={"Refunded balance from the previous transactions"}>
                                        <h2 className='text-warning-500 '>
                                           {network.nativeToken}:&nbsp; {userBalance}
                                        </h2>
                                    </WalletTooltip>
                                    <div className=" mx-4 ">
                                        <button
                                            className="fugu--outline-btn"
                                            onClick={ClaimRefundBalance}
                                            disabled={claimLoading}
                                        >
                                            {claimLoading && (
                                                <div className="fugu-button-spinner">
                                                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="50" cy="50" r="46" />
                                                    </svg>
                                                </div>
                                            )}
                                            <span>WITHDRAW</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='fugu-schedule-table-wrapper'>
                                    <table className='fugu-schedule-Table'>
                                        <thead>
                                            <tr className=''>
                                                {tableKeys?.map((key) => (
                                                    <th key={key} className='text-uppercase fugu-table-border  p-3'>
                                                        {key}
                                                    </th>
                                                ))}
                                                <th className='fugu-table-border'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactionsData?.map((item, index) => (
                                                <tr key={index}>

                                                    <td key={1} className="p-2 fugu-table-border">
                                                        <ul>
                                                            {item.transactionDesc.to.map((element, idx) => (
                                                                <li key={idx}>{element}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                    <td key={2} className="p-2 fugu-table-border">{item.transactionDesc.symbol}:&nbsp; {ethers.utils.formatEther(item.value)*item.numberOfExecutions}</td>
                                                    <td key={3} className="p-2 fugu-table-border">{item.numberOfExecutions.toString()}</td>
                                                    <td key={4} className="p-2 fugu-table-border">{item.currNumberOfExecutions.toString()}</td>
                                                    <td key={5} className="p-2 fugu-table-border">
                                                        {item.numberOfExecutions == 1
                                                            ? "One Transaction"
                                                            : `Days: ${convertSecondsToDaysAndMinutes(item.repeatingTime.toString()).days} Minutes: ${convertSecondsToDaysAndMinutes(item.repeatingTime.toString()).minutes}`
                                                        }
                                                    </td>
                                                    <td key={6} className="p-2 fugu-table-border">{convertBigNumberToDate(item.executionTime)}</td>
                                                    <td key={7} className="p-2 fugu-table-border">{transactionStatusDic[item.status]}</td>
                                                    <td className='p-2 fugu-table-border'>
                                                        <>
                                                            {item.status == 0 ? (
                                                                <div className='d-flex justify-content-around pt-2'>
                                                                    <button className='text-danger-500 mx-3'
                                                                        onClick={() => CancelScheduleTransaction(item)}>
                                                                        CANCEL
                                                                    </button>
                                                                    <button className='text-success-500 mx-3' onClick={()=>openEditScheduleModal(item)} >
                                                                        EDIT
                                                                    </button>
                                                                </div>
                                                            ) : item.status == 1 ?
                                                                (<>NO Action</>)
                                                                : item.status == 2 ?
                                                                    (<div className='text-danger-500'>
                                                                        FAILED
                                                                    </div>)
                                                                    : item.status == 3 ?
                                                                        (<div className='text-danger-500'>
                                                                            CANCELED
                                                                        </div>) :
                                                                        (<></>)
                                                            }
                                                        </>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        ):(
                            <h1 className='text-warning-500 text-center mt-5 pt-5'>NO SCHEDULED TRANSACTIONS </h1>
                        )
                )}
            </div>
            <NormaAlert
                show={showAlert}
                message={"Are you sure you need to cancel this schedule transaction ?"}
                handleCloseParent={() => setShowAlert(false)}
                agreeAction={CancelScheduleTransaction}
            />
            <EditScheduleTransactionModal 
                show={showEditScheduleModal}
                scheduleData={selectedEditSchedule}
                handleCloseParent={()=>setShowEditScheduleModal(!showEditScheduleModal)}
                editSchedule={editSchedule}           
            />
        </div>
    )
}

export default MySchedules