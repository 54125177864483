import binanceIcon from "../assets/images/networks-icons/binance.png";
import casperIcon from "../assets/images/networks-icons/casper.png";
import etherIcon from "../assets/images/networks-icons/eth.png";
import polygonIcon from "../assets/images/networks-icons/polygon.png";
import telosIcon from "../assets/images/networks-icons/telos.png";
import flareIcon from "../assets/images/networks-icons/flare.png";
import baseIcon from "../assets/images/networks-icons/base.png";

import { SUPPORTED_OPTIONS } from "../components/web3/utils/Providers";
import { Network } from "casper-storage";
import { EvmNetwork } from "../components/web3/utils/EvmNetwork";
import { CasperNetwork } from "../components/web3/utils/CasperNetwork";
const chainType = process.env.REACT_APP_NETWORK_TYPE;

const MAINNET_SUPPORTED_CHAINS = ["BASE", "POLYGON"];
const TESTNET_SUPPORTED_CHAINS = [
  "BASE",
  "POLYGON",
  "BSC",
  "ETHEREUM",
  "TELOS",
  "FLARE",
];

const ChainsObjects = [
  {
    title: "BASE",
    chain: "BASE",
    symbol: "BASE",
    nativeToken: "BASE",
    decimals: 18,
    network: new EvmNetwork("BASE"),
    networkId: chainType == "TESTNET" ? "84532" : "8453",
    chainName: chainType == "TESTNET" ? "base-sepolia" : "base",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.BASE,
    logo: baseIcon,
    image: baseIcon,
    baseUrl: "https://rpc.notadegen.com/base/sepolia",
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
  },
  {
    title: "Polygon",
    chain: "POLYGON",
    symbol: "MATIC",
    nativeToken: "MATIC",
    decimals: 18,
    network: new EvmNetwork("POLYGON"),
    networkId: chainType == "TESTNET" ? "80002" : "137",
    chainName: chainType == "TESTNET" ? "amoy" : "polygon",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.POLYGON,
    logo: polygonIcon,
    image: polygonIcon, // set this var to use it in header networks original images
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
  },
  {
    title: "Binance",
    chain: "BSC",
    symbol: "BNB",
    nativeToken: "BNB",
    decimals: 18,
    network: new EvmNetwork("BSC"),
    networkId: chainType == "TESTNET" ? "97" : "56",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.BSC,
    logo: binanceIcon,
    image: binanceIcon, // set this var to use it in header networks original images
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
  },
  {
    title: "Ethereum",
    chain: "ETHEREUM",
    symbol: "ETH",
    nativeToken: "ETH",
    decimals: 18,
    network: new EvmNetwork("ETHEREUM"),
    networkId: chainType == "TESTNET" ? "11155111" : "1",
    chainName: chainType == "TESTNET" ? "sepolia" : "ethereum",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.ETHEREUM,
    logo: etherIcon,
    image: etherIcon, // set this var to use it in header networks original images
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
  },
  {
    title: "CASPER",
    chain: "CASPER",
    symbol: "CSPR",
    nativeToken: "CSPR",
    network: new CasperNetwork("CASPER"),
    networkId: "0",
    networkType: "CASPER",
    supportedOptions: SUPPORTED_OPTIONS.CASPER,
    logo: casperIcon,
    image: casperIcon, // set this var to use it in header networks original images
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
  },
  {
    title: "Telos",
    chain: "TELOS",
    symbol: "TLOS",
    nativeToken: "TLOS",
    decimals: 18,
    network: new EvmNetwork("TELOS"),
    networkId: chainType == "TESTNET" ? "41" : "40",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.TELOS,
    logo: telosIcon,
    image: telosIcon,
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
  },
  {
    title: "Flare",
    chain: "FLARE",
    symbol: "FLR",
    nativeToken: "FLR",
    decimals: 18,
    network: new EvmNetwork("FLARE"),
    networkId: chainType == "TESTNET" ? "16" : "14",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.FLARE,
    logo: flareIcon,
    image: flareIcon,
    baseUrl: "https://testnet.flare.network",
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
  },
];

export const ChainsObjectsArr =
  chainType == "TESTNET"
    ? ChainsObjects.filter((chain) =>
        TESTNET_SUPPORTED_CHAINS.includes(chain.chain)
      )
    : ChainsObjects.filter((chain) =>
        MAINNET_SUPPORTED_CHAINS.includes(chain.chain)
      );
