import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Link } from "react-router-dom";
import { getCollections } from "../web3/nfts-utils/nfts-factory/HorusFactory_Utils";
import { useAuth } from "../web3/context/AuthContext";
import Preloader from "../common/preloader/preloader";

const MyCollectionsLibrary = forwardRef(({ onCollectionSelect }, ref) => {
  const { loginOption, network, entityInfo, isLoggedIn } = useAuth();
  const [collections, setCollections] = useState();
  const [loading, setLoading] = useState(false);

  const reloadCollections = () => {
    setCollections([])
    fetchCurrentCollections()
  };

  useImperativeHandle(ref, () => ({
    reloadCollections: reloadCollections,
  }))

  const fetchCurrentCollections = async () => {
    setLoading(true);
    try {
      //  console.log(network, entityInfo.activePublicKey);

      const fetchedCollections = await getCollections(
        network,
        entityInfo.activePublicKey
      );
      setCollections(fetchedCollections);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCurrentCollections();
  }, [isLoggedIn, entityInfo]);
  const mint = () => { };
  return (
    <>
      <div className={` `}>
        <Preloader show={loading} />
        {collections && collections.length > 0 ? (
        <div className="fugu--breadcrumbs-section d-flex justify-content-center text-center opacity-bg ">
          <div className="fugu--breadcrumbs-data">
            <div className="col-lg-12">
             
                <div className="mb-5">
                  {/* <h1>Select Collection</h1> */}
                  <h3 className="mb-3">
                    You can choose your Collection from your Amazing and Unique
                    library
                  </h3>
                </div>
              
              <div className="row">
                {collections &&
                  collections.length > 0 &&
                  collections.map((elem, index) => (
                    <div className="col-md-4" key={index}>
                      <div
                        className="fugu--blog-wrap fugu--blog-wrap2 wow fadeInUpX"
                        data-wow-delay="0s"
                      >
                        <div className="fugu--blog-thumb">
                          <img src={elem.image} alt="" />
                        </div>
                        <div className="fugu--blog-content">
                          <div className="fugu--blog-date">
                            <ul>
                              <li>
                                <Link href={"#"}>{`Max-NFTs ${elem.maxNfts}`}</Link>
                              </li>
                              <li>
                                <Link
                                  href={"#"}
                                >{`Minted-NFTs ${elem.currNFts}`}</Link>
                              </li>
                            </ul>
                          </div>
                          <div className="fugu--blog-title text-uppercase">
                            <div className="">
                              <h3>
                                {elem.name} {"_"} {elem.symbol}
                              </h3>
                              <div className="d-flex justify-content-center mb-4">
                                <Link to="/mintNfts" state={elem}>
                                  <button
                                    className="fugu--outline-btn"
                                    onClick={() => mint()}
                                  >
                                    <span>Manage Collection</span>
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        ):(
          <h1 className='text-warning-500 text-center mt-5 pt-5 text-uppercase'>there is no collections in your library. </h1>
      )}
      </div>
    </>
  );
})

export default MyCollectionsLibrary;
