import React, { useEffect, useState } from 'react';
import { useAuth } from '../web3/context/AuthContext';
import { fetchTokensBalanceWithDeferentChains } from '../../api/fetchTokensBalance';
import { fetchTokensMetadata } from '../../api/fetchTokensMetaData';
import defaultTokenLogo from '../../assets/images/logo/Coin.svg';
import { getAllBalances } from '../web3/utils/utils';
import WalletTooltip from '../social-wallet/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { ChainsObjectsArr } from '../../chainsStaticObject/chainsStaticData';
export default function TokensTable() {
  const { network, setNetwork, entityInfo, balance, setBalance } = useAuth();
  const [loading, setLoading] = useState(false);
  const [assetsArr, setAssetsArr] = useState();
  const selectToken = (item) => {
    let selectedNetwork = network;
    selectedNetwork = {
      ...selectedNetwork,
      symbol: item.symbol,
      tokenAddress: item.contractAddress,
      logo: item.logo ? item.logo : defaultTokenLogo,
      balance: item.balance,
      decimals: item.decimals,
    };
    setBalance(item.balance);
    setNetwork(selectedNetwork);
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  async function fetchTokenBalancesSequentially(
    network,
    entityInfo,
    index = 0,
    balances = []
  ) {
    if (index >= network?.submenu.length) {
      return balances;
    }

    let networkBaseUrl = network?.baseUrl;
    let url = `${networkBaseUrl}${network?.submenu[index].contractAddress}&address=${entityInfo.activePublicKey}&tag=latest&apikey=${network.apiKey}`;
    //console.log(url);

    let b = await fetchTokensBalanceWithDeferentChains(url);
    balances.push(b);

    await delay(500);
    return fetchTokenBalancesSequentially(
      network,
      entityInfo,
      index + 1,
      balances
    );
  }
  useEffect(() => {
    fetchUserAssets();
  }, [network?.chain, balance]);

  const fetchUserAssets = async () => {
    setLoading(true);
    let assets = localStorage.getItem(
      `walletAssets-${network?.chain}-address-${entityInfo.activePublicKey}`
    );
    if (!assets) {
      const balancesArr = await getAllBalances(
        entityInfo.activePublicKey,
        network?.chain
      );
      setAssetsArr(balancesArr);
      localStorage.setItem(`walletAssets-${network?.chain}`, balancesArr);
    } else {
      setAssetsArr(assets);
    }
    setLoading(false);
  };

  const manualRefreshAssets = async () => {
    localStorage.removeItem(
      `walletAssets-${network?.chain}-address-${entityInfo.activePublicKey}`
    );
    setAssetsArr([]);
    fetchUserAssets();
  };

  return (
    <div className="container">
      <h4 className="pt-3 text-white">Select Token</h4>
      <div className="col d-flex justify-content-end">
        <WalletTooltip title={'Refresh your assets list'}>
          <div
            className={`mr-3 text-warning-500 pt-2`}
            onClick={() => manualRefreshAssets()}
          >
            <FontAwesomeIcon icon={loading ? faSpinner : faRefresh} />
          </div>
        </WalletTooltip>
      </div>
      <div className="row mt-3 fugu-tokens-table-holder">
        {assetsArr?.length &&
          assetsArr.map((item, index) => (
            <div
              key={index}
              className={`fugu-tokens-table col-lg-4 col-sm-12 col-md-4 d-flex`}
              onClick={() => selectToken(item)}
            >
              <img
                src={
                  item.logo
                    ? ChainsObjectsArr.find(
                        (chain) => chain.symbol === item.symbol
                      )?.image
                    : defaultTokenLogo
                }
                className="fugu-network-icons mx-2"
              />
              <h4
                className={`${
                  item?.symbol == network?.symbol ? 'active' : ''
                } mt-2`}
              >
                {item?.symbol}:
              </h4>
              <p>{assetsArr[index].balance}</p>
            </div>
          ))}
      </div>
    </div>
  );
}
