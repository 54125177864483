import axiosToken from "../components/social-wallet/AxiosToken";
export async function switchDefaultNetworkAddress(
  walletId,
  walletAddress,
  isDefault,
  networkName
) {
  const apiName = "/alterReceivingAddress";
  try {
    const response = await axiosToken.post(apiName, {
      walletId: walletId,
      network: networkName,
      receivingAddress: walletAddress,
      isDefault: isDefault,
    });
    return response;
  } catch (err) {
    return err;
  }
}
