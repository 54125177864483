import React from "react";
import { Outlet } from "react-router-dom";
import FooterHomeThree from "./footer/footer-home-three";
import HeaderHomeThree from "./header/header-home-three";
import { joyRideStepsContent } from "../../joySteps/joyRideStaticSteps";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { useAuth } from "../web3/context/AuthContext";

export default function Layout({ children }) {
	const { isLoggedIn } = useAuth();
	const [{ run, steps }, setState] = React.useState({
		run: false,
		steps: joyRideStepsContent,
	});
	const handleClickStart = (event) => {
		event.preventDefault();
		if (isLoggedIn) {
			const startStepIndex = 2;
			setState({
				run: true,
				steps: joyRideStepsContent.slice(startStepIndex),
			});
		} else {
			setState({
				run: true,
				steps: joyRideStepsContent,
			});
		}
	};
	const handleJoyrideCallback = (data) => {
		const { status, type } = data;
		const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
		if (finishedStatuses.includes(status)) {
			setState({ run: false });
		}
		logGroup(type, data);
	};
	function logGroup(type, data) {
		// Make whatever you need while the user is browsing the tour guide
	}
	return (
		<>
			<HeaderHomeThree handleClickStart={handleClickStart} />
			<Joyride
				callback={handleJoyrideCallback}
				continuous
				hideCloseButton
				run={run}
				scrollToFirstStep
				showProgress
				showSkipButton
				steps={steps}
				styles={{
					options: {
						zIndex: 10000,
						backgroundColor: 'rgba(255, 255, 255, 0.8)',
					},
				}}
			/>
			<main>{children}</main>
			<div>
				{/* outlet is using to implement multiple routes layouts */}
				<Outlet />
			</div>
			<FooterHomeThree />
		</>
	);
}
