import React from 'react';

const Terms = () => {
  return (
    <div>
      <div className="section mob-section privacy-container">
        <div className="_w-container diff-container w-container">
          <div
            href="https://cspr.live/#interest"
            className="rich-text-block-2 policy-text w-richtext"
          >
            <h1>
              <strong>Horus Wallet User Agreement</strong>
            </h1>
            <h4>
              <strong>Last Updated: July 13, 2022</strong>
            </h4>
            <p>
              This User Agreement (“Agreement”) is between you (also referred to
              herein as “user”, “you” and “your”) and Horus Wallet Inc. (“Horus Wallet”,
              “we”, “us” and “our”). This Agreement governs your use of the
              services provided by Horus Wallet described below, including the Horus Wallet
              Interface, Horus Wallet  Services, and Horus Wallet API (collectively,
              the “Services”). By connecting your Web3 wallet through the web
              application, available at{' '}
              <a href="https://horuswallet.com/" target="_blank">
              https://horuswallet.com/{' '}
              </a>
              , or using the Horus Wallet or API, you agree that you have read,
              understand, and accept all of the terms and conditions contained
              in this Agreement.
            </p>
            <p>
              To be eligible to use the Services you must be a resident of the
              one of the countries in which the Services are supported and be
              the age of majority in your country of residence. We may make
              changes to the Agreement from time to time. If we do this, we will
              post the changed Agreement on the Horus Wallet Interface and will
              indicate at the top of this page the date the Agreement was last
              revised. You understand and agree that your continued use of the
              Services after we have made any such changes constitutes your
              acceptance of the new Agreement.
            </p>
            <p>
              IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION
              AND A WAIVER OF CLASS ACTION RIGHTS AS DETAILED IN SECTION 9.
              PLEASE READ THE AGREEMENT CAREFULLY.
            </p>
            <h4 className="mt-5">1. Horus Wallet INTERFACE</h4>
            <p>
              <strong>General.</strong>
              The ZeHorus Walletrion Interface consists of the Horus Wallet web-hosted user
              interface, available at
              <a href="https://horuswallet.com/" target="_blank">
              https://horuswallet.com/{' '}
              </a>
              , and the Horus Wallet mobile application, each of which allows you to
              connect the Horus Wallet  or a third-party digital asset wallet to
              store and transfer certain supported digital assets (including
              cryptocurrencies, non-fungible tokens, digital asset indices,
              liquidity pool shares and staking tokens) in order to track and
              monitor a portfolio of digital assets as well as access and
              interact with certain supported decentralized protocols and
              decentralized applications (collectively “dapps”). Some of the
              dapps accessible using the Horus Wallet Interface may also involve
              accessing certain digital asset markets, boards, or interfaces
              allowing for complex financial transactions. In order to use the
              Horus Wallet Interface, you must already have a digital asset wallet
              that is supported in the Horus Wallet Interface. In addition, through
              certain of our other services offered through the Horus Wallet
              Interface, users can (i) monitor real-time movements of digital
              assets and track a personalized digital asset portfolio and (ii)
              access dapps through supported web browsers or through a native
              application installed on our mobile device or personal computer.
              IMPORTANT: Horus Wallet provides all information as is and you may not
              redistribute information displayed on or provided by the Horus Wallet
              Interface.
            </p>
            <p>
              <strong>Transfers</strong>
              When you request to make a transfer of digital asset, in order to
              initiate such transfer using the Interface, you will be required
              to initiate a transfer from your digital asset wallet’s interface
              or a web plug-in that interacts with the Horus Wallet Interface. You may
              initiate a transfer using the Interface after selecting a quote
              and sign a. Horus Wallet is entitled to rely on the transfer initiation
              and has no duty to inquire into or investigate the validity or
              accuracy of any Transfer Initiation.
            </p>
            <p>
              <strong>
                Third-Party Decentralized Finance Protocols (Exchange, Pool,
                Save, Borrow)
              </strong>
              The Horus Wallet Interface displays third-party decentralized finance
              protocols, including protocols for borrowing and lending,
              exchanges, and automated market makers, among other capabilities.
              Horus Wallet is not responsible for the fulfillment of any purchases and
              sales of digital assets, information security, exchange rates,
              routing, the interest rate or percentage yield, the insurance or
              collateralization or other programmatic, algorithmic or
              discretionary methods of any third party protocols or market
              participants whose services are displayed via the Horus Wallet Interface
              through these methods. The Company reserves the right to collect
              fees for marketing, routing, positive slippage, and other fees for
              displaying such third-party decentralized finance protocols.
            </p>
            <p>
              <strong>Market and Third-Party Data</strong>The Horus Wallet Interface
              contains information pertaining to third-party digital assets,
              digital asset wallets, staking pools, and liquidity pools, among
              other things. The presentation of this information does not imply
              any association with or endorsement of third parties, even if
              certain functionalities on the Horus Wallet Interface require the use of
              such third-party products. The content of such third-party
              services and products as stated on the Horus Wallet Interface is based
              on information made publicly available or by such third parties to
              us, and we, therefore, make no representations or warranties on
              the accuracy of such third-party service or product related
              information.
            </p>
            <p>
              <strong>Third-Party Payment Services Partners</strong>The Horus Wallet
              Interface provides users with links to third-party processors to
              process any fiat purchases of digital assets. When you purchase
              digital assets with fiat currencies through these links, you are
              purchasing digital assets directly from the third party provider
              using your valid bank account via ACH transfer or wire transfer,
              debit card or credit card. Your purchase must follow the relevant
              instructions on the Horus Wallet Interface and the terms and conditions
              of the third-party provider. Third-party providers, not the
              Company, are responsible for transferring purchased digital assets
              to your digital assets wallet.
            </p>
            <h4 className="mt-5">2. Horus Wallet</h4>
            <p>
              <strong>General</strong> In addition to the Horus Wallet Interface,
              users have access to Horus Wallet , a non-custodial wallet for
              holding supported digital assets developed by the Company. Horus Wallet
              Wallet is an unhosted and non-custodial. You control the digital
              assets held in your digital asset Wallet. With Horus  Wallet, the
              private keys (which represent ownership of the digital asset) are
              stored directly on your device. At any time, subject to having
              internet access and the congestion on the blockchain, you may
              withdraw your digital asset by sending it to a different
              blockchain address. When holding your digital assets in Horus Wallet
              Wallet, Horus Wallet does not maintain control over your Horus  Wallet
              or private keys. Users are responsible for the risk of loss of
              their private keys and Horus Wallet may not recover such keys.
            </p>
            <p>
              <strong>Recovery Phrase</strong> Horus Wallet generates a 12-word
              recovery phrase, known as a ‘seed', that you and only you have
              access to. This means that if you lose your recovery phrase, you
              will lose access to your Horus Wallet. Please keep in mind that
              Horus Wallet will never have access to this recovery phrase, so we
              cannot move funds on your behalf nor can we help you access
              digital assets in your Horus Wallet should you lose your recovery
              phrase. As an added safeguard, we built an encrypted Google Drive
              and iCloud feature so you can back up your recovery phrase. We
              strongly encourage you to back up your recovery phrase using this
              feature, as well as writing it down and storing it in a secure
              location. To access the backup feature, tap Settings then Recovery
              Phrase from your Horus Wallet mobile or desktop application.
            </p>
            <p>
              <strong>Supported Digital assets</strong>Horus Wallet supports
              all ERC-20 tokens. To see all the supported digital assets, you
              can store and transfer on Horus Wallet open the mobile app, and
              tap Receive. Use the search bar to see if a specific digital asset
              is supported. When you connect your Horus Wallet to Horus Wallet
              <a href="https://horuswallet.com/" target="_blank">
              https://horuswallet.com/{' '}
              </a>{' '}
              or decentralized exchange, you can buy or transfer supported
              digital assets on Horus Wallet or the decentralized exchange directly
              from your Horus Wallet.
            </p>
            <p>
              <strong>Digital asset Peer-to-Peer Transfers</strong>The Horus Wallet
               enables you to send supported digital assets to, and
              request, receive, and store supported digital assets from, third
              parties by giving instructions through the Services. Your transfer
              of supported digital assets between your other digital asset
              wallets and to and from third parties is a transfer. When you or a
              third party sends digital assets to the Horus Wallet, the person
              initiating the transaction is solely responsible for executing the
              transaction properly, which may include, among other things,
              payment of sufficient network or miner’s fees in order for the
              transaction to be successful. Insufficient network fees may cause
              a transfer to remain in a pending state and may result in delays
              or loss incurred as a result of an error in the initiation of the
              transaction. The Company has no obligation to assist in the
              remediation of such transactions. When you send digital asset from
              your Horus Wallet to another digital asset wallet, such transfers
              are executed on chain by you and not under the control of the
              Company. You should verify all transaction information prior to
              submitting them. The Company shall bear no liability or
              responsibility in the event you enter an incorrect blockchain
              destination address. Digital asset transfers cannot be reversed
              once they have been broadcast to the relevant digital asset
              network, although they may be in a pending state, and designated
              accordingly, while the transaction is processed by network
              operators. The Company does not control the network and makes no
              guarantees that a transfer will be confirmed by the network.
            </p>
            <h4 className="mt-5">3. Horus Wallet API</h4>
            <p>
              <strong> Scope</strong>
              Horus Wallet makes its API available to developers as follows through a
              self-serve API program where developers can integrate Horus Wallet API
              into their dapp or application (“Application”) if they meet the
              eligibility criteria set forth below and have agreed to these
              Terms.
            </p>
            <p>
              <strong> Developer Documentation</strong>
              Your use of any of the API and display of Content in your
              Application must comply with the technical documentation, usage
              guidelines, call volume limits, and other documentation maintained
              at the Developer Site or otherwise made available to you
              (together, the “Developer Documentation”, which are expressly
              incorporated into these Terms by reference). “Content” means any
              data or content from our Service or accessed via the API. In the
              event of any conflict between the Developer Documentation and
              these Terms, these Terms shall control. The Developer
              Documentation is available at https://horuswallet.com/.
            </p>
            <p>
              <strong> Eligibility Criteria</strong>
              In addition to your compliance with the other requirements and
              obligations set forth in these Terms, you may retrieve Content via
              the API in your Application if you are developing an Application
              designed to help Horus Wallet users or introduce new users to Horus Wallet and
              your Application DOES NOT make more than 250,000 daily calls to
              the API.
            </p>
            <p>
              <strong>Access Credentials</strong>
              Once you have successfully met the other requirements for the API,
              you will be given Access Credentials for your Application. “Access
              Credentials” means the necessary security keys, secrets, tokens,
              and other credentials to access the API. The Access Credentials
              enable us to associate your API activity with your Application.
              All activities that occur using your Access Credentials are your
              responsibility. Keep your Access Credentials secret. Do not sell,
              share, transfer, or sublicense them to any other party other than
              your employees or independent contractors. Do not try to
              circumvent them and do not require your users to obtain their own
              Access Credentials to use your Aapplication (for example, in an
              attempt to circumvent call limits).
            </p>
            <p>
              <strong>API License</strong>
              Subject to your compliance with this Agreement, we grant you a
              limited, non-exclusive, non-sublicensable (except to independent
              contractors so they may host, develop, test, operate, modify or
              support your Application in accordance with this Agreement),
              non-transferable, non-assignable license under LinkedIn’s
              intellectual property rights during the duration and term of this
              Agreement (a) to use the APIs to develop, test, operate and
              support your dApp or application; (b) to distribute or allow
              access to your integration of the API within your Application to
              end users of your Application; and (c) to display the Content
              accessed through the APIs within your Application. You have no
              right to distribute or allow access to the stand-alone API.
            </p>
            <h4 className="mt-5">4. FEES AND TAXES</h4>
            <p>
              By using the Services, you agree to pay all applicable fees. Bank
              fees, credit card and debit card fees charged for any digital
              asset purchases may be netted out of the settled amount of your
              digital asset purchases from Third Party Payment Services
              Partners. You are responsible for paying any additional fees
              charged by your financial service provider.
            </p>
            <p>
            Horus Wallet retains the right to collect fees now or in the future,
              including from positive price slippage. Price slippage is the
              difference in prices between the time a market order is placed and
              the time it completes on the blockchain or is filled. Slippage can
              either be positive or negative, depending on the direction of
              price change.
            </p>
            <p>
              It is your sole responsibility to determine whether, and to what
              extent, any taxes apply to any transaction via Horus Wallet or
              the Horus Wallet Interface, and to withhold, collect, report and remit
              the correct amount of tax to the appropriate tax authorities.
            </p>
            <h4 className="mt-5"> 5. RISK FACTORS</h4>
            <p>
              <strong>No Insurance</strong>
              Digital asset is not legal tender, is not backed by the
              government, and digital asset accounts and value balances on
              Horus Wallet are not subject to Federal Deposit Insurance Corporation or
              Securities Investor Protection Corporation protections. Horus Wallet is
              not a bank and does not offer fiduciary services. Horus Wallet makes no
              guarantee as to the functionality of any blockchain network or
              dapp, which could, among other things, lead to delays, conflicts
              of interest, or operational decisions by third parties that are
              unfavorable to certain owners of digital asset or lead to your
              inability to complete a transaction using our Services. The
              transaction details you submit via the Services may not be
              completed or may be substantially delayed on the Ethereum network,
              and Horus Wallet takes no responsibility for the failure of a
              transaction to be confirmed or processed as expected. There are no
              warranties or guarantees that a transfer initiated on the Services
              will successfully transfer title or right in any digital asset.
            </p>
            <p>
              <strong>New Technical Risk</strong>
              Both the Horus Wallet and Horus Wallet Interface are new. While this
              software has been extensively tested, the software used for these
              services is still relatively new and could have bugs or security
              vulnerabilities. Further, the software is still under development
              and may undergo significant changes over time that may not meet
              users’ expectations.
            </p>
            <p>
              <strong>Information Security Risk</strong>
              Digital asset and use of the Horus Wallet and Horus Wallet Interface
              may be subject to expropriation and/or theft. Hackers or other
              malicious groups or organizations may attempt to interfere with
              Horus Wallet  and Horus Wallet Interface in a variety of ways,
              including, but not limited to, malware attacks, denial of service
              attacks, consensus-based attacks, Sybil attacks, smurfing and
              spoofing. Furthermore, because the Ethereum protocol rest on open
              source software, there is the software underlying the Services may
              contain intentional or unintentional bugs or weaknesses which may
              negatively affect Horus Wallet and Horus Wallet Interface or result in
              the loss of user’s digital asset, the loss of user’s ability to
              access or control their Horus Wallet. In the event of such a
              software bug or weakness, there may be no remedy and users are not
              guaranteed any remedy, refund or compensation.
            </p>
            <p>
              <strong>No Insurance or Regulatory Protections</strong>
              You acknowledge that digital asset is not subject to protections
              or insurance provided by the Federal Deposit Insurance Corporation
              or the Securities Investor Protection Corporation. As a software
              provider, Horus Wallet is not regulated by any federal or state
              regulatory agency and is not subject to the examination or
              reporting requirements of any such agencies.
            </p>
            <p>
              <strong>Third-Party Resources and Promotions</strong>
              The Horus Wallet Interface may contain references or links to
              third-party resources, including (but not limited to) information,
              materials, products, or services, that we do not own or control.
              In addition, third parties may offer promotions related to your
              access and use of the Interface. Horus Wallet do not endorse or assume
              any responsibility for any such resources or promotions. If you
              access any such resources or participate in any such promotions,
              you do so at your own risk, and you understand that this Agreement
              does not apply to your dealings or relationships with any third
              parties. You expressly relieve us of any and all liability arising
              from your use of any such resources or participation in any such
              promotions.
            </p>
            <p>
              <strong>Operation of Digital Asset Protocols</strong>
              We do not own or control the underlying software protocols which
              govern the operation of digital asset supported on the Service.
              Generally, the underlying protocols are open source, and anyone
              can use, copy, modify, and distribute them. We assume no
              responsibility for the operation of the underlying protocols and
              we are not able to guarantee the functionality or security of
              network operations. In particular, the underlying protocols may be
              subject to sudden changes in operating rules (including “forks”).
              Any such material operating changes may materially affect the
              availability, value, functionality, and/or the name of the digital
              asset you store in your digital asset wallet. Horus Wallet does not
              control the timing and features of these material operating
              changes. It is your responsibility to make yourself aware of
              upcoming operating changes and you must carefully consider
              publicly available information and information concerning the
              digital asset protocols when determining whether to continue to
              use a Horus Wallet Service for the affected digital asset. You
              acknowledge and accept the risks of operating changes to digital
              asset protocols and agree that Horus Wallet is not responsible for such
              operating changes and not liable for any loss of value you may
              experience as a result of such changes in operating rules.
            </p>
            <h4 className="mt-5"> 1. AVAILABILITY AND ACCURACY</h4>
            <p>
              <strong>Access and Availability</strong>
              Although we strive to provide you with excellent service, we do
              not guarantee that the Horus Wallet Interface will be available without
              interruption.
            </p>
            <p>
              <strong>Accuracy</strong>
              Although we intend to provide accurate and timely information on
              the Horus Wallet Interface, the Horus Wallet Interface (including, without
              limitation, the content) may not always be entirely accurate,
              complete or current and may also include technical inaccuracies or
              typographical errors. In an effort to continue to provide you with
              as complete and accurate information as possible, information may,
              to the extent permitted by applicable law, be changed or updated
              from time to time without notice, including without limitation
              information regarding our policies, products, and services.
              Accordingly, you should verify all information before relying on
              it, and all decisions based on information contained on the Horus Wallet
              Interface are your sole responsibility and we shall have no
              liability for such decisions. Links to third-party materials
              (including without limitation any websites) may be provided as a
              convenience but are not controlled by us. You acknowledge and
              agree that we are not responsible for any aspect of the
              information, content, or services contained in any such
              third-party materials accessible or linked to the Horus Wallet
              Interface.
            </p>
            <h4 className="mt-5"> 2. INTELLECTUAL PROPERTY</h4>
            <p>
              <strong>Limited License</strong>
              All content in and in the Services, including but not limited to
              designs, text, graphics, pictures, video, information, software,
              music, sound, and other files, and their selection and arrangement
              (the “Content”), are the proprietary property of Horus Wallet with all
              rights reserved. No Content may be modified, copied, distributed,
              framed, reproduced, republished, downloaded, displayed, posted,
              transmitted, or sold in any form or by any means, in whole or in
              part, without Horus Wallet's prior written permission, except as
              provided in the following sentence and except that the foregoing
              does not apply to your own User Content (as defined below) that
              you legally post on the Horus Wallet Interface. Provided that you are
              eligible for use of the Horus Wallet Interface and Horus Wallet, you
              are granted a limited license to access and use the Horus Wallet
              Interface and to download or print a copy of any portion of the
              Content solely for your use in connection with your use of the
              Services, provided that you keep all copyright or other
              proprietary notices intact. Except for your own User Content (as
              defined below), you may not republish Content on any Internet,
              Intranet or Extranet site or incorporate the information in any
              other database or compilation, and any other use of the Content is
              strictly prohibited. Any use of the Content other than as
              specifically authorized herein, without the prior written
              permission of Horus Wallet, is strictly prohibited and will terminate
              the license granted herein. Such unauthorized use may also violate
              applicable laws including without limitation copyright and
              trademark laws and applicable communications regulations and
              statutes. Unless explicitly stated herein, nothing in this
              Agreement shall be construed as conferring any license to
              intellectual property rights, whether by estoppel, implication or
              otherwise. This license is revocable by us at any time without
              notice and with or without cause.
            </p>
            <p>
              <strong>Trademarks</strong>
              Horus Wallet and other Horus Wallet graphics, logos, designs, page headers,
              button icons, scripts and service names are trademarks or trade
              dresses of Horus Wallet in the U.S. and/or other countries. Horus Wallet's
              trademarks and trade dress may not be used, including as part of
              trademarks and/or as part of domain names, in connection with any
              product or service in any manner that is likely to cause confusion
              and may not be copied, imitated, or used, in whole or in part,
              without the prior written permission of Horus Wallet. Horus Wallet may, at its
              sole discretion, limit access to the Site and/or terminate the
              memberships of any users who infringe any intellectual property
              rights of Horus Wallet or others.
            </p>
            <p>
              <strong>Copyright Complaints</strong>
              If you believe that any material on the Site infringes upon any
              copyright which you own or control, you may send a written
              notification of such infringement to our Horus Wallet as set forth below
              to horus@gmail.com or by physical mail to Horus Wallet Inc., 1412 Market
              Street, San Francisco, CA 94102.
            </p>
            <p>
              To meet the notice requirements under the Digital Millennium
              Copyright Act, the notification must be a written communication
              that includes the following:
            </p>
            <div className="ps-5">
              {' '}
              <p>
                ● A physical or electronic signature of a person authorized to
                act on behalf of the owner of an exclusive right that is
                allegedly infringed;
              </p>
              <p>
                ● Identification of the copyrighted work claimed to have been
                infringed, or, if multiple copyrighted works at a single online
                site are covered by a single notification, a representative list
                of such works at that site;
              </p>
              <p>
                ● Identification of the material that is claimed to be
                infringing or to be the subject of infringing activity and that
                is to be removed or access to which is to be disabled, and
                information reasonably sufficient to permit us to locate the
                material;
              </p>
              <p>
                ● Information reasonably sufficient to permit us to contact the
                complaining party, such as an address, telephone number and, if
                available, an electronic mail address at which the complaining
                party may be contacted;
              </p>
              <p>
                ● A statement that the complaining party has a good-faith belief
                that use of the material in the manner complained of is not
                authorized by the copyright owner, its agent or the law; and
              </p>
              <p>
                ● A statement that the information in the notification is
                accurate, and under penalty of perjury, that the complaining
                party is authorized to act on behalf of the owner of an
                exclusive right that is allegedly infringed.
              </p>
            </div>
            <p>
              <strong>9.4. Submissions</strong>
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback or other information about the Site
              or the Services ("Submissions"), provided by you to Horus Wallet are
              non-confidential and shall become the sole property of Horus Wallet.
              Horus Wallet shall own exclusive rights, including all intellectual
              property rights, and shall be entitled to the unrestricted use and
              dissemination of these Submissions for any purpose, commercial or
              otherwise, without acknowledgment or compensation to you.
            </p>
            <h4 className="mt-5">4. DISPUTES</h4>
            <p>
              <strong>Arbitration Agreement</strong>
              If we cannot resolve the dispute through the Formal Complaint
              Process, you and we agree that any dispute arising out of or
              relating to this Agreement or the Services, including, without
              limitation, federal and state statutory claims, common law claims,
              and those based in contract, tort, fraud, misrepresentation, or
              any other legal theory, shall be resolved through binding
              arbitration, on an individual basis (the “Arbitration Agreement”).
              Subject to applicable jurisdictional requirements, you may elect
              to pursue your claim in your local small claims court rather than
              through arbitration so long as your matter remains in small claims
              court and proceeds only on an individual (non-class and
              non-representative) basis. Arbitration shall be conducted in
              accordance with the American Arbitration Association's rules for
              arbitration of consumer-related disputes (accessible at
              https://www.adr.org/sites/default/files/Consumer%20Rules.pdf).
              This Arbitration Agreement includes, without limitation, disputes
              arising out of or related to the interpretation or application of
              the Arbitration Agreement, including the enforceability,
              revocability, scope, or validity of the Arbitration Agreement or
              any portion of the Arbitration Agreement. All such matters shall
              be decided by an arbitrator and not by a court or judge.
            </p>
            <p>
              <strong>Arbitration Procedures</strong>
              The arbitration will be conducted by a single, neutral arbitrator
              and shall take place in the county or parish in which you reside,
              or another mutually agreeable location, in the English language.
              The arbitrator may award any relief that a court of competent
              jurisdiction could award and the arbitral decision may be enforced
              in any court. An arbitrator’s decision and judgment thereon will
              not have a precedential or collateral estoppel effect. At your
              request, hearings may be conducted in person or by telephone and
              the arbitrator may provide for submitting and determining motions
              on briefs, without oral hearings. To the extent permitted by law,
              the prevailing party in any action or proceeding to enforce this
              Agreement, any arbitration pursuant to this Agreement, or any
              small claims action shall be entitled to costs and attorneys’
              fees.
            </p>
            <p>
              <strong>Waiver of Class Action and Jury Trial</strong>
              YOU AND WE AGREE THAT, BY ENTERING INTO THIS AGREEMENT, THE YOU
              AND HORUS WALLET ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
              PARTICIPATE IN A CLASS ACTION. YOU AND WE ACKNOWLEDGE THAT
              ARBITRATION WILL LIMIT YOUR LEGAL RIGHTS, INCLUDING THE RIGHT TO
              PARTICIPATE IN A CLASS ACTION, THE RIGHT TO A JURY TRIAL, THE
              RIGHT TO CONDUCT FULL DISCOVERY, AND THE RIGHT TO APPEAL (EXCEPT
              AS PERMITTED IN SECTION12.3 OR UNDER THE FEDERAL ARBITRATION ACT).
            </p>
            <p>
              <strong>Disclaimers</strong>
              None of Horus Wallet's, its parent, any of its affiliates, subsidiaries,
              providers or their respective officers, directors, employees,
              agents, independent contractors or licensors (collectively the
              “Horus Wallet Parties”) guarantees the accuracy, adequacy, timeliness,
 Parties”) guarantees the accuracy, adequacy, timeliness,
              reliability, completeness, or usefulness of any of the Content and
              the Horus Wallet Parties disclaim liability for errors or omissions in
              the Content. This Horus Wallet Interface and Horus Wallet and all of
              the Content is provided "as is" and "as available," without any
              warranty, either express or implied, including the implied
              warranties of merchantability, fitness for a particular purpose,
              non-infringement or title. Additionally, there are no warranties
              as to the results of your use of the Content. The Horus Wallet Parties
              do not warrant that the Horus Wallet Interface is free of viruses or
              other harmful components. This does not affect those warranties
              which are incapable of exclusion, restriction or modification
              under the laws applicable to this Agreement. Horus Wallet cannot
              guarantee and does not promise any specific results from use of
              the Services to exchange, borrow, loan, stake or pool digital
              assets.
            </p>
            <p>
              <strong>Release of Claims</strong>
              You expressly agree that you assume all risks in connection with
              your access and use of the Services. You further expressly waive
              and release us from any and all liability, claims, causes of
              action, or damages arising from or in any way relating to your use
              of the Interface and your interaction with the Services. If you
              are a California resident, you waive the benefits and protections
              of California Civil Code § 1542, which provides: ”[a] general
              release does not extend to claims that the creditor or releasing
              party does not know or suspect to exist in his or her favor at the
              time of executing the release and that, if known by him or her,
              would have materially affected his or her settlement with the
              debtor or released party.” Availability The Horus Wallet Interface may
              be temporarily unavailable from time to time for maintenance or
              other reasons. Horus Wallet assumes no responsibility for any error,
              omission, interruption, deletion, defect, delay in operation or
              transmission, communications line failure, theft or destruction or
              unauthorized access to, or alteration of, user communications.
              Horus Wallet is not responsible for any problems or technical
              malfunction of any telephone network or lines, computer online
              systems, servers or providers, computer equipment, software,
              failure of email or players on account of technical problems or
              traffic congestion on the Internet or on via the Services or
              combination thereof, including injury or damage to users or to any
              other person's computer related to or resulting from participating
              or downloading materials in connection with the Web and/or in
              connection with the Horus Wallet Interface and Horus Wallet. Under no
              circumstances will Horus Wallet be responsible for any loss or damage,
              including any loss or damage to any user Content, financial
              damages or lost profits, loss of business, or personal injury or
              death, resulting from anyone's use of the Service, any User
              Content or Third Party Content posted on or through the Services
              or transmitted to users, or any interactions between users of the
              Services, whether online or offline.
            </p>
            <p>
              <strong>Limitation on Liability</strong>
              EXCEPT IN JURISDICTIONS WHERE SUCH PROVISIONS ARE RESTRICTED, IN
              NO EVENT WILL HORUS WALLET OR ITS DIRECTORS, EMPLOYEES OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL,
              EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR
              ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE
              SERVICES OR ANY OF THE CONTENT OR OTHER MATERIALS ON OR ACCESSED
              THROUGH THE SERVICES, EVEN IF HORUS WALLET IS AWARE OR HAS BEEN ADVISED
              OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p>
              NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, TO THE
              EXTENT PERMITTED BY APPLICABLE LAW HORUS WALLET'S LIABILITY TO YOU FOR
              ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION,
              WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO
              HORUS WALLET FOR THE SERVICES. IN NO CASE WILL HORUS WALLET'S LIABILITY TO YOU
              EXCEED THE LESSER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO
              HORUS WALLET IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH
              PERIOD PRECEDING THIS APPLICABLE CLAIM. YOU ACKNOWLEDGE THAT IF NO
              FEES ARE PAID TO HORUS WALLET FOR THE SERVICE, YOU SHALL BE LIMITED TO
              INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PERMITTED BY LAW, AND
              SHALL NOT BE ENTITLED TO DAMAGES OF ANY KIND FROM HORUS WALLET,
              REGARDLESS OF THE CAUSE OF ACTION.
            </p>
            <p>
              CERTAIN FEDERAL AND STATE LAWS DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF CERTAIN DAMAGES OR LIMITATIONS ON IMPLIED
              WARRANTIES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
              DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
              YOU MAY HAVE ADDITIONAL RIGHTS.
            </p>
            <p>
              <strong>Governing Law; Venue and Jurisdiction</strong>
              By visiting or using the Services, you agree that the laws of the
              state of California, without regard to any principles of conflict
              of laws that would require or permit the application of the laws
              of any other jurisdiction, will govern this Agreement. If you use
              the Services, the terms of the Services will be governed by
              federal laws and the laws of the state of California to the extent
              not preempted, without regard to any principle of conflicts of
              laws that would require or permit the application of the laws of
              any other jurisdiction.
            </p>
            <p>
              <strong>Indemnity</strong>
              You also agree to indemnify and hold Horus Wallet, its subsidiaries,
              affiliates and service providers, and each of its or their
              respective officers, directors, agents, joint venturers,
              employees, and representatives, harmless from any claim or demand
              (including attorneys' fees and any fines, fees or penalties
              imposed by any regulatory authority) arising out of or related to
              your breach of this Agreement or your violation of any law, rule
              or regulation, or the rights of any third party.
            </p>
            <h4 className="mt-5"> 5. GENERAL PROVISIONS</h4>
            <p>
              <strong>Amendments</strong>
              We may amend or modify this Agreement by posting on Horus Wallet
              Interface or emailing to you the revised Agreement, and the
              revised Agreement shall be effective at such time. If you do not
              agree with any such modification, your sole and exclusive remedy
              is to terminate your use of the Services and close your account.
              You agree that we shall not be liable to you or any third party
              for any modification or termination of the Services, or suspension
              or termination of your access to the Services, except to the
              extent otherwise expressly set forth herein. If the revised
              Agreement includes a material change, we will endeavor to provide
              you advanced notice via our website and/or email before the
              material change becomes effective.
            </p>
            <p>
              <strong>Assignment</strong>
              You may not assign any rights and/or licenses granted under this
              Agreement. We reserve the right to assign our rights without
              restriction, including without limitation to any Horus Wallet affiliates
              or subsidiaries, or to any successor in the interest of any
              business associated with the Services. Any attempted transfer or
              assignment in violation hereof shall be null and void. Subject to
              the foregoing, this Agreement will bind and inure to the benefit
              of the parties, their successors, and permitted assigns. In the
              event that Horus Wallet is acquired by or merged with a third party
              entity or undergoes a change of control, we reserve the right, in
              any of these circumstances, to transfer or assign the information
              we have collected from you as part of such merger, acquisition,
              sale, or other change of control.
            </p>
            <p>
              <strong>Force Majeure</strong>
              Horus Wallet shall not be liable for delays, failure in performance or
              interruption of service which result directly or indirectly from
              any cause or condition beyond our reasonable control, including
              but not limited to, significant market volatility, any delay or
              failure due to any act of God, act of civil or military
              authorities, act of terrorists, civil disturbance, war, strike or
              other labor dispute, fire, interruption in telecommunications or
              Internet services or network provider services, failure of
              equipment and/or software, other catastrophe or any other
              occurrence which is beyond our reasonable control and shall not
              affect the validity and enforceability of any remaining
              provisions.
            </p>
            <p>
              <strong>Links to Other Web Sites and Content</strong>
              The Horus Wallet Interface and Horus Wallet) contain (or you may be
              sent through the Horus Wallet Interface and Horus Wallet) links to
              other websites (“Third Party Sites”), as well as articles,
              photographs, text, graphics, pictures, designs, music, sound,
              video, information, software and other content belonging to or
              originating from third parties (the “Third Party Content”). Such
              Third Party Sites and Third Party Content are not investigated,
              monitored or checked for accuracy, appropriateness, or
              completeness by us, and we are not responsible for any Third Party
              Sites accessed through the Horus Wallet Interface and Horus Wallet or
              any Third Party Content posted on the Horus Wallet Interface and Horus Wallet
              Wallet), including without limitation the content, accuracy,
              offensiveness, opinions, reliability or policies of or contained
              in the Third Party Sites or the Third Party Content. Inclusion of
              or linking to any Third Party Site or any Third Party Content does
              not imply approval or endorsement thereof by us. If you decide to
              leave the Site and access the Third Party Sites, you do so at your
              own risk and you should be aware that our terms and policies no
              longer govern. You should review the applicable terms and
              policies, including privacy and data gathering practices, of any
              site to which you navigate from the Horus Wallet Interface and Horus Wallet
              Wallet.
            </p>
            <p>
              <strong>No-Waiver</strong>
              The failure of Horus Wallet to exercise or enforce any right or
              provision of this Agreement shall not constitute a waiver of such
              right or provision in that or any other instance. If any provision
              of this Agreement is held invalid, the remainder of this Agreement
              shall continue in full force and effect. If any provision of this
              Agreement shall be deemed unlawful, void or for any reason
              unenforceable, then that provision shall be deemed severable from
              this Agreement and shall not affect the validity and
              enforceability of any remaining provisions.
            </p>
            <p>
              <strong>Notices</strong>
              To give us notice under this Agreement, the user must contact
              Horus Wallet by email to horus@gmail.com.
            </p>
            <p>
              <strong>Promotions</strong>
              From time to time, Horus Wallet may make available special offers or
              conduct promotions for qualifying users. Subject to applicable
              laws, Horus Wallet may establish qualifying criteria to participate in
              any special promotion its sole discretion. Horus Wallet may revoke any
              special offer at any time without notice. Horus Wallet shall have no
              obligation to make special offers available to all users. Horus Wallet
              makes no recommendation and does not provide any advice about the
              value or utility of any digital asset, pool, lending arrangement
              or Services otherwise subject to a promotion.
            </p>
            <p>
              <strong>Severability</strong>
              If any provision of this Agreement shall be determined to be
              invalid or unenforceable under any rule, law, or regulation of any
              local, state, or federal government agency, such provision will be
              changed and interpreted to accomplish the objectives of the
              provision to the greatest extent possible under any applicable law
              and the validity or enforceability of any other provision of this
              Agreement shall not be affected. If such construction is not
              possible, the invalid or unenforceable portion will be severed
              from this Agreement but the rest of the Agreement will remain in
              full force and effect.
            </p>
            <p>
              <strong>Survival</strong>
              The following provisions of this Agreement shall survive
              termination of your use or access to the Horus Wallet Interface and
              Horus Wallet): the sections concerning Indemnification,
              Disclaimer of Warranties, Limitation of Liability, Waiver,
              Applicable Law and Dispute Resolution, and General Provisions, and
              any other provision that by its terms survives termination of your
              use or access to the Horus Wallet Interface and Horus Wallet).
            </p>
            <p>
              APPENDIX A: PROHIBITED USE, PROHIBITED BUSINESS, AND CONDITIONAL
              USE POLICY
            </p>
            <p>
              <strong>Prohibited Use</strong>
              You may not use the Services to engage in the following categories
              of activity (“Prohibited Uses”). The specific of use listed below
              are representative, but not exhaustive. If you are uncertain as to
              whether or not your use of the Services involves a Prohibited Use,
              or have questions about how these requirements apply to you,
              please contact us at horus@gmail.com. By opening a Horus Wallet or
              accessing the Horus Wallet Interface or Horus Wallet API, you confirm that you
              will not use your Account to do any of the following, as relevant:
            </p>
            <div className="ps-5">
              <p>
                <strong>● Abusive Activity:</strong> Actions which impose an
                unreasonable or disproportionately large load on our
                infrastructure, or detrimentally interfere with, intercept, or
                expropriate any system, data, or information; transmit or upload
                any material to the Horus Wallet Interface that contains viruses,
                trojan horses, worms, or any other harmful or deleterious
                programs; attempt to gain unauthorized access to the Services,
                computer systems or networks connected to the Services, through
                password mining or any other means; use Horus Wallet
                information of another party to access or use the Horus Wallet,
                or transfer your account access or rights to your account to a
                third party, unless by operation of law or with the express
                permission of Horus Wallet.
              </p>
              <p>
                <strong>● Abuse Other Users:</strong> Interfere with another
                individual's or entity's access to or use of any Services;
                defame, abuse, extort, harass, stalk, threaten or otherwise
                violate or infringe the legal rights (such as, but not limited
                to, rights of privacy, publicity and intellectual property) of
                others; incite, threaten, facilitate, promote, or encourage
                hate, racial intolerance, or violent acts against others;
                harvest or otherwise collect information from the Services about
                others, including without limitation email addresses, without
                proper consent.
              </p>
              <p>
                <strong>● Fraud:</strong> Activity which operates to defraud
                Horus Wallet, Horus Wallet users, or any other person; provide any false,
                inaccurate, or misleading information to Horus Wallet.
              </p>
              <p>
                <strong>● Gambling: </strong>Lotteries; bidding fee auctions;
                sports forecasting or odds making; fantasy sports leagues with
                cash prizes; internet gaming; contests; sweepstakes; games of
                chance
              </p>
              <p>
                <strong>● Intellectual Property Infringement:</strong> Engage in
                transactions involving items that infringe or violate any
                copyright, trademark, right of publicity or privacy or any other
                proprietary right under the law, including but not limited to
                sales, distribution, or access to counterfeit music, movies,
                software, or other licensed materials without the appropriate
                authorization from the rights holder; use of Horus Wallet intellectual
                property, name, or logo, including use of Horus Wallet trade or
                service marks, without express consent from Horus Wallet or in a
                manner that otherwise harms Horus Wallet or the Horus Wallet brand; any
                action that implies an untrue endorsement by or affiliation with
                Horus Wallet.
              </p>
              <p>
                <strong>● Unlawful Activity:</strong> Activity which would
                violate, or assist in violation of, any law, statute, ordinance,
                or regulation, sanctions programs administered in the countries
                where Horus Wallet conducts business, including but not limited to the
                U.S. Department of Treasury's Office of Foreign Assets Control
                ("OFAC"), or which would involve proceeds of any unlawful
                activity; publish, distribute or disseminate any unlawful
                material or information.
              </p>
              <p>
                <strong>Prohibited Business</strong>
                You may not use your Horus Wallet(s) to engage in the following
                categories of activity ("Prohibited Use(s)"). The specific types
                of use listed below are representative, but not exhaustive. If
                you are uncertain as to whether or not your use of the Services
                involves a Prohibited Use, or have questions about how these
                requirements apply to you, please contact us at horus@gmail.com.
                By opening a Horus Wallet, you confirm that you will not use
                your Horus Wallet to do any of the following:
              </p>
              <p>
                <strong>● Counterfeit or Unauthorized Goods:</strong>{' '}
                Unauthorized sale or resale of a brand name or designer products
                or services; sale of goods or services that are illegally
                imported or exported or which are stolen.
              </p>
              <p>
                <strong>● High-risk businesses:</strong> any businesses that we
                believe poses elevated financial risk, legal liability, or
                violates card network or bank policies.
              </p>
              <p>
                <strong>
                  ● Intellectual Property or Proprietary Rights Infringement:
                </strong>{' '}
                Sales, distribution, or access to counterfeit music, movies,
                software, or other licensed materials without the appropriate
                authorization from the rights holder.
              </p>
              <p>
                <strong>● Investment and Credit Services: </strong>Securities
                brokers; mortgage consulting or debt reduction services; credit
                counseling or repair; real estate opportunities; investment
                schemes.
              </p>
              <p>
                <strong>● Multi-level Marketing:</strong> Pyramid schemes,
                network marketing, and referral marketing programs
              </p>
              <p>
                <strong>● Pseudo-Pharmaceuticals:</strong> Pharmaceuticals and
                other products that make health claims that have not been
                approved or verified by the applicable local and/or national
                regulatory body.
              </p>
              <p>
                <strong>● Regulated Products and Services:</strong> Marijuana
                dispensaries and related businesses; sale of tobacco,
                e-cigarettes, and e-liquid; online prescription or
                pharmaceutical services; age-restricted goods or services;
                weapons and munitions; gunpowder and other explosives; fireworks
                and related goods; toxic, flammable, and radioactive materials;
                products and services with varying legal status on a
                state-by-state basis.
              </p>
              <p>
                <strong>● Restricted Financial Services:</strong> Check cashing,
                bail bonds, and collections agencies.
                <p>
                  <strong></strong>● Substances designed to mimic illegal drugs:
                  Sale of a legal substance that provides the same effect as an
                  illegal drug (e.g., salvia, kratom).
                </p>
                <p>
                  <strong>● Unfair, predatory or deceptive practices:</strong>{' '}
                  Investment opportunities or other services that promise high
                  rewards; Sale or resale of a service without added benefit to
                  the buyer; resale of government offerings without
                  authorization or added value; sites that we determine in our
                  sole discretion to be unfair, deceptive, or predatory towards
                  consumers. Conditional Use Express written consent and
                  approval from Horus Wallet must be obtained prior to using Services
                  for the following categories of business and/or use
                  ("Conditional Uses"). Consent may be requested by contacting
                  us at horus@gmail.com. Horus Wallet may also require you to agree to
                  additional conditions, make supplemental representations and
                  warranties, complete enhanced on-boarding procedures, and
                  operate subject to restrictions if you use Services in
                  connection with any of following businesses, activities, or
                  practices:
                </p>
                <p>
                  <strong>● Money Services:</strong> Money transmitters, digital
                  asset transmitters; currency or digital asset exchanges or
                  dealers; gift cards; prepaid cards; sale of in-game currency
                  unless the merchant is the operator of the virtual world; act
                  as a payment intermediary or aggregator or otherwise resell
                  any of the Services.
                </p>
                <p>
                  <strong>● Games of Skill: </strong>Games which are not defined
                  as gambling under this Agreement or by law, but which require
                  an entry fee and award a prize.
                </p>
                <p>
                  <strong>● Charities:</strong> Acceptance of donations for
                  nonprofit enterprise.
                </p>
                <p>
                  <strong>● Religious/Spiritual Organizations: </strong>
                  Operation of a for-profit religious or spiritual organization.
                </p>
                <p>
                  <strong>APPENDIX B: E-SIGN DISCLOSURES AND CONSENT</strong>
                  This policy describes how the Company delivers communications
                  to you electronically. The Company may amend this policy at
                  any time by providing a revised version on our website. The
                  revised version will be effective at the time we post it. We
                  will provide you with prior notice of any material changes via
                  our website.
                </p>
                <p>
                  <strong>Electronic Delivery of Communications</strong>
                  You agree and consent to receive electronically all
                  communications, agreements, documents, notices, and
                  disclosures (collectively, "Communications") that we provide
                  in connection with your Horus Wallet(s) and your use of the
                  Horus Wallet. Communications include:
                </p>
                <p>
                  <strong>
                    ● Terms of use and policies you agree to (e.g., the Horus Wallet
                    Terms of User and Privacy Policy), including updates to
                    these agreements or policies;
                  </strong>
                </p>
                <p>
                  <strong>
                    ● Account details, history, transaction receipts,
                    confirmations, and any other Account or transaction
                    information;
                  </strong>
                </p>
                <p>
                  <strong>
                    ● Legal, regulatory, and tax disclosures or statements we
                    may be required to make available to you; and
                  </strong>
                </p>
                <p>
                  <strong>
                    ● Responses to claims or customer support inquiries filed in
                    connection with your Account. Horus Wallet will provide these
                    Communications to you by posting them on the Horus Wallet website,
                    emailing them to you at the primary email address listed in
                    your Horus Wallet profile, communicating to you via instant chat,
                    and/or through other electronic communication such as text
                    message or mobile push notification.
                  </strong>
                </p>
                <p>
                  <strong>Hardware and Software Requirements</strong>
                </p>
                In order to access and retain electronic Communications, you
                will need the following computer hardware and software:
              </p>
              <p>
                <strong>● A device with an Internet connection;</strong>
              </p>
              <p>
                <strong>
                  ● A current web browser that includes 128-bit encryption
                  (e.g., Internet Explorer version 9.0 and above, Firefox
                  version 3.6 and above, Chrome version 31.0 and above, or
                  Safari 7.0 and above) with cookies enabled;
                </strong>
              </p>
              <p>
                <strong>
                  ● A valid email address (your primary email address on file
                  with the Company); and
                </strong>
              </p>
              <p>
                <strong>
                  ● Sufficient storage space to save past Communications or an
                  installed printer to print them.
                </strong>
              </p>
            </div>
            <p>
              <strong>How to Withdraw Your Consent</strong>
              You may withdraw your consent to receive Communications
              electronically by contacting us at horus@gmail.com. If you fail to
              provide or if you withdraw your consent to receive Communications
              electronically, the Company reserves the right to immediately
              close your Account or charge you additional fees for paper copies.
            </p>
            <p>
              <strong>Updating your Information</strong>
              It is your responsibility to provide us with a true, accurate, and
              complete e-mail address and your contact information, and to keep
              such information up to date. You understand and agree that if the
              Company sends you an electronic Communication but you do not
              receive it because your primary email address on file is
              incorrect, out of date, blocked by your service provider, or you
              are otherwise unable to receive electronic Communications, the
              Company will be deemed to have provided the Communication to you.
            </p>
            <p>
              You may update your information by logging into your account and
              visiting settings or by contacting our support team at
              horus@gmail.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Terms;
