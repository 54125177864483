import toast from 'react-hot-toast';
import {
  createCollection_custodial,
  createCollection_nonCustodial,
} from '../../web3/nfts-utils/nfts-factory/HorusFactory_Utils';
import { openPasswordModel } from '../../social-wallet/PasswordModal';

export const validateCollectionProps = (props, traits) => {
  if (props.name == '') {
    toast.error('Add collection name');
    return false;
  } else if (props.symbol == '') {
    toast.error('Add collection Symbol');
    return false;
  } else if (props.nftCount == '' || props.nftCount == 0) {
    toast.error('Add collection NFT Count');
    return false;
  } else if (!props.image) {
    toast.error('Add collection Image');
    return false;
  } else if (traits.length == 0) {
    toast.error('Add collection Traits');
    return false;
  } else {
    return true;
  }
};

export const createCollection = async ({
  balance,
  isLoggedIn,
  loginOption,
  hasPassword,
  network,
  collectionProps,
  collectionTraits,
  isGeneratedImageLayers,
  isGeneratedImageAILayersHasURI,
  isGeneratedImageAILayers,
  confirmedURI,
  email,
  entityInfo,
  setLoading,
}) => {
  if (balance < 0.001)
    return toast.error("You don't have enough balance to mint");
  // Configure collection props with needed properties
  let CollectionData = {
    name: collectionProps.name,
    symbol: collectionProps.symbol,
    image: collectionProps.image,
    maxNfts: collectionProps.nftCount,
    traits: collectionTraits,
    confirmedURI: confirmedURI,
  };

  // Override collection properties if isGeneratedImageLayers is true
  if (isGeneratedImageLayers) {
    // Append extra variables to pass to create collections calls
    CollectionData = {
      ...CollectionData,
      content: collectionProps.content,
      layersInfo: collectionProps.layersInfo,
      imageDimensions: collectionProps.imageDimensions,
    };
  }
  // Override collection properties if isGeneratedImageLayers is true
  if (isGeneratedImageAILayers) {
    // Append extra variables to pass to create collections calls
    CollectionData = {
      ...collectionProps,
      maxNfts: collectionProps.nftCount,
    };
  }
  if (isLoggedIn) {
    try {
      setLoading(true);
      if (loginOption === 'custodial') {
        if (hasPassword) {
          openPasswordModel(
            handlePassSecureTransfer,
            {
              email,
              entityInfo,
              network,
              CollectionData,
              setLoading,
              isGeneratedImageLayers,
              isGeneratedImageAILayers,
              isGeneratedImageAILayersHasURI,
            },
            setLoading
          );
        } else {
          await createCollection_custodial(
            email,
            entityInfo.indexWallet,
            network,
            CollectionData,
            '', // pass value for password
            isGeneratedImageLayers,
            isGeneratedImageAILayers,
            isGeneratedImageAILayersHasURI
          );
          setLoading(false);
        }
      } else if (loginOption === 'non_custodial') {
        await createCollection_nonCustodial(
          network,
          CollectionData,
          isGeneratedImageLayers,
          isGeneratedImageAILayers,
          isGeneratedImageAILayersHasURI
        );
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      throw e;
    }
  }
};

const handlePassSecureTransfer = async (password, input) => {
  if (password === '') return toast.error('Password is required');
  if (password == null) return;
  try {
    await createCollection_custodial(
      input.email,
      input.entityInfo.indexWallet,
      input.network,
      input.CollectionData,
      password,
      input.isGeneratedImageLayers
    );
    input.setLoading(false);
  } catch (e) {
    console.log(e);
    input.setLoading(false);
  }
};
