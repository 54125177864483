import axios from "axios";

export async function sendNewUserEmail(recipient, amount, token , type) {
  const emailSubject =
    `Congratulations! You have received ${type} in your Horus Wallet`;

  const emailContent = `
    <h1>Congratulations!</h1>
    <p>We are pleased to inform you that you have received ${amount} ${token} in your Horus Wallet. To claim them, please <a href="${process.env.REACT_APP_SITE_URL}">sign in</a> to your account. If you have any questions or need assistance, feel free to contact our support team.</p>
    <p>Best regards,</p>
    <p>The Horus Wallet Team</p>
  `;

  const apiName =
    "https://h3mfjhvyjk.execute-api.us-east-1.amazonaws.com/sendEmail";

  https: try {
    const response = await axios.post(apiName, {
      toEmail: recipient,
      subject: emailSubject,
      emailText: emailContent,
    });

    if (response.status === 200) {
      console.log("Email sent successfully");
    } else {
      console.error("Email sending failed", response.status, response.data);
    }
  } catch (error) {
    console.error("Error sending email", error);
  }
}
