import React from 'react'
import { extractSchedulesList } from '../../helper/helperFunc';
import DatePicker from "react-datepicker";
import WalletTooltip from '../../components/social-wallet/tooltip';
import { toast } from "react-hot-toast";
import BackButton from '../../components/common/backButton';
const ScheduleTransaction = () => {

    const [filterKey, setFilterKey] = React.useState("*");
    const [activeClass, setActiveClass] = React.useState("*");
    const [uploadedData, setUploadedData] = React.useState([])
    const [keys, setKeys] = React.useState([])
    const [startDate, setStartDate] = React.useState(new Date());

    const initialData = [
        { name: '', address: '', amount: '', status: "Add" } // Initial empty row
    ];
    const [manualData, setManualData] = React.useState(initialData);
    const handleFilterKeyChange = (key) => () => {
        setFilterKey(key);
        setActiveClass(key);
    };

    const handleActiveClass = (key) => {
        if (key === activeClass) return "active";
    };
    const detectCsvFileData = async (event, type) => {
        const data = await extractSchedulesList(event)
        // setReceiversMils(data)
        const keys = Object.keys(data[0]);
        setKeys(keys)
        setUploadedData(data)
    }
    const removeElement = (index) => {
        setUploadedData((prevData) => {
            const newData = prevData.filter((_, i) => i !== index);
            return newData;
        });
    }

    //manual data func manager
    const handleInputChange = (index, key, value) => {
        setManualData((prevData) => {
            const newData = [...prevData];
            newData[index][key] = value;
            return newData;
        });
    };

    const handleAddRow = (index) => {
        const newItem = manualData[index];
        
        if (newItem.name === "") {
          toast.error("Please add a user name.");
          return;
        } else if (newItem.address === "") {
          toast.error("Please add a valid user phone or email.");
          return;
        } else if (newItem.amount === "" || newItem.amount === 0) {
          toast.error("Please add a user amount.");
          return;
        }
      
        setManualData((prevData) => {
          const newData = [...prevData];
          newData[index].status = "Remove";
          
          if (newItem.name !== "" && newItem.address !== "" && newItem.amount !== "") {
            newData.push({ name: "", address: "", amount: "", status: "Add" });
          }
      
          return newData;
        });
      };
      


    const handleRemoveRow = (index) => {
        setManualData((prevData) => {
            const newData = [...prevData];
            newData.splice(index, 1);
            return newData;
        });
    };

    return (
        <div className="fugu--dashboard-section mb-5">
            <BackButton />
            <div className="fugu--portfolio-menu">
                <ul className="option-set clear-both">
                    <li onClick={handleFilterKeyChange("*")} className={handleActiveClass("*")}>
                        <WalletTooltip title={"Upload csv file contains a header"}>
                        <div>
                            <input
                                type="file"
                                id="file-input"
                                name="file"
                                onChange={(event) => detectCsvFileData(event, "Email")}
                                accept=".csv"
                                capture="user"
                                style={{ display: "none" }}
                            />
                            <label htmlFor="file-input">
                                <span>
                                    Upload CSV file
                                </span>
                            </label>
                        </div>
                        </WalletTooltip>
                    </li>
                    <li onClick={handleFilterKeyChange("manualReceivers")} className={handleActiveClass("manualReceivers")}>
                        <span>
                            Set the receivers manually
                        </span>
                    </li>
                    <li onClick={handleFilterKeyChange("mySchedule")} className={handleActiveClass("mySchedule")}>
                        <span>
                            My Schedules
                        </span>
                    </li>
                    <li style={{ position: 'relative' }}>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            popperPlacement="bottom-end" // Adjust the popper placement
                            popperModifiers={{
                                offset: {
                                    enabled: true,
                                    offset: '0, 10px' // Adjust the offset as needed
                                }
                            }}
                        />
                    </li>
                </ul>

                {(uploadedData?.length > 0 || manualData.length > 1) && (
                    <div className="fugu--newsletter wow fadeInUpX" data-wow-delay=".20s">
                        <input type="default" placeholder="" />
                        <button type="submit" id="fugu--submit-btn">
                            Group name
                        </button>
                    </div>
                )}
            </div>
            {activeClass == "*" ? (
                uploadedData?.length > 0 && (
                    <table className='fugu-schedule-Table'>
                        <thead>
                            <tr>
                                {keys?.map((key) => (
                                    <th key={key} className='text-uppercase fugu-table-border'>
                                        {key}
                                    </th>
                                ))}
                                <th className='fugu-table-border'></th> {/* Add remove button header */}
                            </tr>
                        </thead>
                        <tbody>
                            {uploadedData?.map((item, index) => (
                                <tr key={index}>
                                    {keys?.map((key) => (
                                        <td key={key} className="p-1 fugu-table-border">{item[key]}</td>
                                    ))}
                                    <td className='fugu-table-border'>
                                        <button onClick={() => removeElement(index)} className='text-danger-500'>Remove</button> {/* Add remove button */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            ) : (
                // Manual Add section
                <table className='fugu-schedule-Table'>
                    <thead>
                        <tr>
                            <th className='text-uppercase fugu-table-border'>Name</th>
                            <th className='text-uppercase fugu-table-border'>Address</th>
                            <th className='text-uppercase fugu-table-border'>Amount</th>
                            <th className='text-uppercase fugu-table-border'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {manualData.map((item, index) => (
                            item.status == "Add" ? (

                                <tr key={index}>
                                    <td className='p-1 fugu-table-border'>
                                        <input
                                            type="text"
                                            value={item.name}
                                            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                        />
                                    </td>
                                    <td className='p-1 fugu-table-border'>
                                        <input
                                            type="text"
                                            value={item.address}
                                            onChange={(e) => handleInputChange(index, 'address', e.target.value)}
                                        />
                                    </td>
                                    <td className='p-1 fugu-table-border'>
                                        <input
                                            type="number"
                                            value={item.amount}
                                            onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
                                        />
                                    </td>
                                    <td className='fugu-table-border'>
                                        <button onClick={() => handleAddRow(index)} className='text-success-500'>
                                            Add
                                        </button>
                                    </td>
                                </tr>
                            ) : (
                                <tr key={index}>
                                    <td className='p-1 fugu-table-border'>
                                        {item.name}
                                    </td>
                                    <td className='p-1 fugu-table-border'>
                                        {item.address}
                                    </td>
                                    <td className='p-1 fugu-table-border'>
                                        {item.amount}
                                    </td>
                                    <td className='fugu-table-border'>
                                        <button onClick={() => handleRemoveRow(index)} className='text-danger-500'>
                                            Remove
                                        </button>
                                    </td>
                                </tr>)
                        ))}
                    </tbody>
                </table>

            )}

            {(uploadedData?.length > 0 || manualData.length > 1) && (
                <div>
                    <div className='d-flex mt-5'>
                        <div className="me-5">
                            <button id="fugu--form-submit-btn" type="submit">
                                Submit
                            </button>
                        </div>
                        <div>
                            <button className="fugu--outline-btn" onClick={() => { setUploadedData([]); setKeys([]) }}>
                                <span>Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default ScheduleTransaction