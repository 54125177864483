import Isotope from "isotope-layout";
import React, { useEffect, useRef, useState } from "react";
import Dropdown from "react-dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../components/web3/context/AuthContext";
import { getNfts } from "../../components/web3/utils/utils";
import nftPlaceHolder from "../../assets/images/all-img/v3/card9.jpg";
import { isValidEmail } from "../../helper/helperFunc";
import { toast } from "react-hot-toast";
import twitterLogo from "../../assets/images/social2/twitterx.svg";
import openseaLogo from "../../assets/images/social2/OpenSea.png";
import { TwitterShareButton } from "react-share";
import {
  depositNft_custodial,
  depositNft_nonCustodial,
  transferNft_custodial,
  transferNft_nonCustodial,
} from "../../components/web3/nfts-utils/nfts-proxy/NftsProxy_Utils";
import { openPasswordModel } from "../../components/social-wallet/PasswordModal";
import { transferSelectedMethod } from "../../components/layout/navbar/menu-data";
import phone from "phone";
import { isValidWalletAddress } from "../../helper/helperFunc";
import Preloader from "../../components/common/preloader/preloader";
import ReactGA from "react-ga4";
import axios from "axios";
function MyNfts() {
  const isotope = useRef();
  const [walletNfts, setWalletNfts] = useState([]);
  const [isBrokenImage, setIsBrokenImage] = useState([]);
  const {
    balance,
    network,
    entityInfo,
    hasPassword,
    loginOption,
    email,
    isLoading,
    isLoggedIn,
    setIsLoading,
  } = useAuth();
  const [showTransferInput, setShowTransferInput] = useState([]);
  const [emailInputs, setEmailInputs] = useState([]);
  const [userSelection, setUserSelection] = useState(transferSelectedMethod[0]);
  const [inputPlaceHolder, setInputPlaceHolder] = useState("Email");
  const [inputElement, setInputElement] = useState(); //this const is to save the input element in the state. to validate the input and update the ui
  const [selectedOption, setSelectedOption] = useState();
  const [shortUrls, setShortUrls] = useState([]);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/MyNfts",
      title: "My NFTS Page",
    });
    const initialShowTransferInput = Array(walletNfts.length).fill(false);
    const initialEmailInputs = Array(walletNfts.length).fill("");
    setShowTransferInput(initialShowTransferInput);
    setEmailInputs(initialEmailInputs);
  }, [walletNfts]);

  useEffect(() => {
    fetchNfts();
  }, [isLoggedIn, entityInfo, network?.chain]);

  useEffect(() => {
    //set email as a default selection
    if (transferSelectedMethod.length > 0) {
      setSelectedOption({
        value: transferSelectedMethod[0].icon,
        label: (
          <div className="dropdown-item">
            <img
              src={transferSelectedMethod[0].icon}
              alt={transferSelectedMethod[0].name}
              className="fugu-network-icons mx-2"
            />
          </div>
        ),
      });
    }
  }, []);

const shortenUrl = async (originalLink)=>{
  try{
    const response = await axios.post('https://api.short.io/links', {
      domain: 'g1g7.short.gy',
      originalURL: originalLink,
    }, {
      headers: {
        accept: 'application/json',
        authorization: 'sk_ozgzfGhBIpMreHkW', // Replace with your Short.io API key
        'content-type': 'application/json',
      },
    });

    return response.data.shortURL;
  }catch(e){
  return originalLink
  }
}


useEffect(() => {
  const generateShortUrls = async () => {
    const urls = await Promise.all(walletNfts.map(async (elem) => {
      const longUrl = `${process.env.REACT_APP_TEST_OPENSEA_URL}/${network?.chainName}/${elem.contractAddress}/${elem.tokenId}`;
      return await shortenUrl(longUrl);
    }));
    setShortUrls(urls);
  };

  if (walletNfts.length > 0) {
    generateShortUrls();
  }
}, [walletNfts, network])

  const fetchNfts = async () => {
    try {
      setIsLoading(true);
      const nfts = await getNfts(entityInfo?.activePublicKey, network.chain);
      nfts && setWalletNfts(nfts);
      setIsLoading(false);
    } catch (err) {
      console.log("Error on fetch user nfts");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (walletNfts) {
      initializeIsotope();
    }
  }, [walletNfts]);
  const initializeIsotope = () => {
    isotope.current = new Isotope("#fugu--four-column", {
      itemSelector: ".collection-grid-item",
      resizable: false,
      masonry: {
        columnWidth: ".collection-grid-item",
        gutterWidth: 0,
        isFitWidth: true,
      },
    });
  };

  const handleImageError = (index) => {
    setIsBrokenImage((prevBrokenImages) => {
      const updatedBrokenImages = [...prevBrokenImages];
      updatedBrokenImages[index] = true;
      return updatedBrokenImages;
    });
  };
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      dateStyle: "short",
      timeStyle: "short",
    };
    const formatted = date.toLocaleString(undefined, options);
    return formatted;
  };
  const handleTransferClick = (index) => {
    toggleElementIndex(true, index);

    setShowTransferInput((prevShowTransferInput) => {
      const updatedShowTransferInput = prevShowTransferInput.map((value, idx) =>
        idx === index ? true : false
      );
      return updatedShowTransferInput;
    });
  };
  const isValid = (input, dropdownSelection) => {
    if (!dropdownSelection) {
      return false;
    } else if (dropdownSelection.title == "Email") {
      return isValidEmail(input);
    } else if (dropdownSelection.title == "Phone") {
      return phone(input).isValid;
    } else if (dropdownSelection.title == "Wallet") {
      return isValidWalletAddress(input);
    }
    return false;
  };

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    const newInputElement = e.target;
    setInputElement(newInputElement);
    if (isValid(value, userSelection)) {
      newInputElement.style.color = "#fff";
    } else {
      newInputElement.style.color = "red";
    }
    setEmailInputs((prevEmailInputs) => {
      const updatedEmailInputs = [...prevEmailInputs];
      updatedEmailInputs[index] = value;
      return updatedEmailInputs;
    });
  };

  const handleTransferConfirmation = async (index) => {
    if (balance < 0.001)
      return toast.error("You don't have enough balance to transfer");
    const to = emailInputs[index];
    if (isValid(to, userSelection)) {
      setIsLoading(true);
      try {
        if (loginOption == "custodial") {
          if (hasPassword) {
            if (userSelection.title == "Wallet") {
              openPasswordModel(handleSocialTransfer, index, setIsLoading);
            } else {
              openPasswordModel(handleSocialDeposit, index, setIsLoading);
            }
          } else {
            if (userSelection.title == "Wallet") {
              handleSocialTransfer(null, index);
            } else {
              handleSocialDeposit(null, index);
            }
          }
        } else if (loginOption == "non_custodial") {
          setIsLoading(true);
          if (userSelection.title == "Wallet") {
            await transferNft_nonCustodial(
              entityInfo.activePublicKey,
              to,
              walletNfts[index],
              network
            );
            fetchNfts();
          } else {
            await depositNft_nonCustodial(to, walletNfts[index], network);
            fetchNfts();
          }
        }
      } catch (e) {
        setIsLoading(false);
        toast.error(e.message);
      }
      // setUserSelection(null);
    } else {
      let errMes = "";
      if (!userSelection && email == "") {
        errMes = "Select your receiver address and transfer type";
      } else if (!userSelection) {
        errMes = "Select transfer type";
      } else {
        errMes = `Invalid ${userSelection.title}`;
      }
      toast.error(errMes);
    }
  };

  const removeTransferredNft = (index) => {
    const newWalletNfts = walletNfts.filter((nft, idx) => idx !== index);
    setWalletNfts(newWalletNfts);
  };

  const handleSocialTransfer = async (password, index) => {
    if (hasPassword && password === "")
      return toast.error("Password is required");
    if (hasPassword && password == null) return;
    await transferNft_custodial(
      email,
      entityInfo.indexWallet,
      entityInfo.activePublicKey,
      emailInputs[index],
      walletNfts[index],
      network,
      password
    );
    fetchNfts();
  };

  const handleSocialDeposit = async (password, index) => {
    if (hasPassword && password === "")
      return toast.error("Password is required");
    if (hasPassword && password == null) return;
    console.log("selected nft", walletNfts[index]);
    await depositNft_custodial(
      email,
      entityInfo.indexWallet,
      emailInputs[index],
      walletNfts[index],
      network,
      password
    );
    fetchNfts();
  };

  // this func to change nft card style on click transfer or cancel btn to pop  the card or reset the style
  const toggleElementIndex = (display, index) => {
    if (isotope && isotope.current) {
      const gridItems = isotope.current.element.querySelectorAll(
        ".collection-grid-item"
      );
      gridItems.forEach((item, idx) => {
        if (idx === index) {
          item.style.zIndex = display ? "99" : "initial";
        } else {
          item.style.zIndex = "initial";
        }
      });
    }
    if (!display) {
      setShowTransferInput((prevShowTransferInput) => {
        const updatedShowTransferInput = prevShowTransferInput.map(
          (value, idx) => (idx === index ? false : value)
        );
        return updatedShowTransferInput;
      });
    }
    //reset transfer selection to the default way with email
    setInputPlaceHolder("Email");
    setUserSelection(transferSelectedMethod[0]);
    //reset the input value with index
    setEmailInputs((prevEmailInputs) => {
      const updatedEmailInputs = [...prevEmailInputs];
      updatedEmailInputs[index] = "";
      return updatedEmailInputs;
    });
  };

  const handleDropDownSelection = (e, index) => {
    setUserSelection(e);
    if (isValid(emailInputs[index], e)) {
      if (inputElement) inputElement.style.color = "#fff";
    } else {
      if (inputElement) inputElement.style.color = "red";
    }
    setInputPlaceHolder(e.title);
  };

  return (
    <div className="mt-5">
      <Preloader show={isLoading} />
      <div className="fugu--section-title">
        <div className="fugu--default-content content-sm">
          <h2>Explore your most unique NFTs</h2>
          <p>
            NFTs are usually associated with non-physical art but in reality,
            there are several different types of NFTs and are explained in this
            guide.
          </p>
        </div>
      </div>
      <div className="container">
        <div id="fugu--four-column" className="fugu--four-column">
          {walletNfts &&
            walletNfts.map((elem, index) => (
              <div
                className={`collection-grid-item sports domain m-2 ${
                  showTransferInput[index] ? "transfer-active" : ""
                }`}
                key={index}
              >
                <div className="fugu--card-wrap">
                  <div className="fugu--card-thumb">
                    <img
                      src={
                        elem.metadata == null
                          ? nftPlaceHolder
                          : elem.metadata.image
                      }
                      alt=""
                      onError={() => handleImageError(index)}
                    />
                  </div>
                  <div className="fugu--card-data">
                    <h3>{`${elem.name} - #${elem.tokenId}`}</h3>
                    {/* <p>{formatDate(elem.timeLastUpdated)}</p> */}
                    <div className="fugu--social-icon ">
                      <ul>
                        <li>
                        {shortUrls[index] ? (
                          <a>
                            <TwitterShareButton
                              url={shortUrls[index]}
                              title={
                                "🔥 Discover the gateway to a new digital era with my exclusive NFT on HorusWallet! 🌟 Immerse yourself in the captivating world of art and blockchain. 🎨✨ Join me on this journey today. 💫 #NFT #HorusWallet #DigitalArt"
                              }
                              via="HorusWallet"
                            >
                              <img src={twitterLogo} alt="" />
                            </TwitterShareButton>
                          </a>
                        ) : (
                          <div>Loading...</div>
                        )}
                        </li>
                        <li>
                          <a
                            href={`${process.env.REACT_APP_TEST_OPENSEA_URL}/${network.chainName}/${elem.contractAddress}/${elem.tokenId}`}
                            target="_blank"
                          >
                            <img src={openseaLogo} alt="" />
                          </a>
                        </li>
                        <li>
                          {showTransferInput[index] && (
                            <div className="col">
                              <Dropdown
                                options={transferSelectedMethod
                                  .filter((item) => !item.disabled)
                                  .map((item, optionIndex) => ({
                                    value: item,
                                    label: (
                                      <div
                                        key={optionIndex}
                                        className="dropdown-item"
                                      >
                                        <img
                                          src={item.icon}
                                          alt={item.name}
                                          className="fugu-network-icons mx-2"
                                        />
                                      </div>
                                    ),
                                  }))}
                                onChange={(e) =>
                                  handleDropDownSelection(e.value, index)
                                }
                                placeholder={"select"}
                                arrowClosed={
                                  <FontAwesomeIcon
                                    icon={faAngleDown}
                                    className="ms-2"
                                  />
                                }
                                arrowOpen={
                                  <FontAwesomeIcon
                                    icon={faAngleUp}
                                    className="ms-2"
                                  />
                                }
                                className="fugu-dropdown dropdown-control"
                                controlClassName="fugu-dropdown dropdown-control"
                                arrowClassName="dropdown-arrow"
                                placeholderClassName="dropdown-placeholder"
                                value={selectedOption}
                              />
                            </div>
                          )}
                        </li>
                      </ul>
                    </div>
                    <div className="fugu--card-footer">
                      {showTransferInput[index] ? (
                        <div className="d-block">
                          <div className="input-wrapper">
                            <input
                              type="default"
                              placeholder={inputPlaceHolder}
                              value={emailInputs[index]}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </div>
                          <div className="button-wrapper row">
                            <button
                              className="fugu--btn btn-sm bg-white col m-2"
                              onClick={() => handleTransferConfirmation(index)}
                            >
                              OK
                            </button>
                            <button
                              className="fugu--btn btn-sm bg-white col m-2"
                              onClick={() => toggleElementIndex(false, index)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          {elem.contractAddress !==
                            process.env.REACT_APP_QRNFT_CONTRACT_ADDRESS && (
                            <div className="row">
                              <div className="col">
                                <button
                                  className="fugu--btn btn-sm bg-white"
                                  onClick={() => handleTransferClick(index)}
                                >
                                  {showTransferInput[index] ? "OK" : "Transfer"}
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default MyNfts;
