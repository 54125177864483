import HORUS_SCHEDULER from "./Scheduler.json";
import HAMADA_APES from "./HamadaApes.json";

export const HORUS_SCHEDULER_CONTRACT_ADDRESS = {
  POLYGON: {
    TESTNET: "0x3ed31dC05591841114DbC8815002fd055EcF273B",
    MAINNET: "",
  },
  ETHEREUM: {
    TESTNET: "0x654eDD3d8c9627e1b33A4D580a38Bd13E2067F9C",
    MAINNET: "",
  },
};

export const HORUS_SCHEDULER_ABI = HORUS_SCHEDULER.abi;
export const HAMADA_APES_ABI = HAMADA_APES.abi;
