import React from "react";
import { useNavigate } from "react-router-dom";
import HeroSection from "../../components/landing-home/hero-section";
import { useAuth } from "../../components/web3/context/AuthContext";
import { useAccount } from "wagmi";
import ReactGA from 'react-ga4';
import Livechat from "../../components/live-chat/liveChat";
import Preloader from "../../components/common/preloader/preloader";
export default function IndexThree() {
  const { isLoading, isLoggedIn } = useAuth();
  const { address, isConnected } = useAccount();
  const navigate = useNavigate();
  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/Home', title: 'Home US Page' });
    isLoggedIn && navigate("/transferDashboard");
  }, [isConnected, address]);
  return (
    <>
    <Preloader show={isLoading}/>
      <HeroSection />
      <Livechat />
    </>
  );
}
export async function getStaticProps() {
  return { props: { header: "three", footer: "three" } };
}
