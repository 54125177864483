import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import '../../../../assets/styles/css/_drag-drop-list.scss';
import CollectionTraits from './collection-traits';

const OptionsController = ({ setLayerProps, isDisabled }) => {
  const [items, setItems] = useState([]);

  const [draggingItem, setDraggingItem] = useState(null);
  const [newItemName, setNewItemName] = useState('');
  const [selectedItem, setSelectedItem] = useState();
  const [layerWithTraits, setLayerWithTraits] = useState([]);
  const [addedTraits, setAddedTraits] = useState([]);
  const [isNameExist, setIsNameExist] = useState(false);
  const [isProbsValid, setIsProbsValid] = useState(true);

  useEffect(() => {
    setLayerProps && setLayerProps(items[0]);
    setSelectedItem(items[0]);
  }, [setLayerProps]);

  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', '');
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e, targetItem) => {
    const currentIndex = items.indexOf(draggingItem);
    const targetIndex = items.indexOf(targetItem);
    const newItems = [...items];
    if (currentIndex !== -1 && targetIndex !== -1) {
      newItems.splice(currentIndex, 1);
      newItems.splice(targetIndex, 0, draggingItem);
      const allnewItems = newItems.map((item, index) => ({ ...item, index }));
      setItems(allnewItems);
      let layersData = [];
      layerWithTraits.forEach((item) => {
        let newItem = allnewItems.find(({ name }) => name === item.layer.name);
        const sumOfProbs = item.traits
          .map((obj) => Number(obj.traitProb))
          .reduce((partialSum, a) => partialSum + a, 0);
        layersData.push({
          layer: { ...newItem, index: newItem.index },
          traits: item.traits,
          sumOfProbs: sumOfProbs == 100 ? true : false,
        });
      });
      setLayerWithTraits(layersData);
      setLayerProps(layersData);
    }
  };

  const handleNameChange = (e) => {
    setNewItemName(e.target.value);
  };

  const addNewItem = () => {
    const isNameExist = items?.findIndex(({ name }) => name === newItemName);
    if (isNameExist != -1) {
      setIsNameExist(true);
      return;
    }
    setIsNameExist(false);
    const newItemId =
      items.length == 0 ? 1 : Math.max(...items.map((item) => item.id)) + 1;
    const newItem = {
      id: newItemId,
      name: newItemName,
      index: items.length,
    };

    setLayerWithTraits([
      ...layerWithTraits,
      { layer: newItem, traits: [], sumOfProbs: false },
    ]);
    setLayerProps([
      ...layerWithTraits,
      { layer: newItem, traits: [], sumOfProbs: false },
    ]);
    setItems([...items, newItem]);
    if (items.length === 0) {
      setSelectedItem(newItem);
    }
    setNewItemName('');
  };

  const handleItemClick = (item) => {
    const selectedIndex = items.findIndex((i) => i.name === item.name);
    setSelectedItem(item);
    const selectedTraits = layerWithTraits?.find(
      (item) => item.layer.index === selectedIndex
    ).traits;
    setAddedTraits(selectedTraits);
    const sumOfProbs = selectedTraits
      .map((obj) => Number(obj.traitProb))
      .reduce((partialSum, a) => partialSum + a, 0);
    setIsProbsValid(sumOfProbs == 100 ? true : false);
  };
  const passTraits = (traits) => {
    const resIndex = layerWithTraits.findIndex(
      (item) => item.layer.index === selectedItem.index
    );
    const sumOfProbs = traits
      .map((obj) => Number(obj.traitProb))
      .reduce((partialSum, a) => partialSum + a, 0);
    if (resIndex > -1) {
      layerWithTraits?.splice(resIndex, 1);

      setLayerWithTraits([
        ...layerWithTraits?.slice(0, resIndex),
        {
          layer: selectedItem,
          traits,
          sumOfProbs: sumOfProbs == 100 ? true : false,
        },
        ...layerWithTraits?.slice(resIndex),
      ]);
      setLayerProps([
        ...layerWithTraits?.slice(0, resIndex),
        {
          layer: selectedItem,
          traits,
          sumOfProbs: sumOfProbs == 100 ? true : false,
        },
        ...layerWithTraits?.slice(resIndex),
      ]);
      setIsProbsValid(sumOfProbs == 100 ? true : false);
    } else {
      setLayerWithTraits([
        ...layerWithTraits,
        {
          layer: selectedItem,
          traits,
          sumOfProbs: sumOfProbs == 100 ? true : false,
        },
      ]);
      setLayerProps([
        ...layerWithTraits,
        {
          layer: selectedItem,
          traits,
          sumOfProbs: sumOfProbs == 100 ? true : false,
        },
      ]);
    }
  };
  return (
    <div className="row sortable-list mb-5">
      <div className="col-lg-6">
        <div className="sortable-list">
          <div className="text-white">Options</div>
          {items.map((item, index) => (
            <div
              key={item.id}
              className={`item ${item === draggingItem ? 'dragging' : ''} ${
                item === selectedItem ? 'selected' : ''
              }`}
              draggable="true"
              onDragStart={(e) => handleDragStart(e, item)}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, item)}
              onClick={() => handleItemClick(item)}
              isDisabled={isDisabled}
            >
              <div className="details">
                <FontAwesomeIcon
                  icon={faGripVertical}
                  className={`${
                    item === selectedItem ? 'text-black' : 'text-white'
                  }`}
                />
                <span className="ms-2">{item.name}</span>
              </div>
            </div>
          ))}
          <div className="new-item">
            <input
              type="text"
              placeholder="Name"
              value={newItemName}
              onChange={handleNameChange}
              className="input-field mt-4 mb-2 text-white"
            />
            {isNameExist && (
              <span className="text-danger">Name is already exist</span>
            )}
            <button
              onClick={addNewItem}
              disabled={newItemName === '' || isDisabled}
              className="fugu--outline-btn w-100 mt-2"
            >
              <span>Add New Item</span>
            </button>
          </div>
        </div>
      </div>
      {selectedItem && (
        <div className="col-lg-6">
          <CollectionTraits
            passTraits={passTraits}
            traitsOfSelectedLayer={addedTraits}
            isProbsValid={isProbsValid}
          />
        </div>
      )}
    </div>
  );
};

export default OptionsController;
