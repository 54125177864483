import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/web3/context/AuthContext';
import TokensExchange from '../../components/dashboard-controler/tokens-exchange';
import defaultBg from '../../assets/images/all-img/v3/dashboardBg.jpg';
import nftsBg from '../../assets/images/all-img/v3/hero-thumb2.jpg';
import collectionsBg from '../../assets/images/all-img/v3/hero-thumb3.jpg';
import claimTokensBg from '../../assets/images/all-img/v3/hero-thumb4.jpg';
import claimNftsBg from '../../assets/images/all-img/v3/hero-thumb5.jpg';
import createCollection from '../../assets/images/all-img/v3/createCollection.jpg';
import MyNfts from '../nft-gallery/my-nft';
import CollectionManager from '../../components/collections-manager/create-collection/collection-manager';
import ClaimNfts from '../nft-gallery/claim-nft';
import MySchedules from '../schedule-transaction/mySchedules';
import ClaimFund from '../claim-fund/claim-fund';
import ReactGA from 'react-ga4';
import MyCollectionsLibrary from '../../components/collections-manager/my-collections-library';
import CollectionCreationManager from '../../components/collections-manager/create-collection/collection-creation-manager';
import { useLocation } from 'react-router-dom';
import { FetchUsersNewBenefits } from '../../helper/fetchNewBenfitsHandler';
import CongratsModal from '../../components/custom-modal/congratsBenefitsModal';
import Preloader from '../../components/common/preloader/preloader';
const TransferDashboard = () => {
  const IMGS_BG = {
    Tokens: defaultBg,
    NFTs: nftsBg,
    Collections: collectionsBg,
    Claim_Tokens: claimTokensBg,
    Claim_NFTs: claimNftsBg,
    Schedules: defaultBg,
    CreateNewCollection: createCollection,
  };
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const selectedTab = queryParams.get('selectedTab');
  const { isLoggedIn, loginOption, network, supportedOptions, email, entityInfo, isLoading } = useAuth();
  const [filterKey, setFilterKey] = React.useState();
  const [activeClass, setActiveClass] = React.useState();
  const [backgroundIm, setBackgroundIm] = useState(defaultBg);
  const [showBenefitsModal, setShowBenefitsModal] = useState(false);
  const [userBenefits, setUserBenefits] = useState()
  const handleFilterKeyChange = (key) => {
    setFilterKey(key);
    setActiveClass(key);
    setBackgroundIm(IMGS_BG[key]);
    removeSelectedTab()
  };

  const handleActiveClass = (key) => {
    if (key === activeClass) return 'active';
  };
  useEffect(() => {
    const value = selectedTab;
    if (!value || value == "") return
    handleFilterKeyChange(value);
  }, [selectedTab]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/TransferDashboard',
      title: 'Transfer Dashboard Page',
    });
  }, []);

  useEffect(() => {
    const defaultKey = selectedTab ? selectedTab : 'Tokens';
    function updateStatesByKey(key) {
      setFilterKey(key);
      setActiveClass(key);
      setBackgroundIm(IMGS_BG[key]);
    }

    function checkAndUpdateFilter(currentFilter, newSupportedOptions) {
      const optionsMap = {
        Tokens: 'Tokens',
        NFTs: 'NFTs',
        Collections: 'Collections',
        Claim_Tokens: 'Claim_Tokens',
        Claim_NFTs: 'Claim_NFTs',
        Schedules: 'Schedules',
      };

      if (newSupportedOptions[currentFilter]) {
        updateStatesByKey(optionsMap[currentFilter]);
      } else {
        updateStatesByKey(defaultKey);
      }
    }

    if (supportedOptions) {
      checkAndUpdateFilter(filterKey, supportedOptions);
    } else {
      updateStatesByKey(defaultKey);
    }
  }, [supportedOptions]);
  useEffect(() => {
    fetchBenefits(email)
  }, [isLoggedIn, entityInfo])
  const fetchBenefits = async () => {
    if (loginOption && isLoggedIn && entityInfo && loginOption == "custodial") {
      const benefits = await FetchUsersNewBenefits(email, loginOption)
      if (!benefits) return;
      setUserBenefits(benefits)
      if (benefits.showMeAgain) {
        setShowBenefitsModal(true)
      }
    }
  }
  const transparentBackground = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundIm})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
  function removeSelectedTab() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('selectedTab');
    const newUrl = window.location.pathname + '?' + urlParams.toString();
    window.history.replaceState({}, '', newUrl);
  }
  return (
    <div
      className="fugu--dashboard-section "
      style={{
        backgroundImage: `url(${backgroundIm})`,
        backgroundSize: `${activeClass == 'Claim_NFTs' ? "contain" : "cover"}`,
        backgroundRepeat: `${activeClass == 'Claim_NFTs' ? "initial" : 'no-repeat'}`,
      }}
    >
      {isLoggedIn ? (
        <>
          <div className="fugu--portfolio-menu">
            <ul className="option-set clear-both mx-3 ps-0">
              {supportedOptions?.Tokens && (
                <li
                  onClick={() => handleFilterKeyChange('Tokens')}
                  className={handleActiveClass('Tokens')}
                >
                  <span>Tokens</span>
                </li>
              )}
              {supportedOptions?.NFTs && (
                <li
                  onClick={() => handleFilterKeyChange('NFTs')}
                  className={handleActiveClass('NFTs')}
                >
                  <span>NFTs</span>
                </li>
              )}
              {supportedOptions?.Collections && (
                <li
                  onClick={() => handleFilterKeyChange('Collections')}
                  className={handleActiveClass('Collections')}
                >
                  <span>My Collections</span>
                </li>
              )}
              {supportedOptions?.Collections && (
                <li
                  onClick={() => handleFilterKeyChange('CreateNewCollection')}
                  className={handleActiveClass('CreateNewCollection')}
                >
                  <span>Create New Collection</span>
                </li>
              )}
              {supportedOptions?.Claim_Tokens && (
                <li
                  onClick={() => handleFilterKeyChange('Claim_Tokens')}
                  className={handleActiveClass('Claim_Tokens')}
                >
                  <span>Claim Tokens</span>
                </li>
              )}
              {supportedOptions?.Claim_NFTs && (
                <li
                  onClick={() => handleFilterKeyChange('Claim_NFTs')}
                  className={handleActiveClass('Claim_NFTs')}
                >
                  <span>Claim NFTs</span>
                </li>
              )}
              {supportedOptions?.Schedules && (
                <li
                  onClick={() => handleFilterKeyChange('Schedules')}
                  className={handleActiveClass('Schedules')}
                >
                  <span>Schedules</span>
                </li>
              )}
            </ul>
          </div>
          {activeClass == 'Tokens' ? (
            <TokensExchange />
          ) : activeClass == 'NFTs' ? (
            <MyNfts />
          ) : activeClass == 'Collections' ? (
            <MyCollectionsLibrary />
          ) : activeClass == 'CreateNewCollection' ? (
            <CollectionCreationManager />
          ) : activeClass == 'Claim_Tokens' ? (
            <ClaimFund />
          ) : //claim nfts feature support social login and evm  wallets only
            activeClass == 'Claim_NFTs' && loginOption == 'custodial' ? (
              <ClaimNfts />
            ) : //my schedules supported in custodial "EVM ONLY" and noneCustodial
              activeClass == 'Schedules' ? (
                <MySchedules />
              ) : (
                <></>
              )}
          <CongratsModal
            show={showBenefitsModal}
            handleCloseParent={() => setShowBenefitsModal(!showBenefitsModal)}
            benefits={userBenefits}
          />
        </>
      ) : (
        <>
        <Preloader show={isLoading} />
          <div className="d-flex justify-content-center">
            <div className="header-btn">
              <a className="fugu--btn fugu--menu-btn1" href="/">
                Connect with system
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default TransferDashboard;
