import {
  CasperClient,
  CLKeyParameters,
  CLKey,
  CLMap,
  CLPublicKey,
  CLStringType,
  CLTypeTag,
  CLValue,
  CLValueBuilder,
  CLValueParsers,
  Contracts,
  decodeBase16,
  encodeBase16,
  Keys,
  RuntimeArgs,
} from 'casper-js-sdk';
import { None, Some } from 'ts-results';
import { concat } from '@ethersproject/bytes';
import blake from 'blakejs';

import { signDeploy } from './signer';
import {
  CONNECTION,
  VAULT_CONTRACT_HASH,
  VAULT_PACKAGE_HASH,
} from './constants/blockchain';
import { PAYMENT_AMOUNTS } from './constants/paymentAmounts';
import { Buffer } from 'buffer';
const { NODE_ADDRESS, CHAIN_NAME } = CONNECTION;
const { Contract, fromCLMap } = Contracts;
type RecipientType = CLKey;

export interface VaultInstallArgs {
  feeWallet: RecipientType;
  contractName: string;
  contractPackageHash?: string;
}

export enum VaultEvents {
  DepositCreated = 'DepositCreated',
  DepositClaimed = 'DepositClaimed',
}

export const VaultEventParser = (
  {
    contractPackageHash,
    eventNames,
  }: { contractPackageHash: string; eventNames: string[] },
  value: any
) => {
  if (value.body.DeployProcessed.execution_result.Success) {
    const { transforms } =
      value.body.DeployProcessed.execution_result.Success.effect;

    const vaultEvents = transforms.reduce((acc: any, val: any) => {
      if (
        // eslint-disable-next-line no-prototype-builtins
        val.transform.hasOwnProperty('WriteCLValue') &&
        typeof val.transform.WriteCLValue.parsed === 'object' &&
        val.transform.WriteCLValue.parsed !== null
      ) {
        const maybeCLValue = CLValueParsers.fromJSON(
          val.transform.WriteCLValue
        );
        const clValue = maybeCLValue.unwrap();
        if (clValue && clValue.clType().tag === CLTypeTag.Map) {
          const hash = (clValue as CLMap<CLValue, CLValue>).get(
            CLValueBuilder.string('contract_package_hash')
          );
          const preferContractPackageHash = contractPackageHash.startsWith(
            'hash-'
          )
            ? contractPackageHash.slice(5).toLowerCase()
            : contractPackageHash.toLowerCase();
          const event = (clValue as CLMap<CLValue, CLValue>).get(
            CLValueBuilder.string('event_type')
          );
          if (
            hash &&
            // NOTE: Calling toLowerCase() because current JS-SDK doesn't support checksumed hashes and returns all lower case value
            // Remove it after updating SDK
            hash.value() === preferContractPackageHash &&
            event &&
            eventNames.includes(event.value())
          ) {
            acc = [
              ...acc,
              {
                name: event.value(),
                clValue,
                deployHash: value.body.DeployProcessed.deploy_hash,
              },
            ];
          }
        }
      }

      return acc;
    }, []);

    return { error: null, success: !!vaultEvents.length, data: vaultEvents };
  }

  return null;
};

const keyAndValueToHex = (key: CLValue, value: CLValue) => {
  const aBytes = CLValueParsers.toBytes(key).unwrap();
  const bBytes = CLValueParsers.toBytes(value).unwrap();

  const blaked = blake.blake2b(concat([aBytes, bBytes]), undefined, 32);
  const hex = Buffer.from(blaked).toString('hex');

  return hex;
};

export const fromMotes = (amt: number) => {
  return amt / 1000000000;
};

export const toMotes = (amt: number) => {
  return amt * 1000000000;
};

const getPreDepositBinary = async () => {
  return fetch(`${process.env.PUBLIC_URL}/build-wasm/pre_deposit_cspr.wasm`, {
    headers: {
      'Content-Type': 'application/wasm',
    },
  })
    .then((response) => response.arrayBuffer())
    .then((bytes) => new Uint8Array(bytes));
};

class VaultClient {
  casperClient: CasperClient;
  contractClient: Contracts.Contract;
  networkName: string;

  constructor() {
    this.casperClient = new CasperClient(NODE_ADDRESS!);
    this.contractClient = new Contract(this.casperClient);
    this.networkName = CHAIN_NAME;
    console.log(VAULT_CONTRACT_HASH!);
    console.log(VAULT_PACKAGE_HASH);

    this.contractClient.setContractHash(
      VAULT_CONTRACT_HASH!,
      'hash-' + VAULT_PACKAGE_HASH
    );
  }

  public install(
    wasm: Uint8Array,
    args: VaultInstallArgs,
    paymentAmount: string,
    deploySender: CLPublicKey,
    keys?: Keys.AsymmetricKey[]
  ) {
    const runtimeArgs = RuntimeArgs.fromMap({
      fee_wallet: args.feeWallet,
      fee_percentage: CLValueBuilder.u32(100),
      contract_name: CLValueBuilder.string(args.contractName),
      contract_package_hash: args.contractPackageHash
        ? CLValueBuilder.option(
            Some(CLValueBuilder.string(args.contractPackageHash))
          )
        : CLValueBuilder.option(None, new CLStringType()),
    });

    return this.contractClient.install(
      wasm,
      runtimeArgs,
      paymentAmount,
      deploySender,
      this.networkName,
      keys || []
    );
  }

  public setContractHash(contractHash: string, contractPackageHash?: string) {
    this.contractClient.setContractHash(contractHash, contractPackageHash);
  }

  public async balanceOf(account: CLPublicKey) {
    const result = await this.contractClient.queryContractDictionary(
      'balances',
      account.toAccountHashStr().slice(13)
    );

    const maybeValue = result.value().unwrap();

    return maybeValue.value().toString();
  }

  public async setFeeWallet(feeWallet: string, deploySender: CLPublicKey) {
    const runtimeArgs = RuntimeArgs.fromMap({
      fee_wallet: new CLKey(CLPublicKey.fromHex(feeWallet)),
    });
    const setWalletDeploy = this.contractClient.callEntrypoint(
      'set_fee_wallet',
      runtimeArgs,
      deploySender,
      this.networkName,
      PAYMENT_AMOUNTS.DEFAULT_ENTRYPOINT_PAYMENT_AMOUNT
    );

    const signedSetWalletDeploy = await signDeploy(
      setWalletDeploy,
      deploySender
    );
    console.log('Signed SetWallet deploy:', signedSetWalletDeploy);

    const setWalletDeployHash = await signedSetWalletDeploy.send(
      CONNECTION.NODE_ADDRESS
    );
    console.log('Deploy hash', setWalletDeployHash);
    return setWalletDeployHash;
  }

  public async depositCSPR(
    runtimeArgs: RuntimeArgs,
    deploySender: CLPublicKey
  ) {
    const preDepositBinary = await getPreDepositBinary();

    try {
      const deploy = await this.contractClient.install(
        preDepositBinary,
        runtimeArgs,
        PAYMENT_AMOUNTS.DEPOSIT_CSPR_ENTRYPOINT_PAYMENT_AMOUNT!,
        deploySender,
        CHAIN_NAME!
      );

      return deploy;
    } catch (error: any) {
      console.log(error);
    }
  }

  public async createDeposits(
    amount: number,
    emailIds: string[],
    deploySender: CLPublicKey,
    payToken?: string
  ) {
    let depositDeploy;
    const cspr_contract_hash = `contract-${encodeBase16(
      new Uint8Array(32).fill(0)
    )}`;
    const runtimeArgs = RuntimeArgs.fromMap({
      amount: CLValueBuilder.u256(toMotes(amount)),
      email_ids: CLValueBuilder.list(
        emailIds.map((id) => CLValueBuilder.string(id))
      ),
    });

    if (payToken) {
      runtimeArgs.insert('pay_token', CLValueBuilder.string(payToken!));

      depositDeploy = this.contractClient.callEntrypoint(
        'create_deposits',
        runtimeArgs,
        deploySender,
        this.networkName,
        PAYMENT_AMOUNTS.DEPOSIT_ENTRYPOINT_PAYMENT_AMOUNT
      );
    } else {
      runtimeArgs.insert(
        'pay_token',
        CLValueBuilder.string(cspr_contract_hash)
      );
      runtimeArgs.insert(
        'vault_contract',
        CLValueBuilder.string(`contract-${VAULT_CONTRACT_HASH!}`)
      );
      runtimeArgs.insert(
        'entrypoint',
        CLValueBuilder.string('create_deposits')
      );

      depositDeploy = await this.depositCSPR(runtimeArgs, deploySender);
    }

    const signedDepositDeploy = await signDeploy(depositDeploy!, deploySender);
    console.log('Signed Deposit deploy:', signedDepositDeploy);

    const depositDeployHash = await signedDepositDeploy.send(
      CONNECTION.NODE_ADDRESS
    );
    console.log('Deploy hash', depositDeployHash);

    return depositDeployHash;
  }

  public async claimDeposit(
    receiver: CLPublicKey,
    amount: number,
    emailId: string,
    deploySender: CLPublicKey,
    payToken?: string
  ) {
    const runtimeArgs = RuntimeArgs.fromMap({
      receiver: new CLKey(receiver),
      amount: CLValueBuilder.u256(toMotes(amount)),
      email: CLValueBuilder.string(emailId),
    });
    if (payToken) {
      runtimeArgs.insert('pay_token', CLValueBuilder.string(payToken!));
    } else {
      const cspr_contract_hash = `contract-${encodeBase16(
        new Uint8Array(32).fill(0)
      )}`;
      runtimeArgs.insert(
        'pay_token',
        CLValueBuilder.string(cspr_contract_hash)
      );
    }

    const claimDeploy = this.contractClient.callEntrypoint(
      'claim_deposit',
      runtimeArgs,
      deploySender,
      this.networkName,
      PAYMENT_AMOUNTS.DEPOSIT_ENTRYPOINT_PAYMENT_AMOUNT
    );

    const signedClaimDeploy = await signDeploy(claimDeploy, deploySender);
    console.log('Signed Claim deploy:', signedClaimDeploy);

    const claimDeployHash = await signedClaimDeploy.send(
      CONNECTION.NODE_ADDRESS
    );
    console.log('Deploy hash', claimDeployHash);
    return claimDeployHash;
  }

  public async feeWallet() {
    const result = (await this.contractClient.queryContractData([
      'fee_wallet',
    ])) as CLValue;

    return encodeBase16(result.value());
  }

  public async getDepositAddresses(emailId: string) {
    const result: any = await this.contractClient.queryContractData([emailId]);

    const mappedAddresses = result?.map(
      (address: any) => encodeBase16(address.data)
      // (address: any) => `contract-${encodeBase16(address.data)}`
    );
    return mappedAddresses;
  }

  public async getDeposit(emailId: string, payToken?: string) {
    const hex = keyAndValueToHex(
      CLValueBuilder.key(
        CLValueBuilder.byteArray(
          payToken ? decodeBase16(payToken!) : new Uint8Array(32).fill(0)
        )
      ),
      CLValueBuilder.string(emailId)
    );

    try {
      const result = await this.contractClient.queryContractDictionary(
        'deposits_list',
        hex
      );

      const maybeValue = result.value().unwrap().value();
      const jsMap: any = new Map();
      Array.from(fromCLMap(maybeValue).entries()).map(([key, val]: any) =>
        jsMap.set(key, val)
      );
      return fromMotes(Object.fromEntries(jsMap).amount);
    } catch (err: any) {
      if (err.message.includes('Query failed')) {
        return 0;
      } else {
        throw new Error(err);
      }
    }
  }
}

export const vaultClient = new VaultClient();
