import { useState, useEffect } from 'react';
import { Modal, CloseButton } from 'react-bootstrap';
import toast from 'react-hot-toast';
import {
  ConfirmGeneratingCollection,
  RegenerateSpecificAIImage,
  createLayeredAIPreviewImages,
  getImageCreationStatus,
} from '../../../web3/nfts-utils/nfts-factory/HorusFactory_Utils';
import Preloader from '../../../common/preloader/preloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useAuth } from '../../../web3/context/AuthContext';
function PreviewCollectionModalModal({
  show,
  handleCloseParent,
  layerInfo,
  setGeneratedURI,
}) {
  const [images, setImages] = useState();
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uniqueKey, setUniqueKey] = useState();
  const [resultCalledFirstTime, setResultCalledFirstTime] = useState(false);
  const [updatingProgress, setUpdatingProgress] = useState(false);
  const { entityInfo } = useAuth();
  const handleClose = () => {
    handleCloseParent();
  };
  const getImages = async () => {
    try {
      setLoader(true);
      const result = await createLayeredAIPreviewImages(layerInfo);
      if (result.status == 1) {
        setUniqueKey(result.result.id);
        const ApiData = {
          id: result.result.id,
          userAddress: entityInfo.activePublicKey,
        };
        const response = result && (await getImageCreationStatus(ApiData));
        setResultCalledFirstTime(true);
        setProgress(response.progress);
      }
    } catch (e) {
      setLoader(false);
      toast.error(e.response.data.message);
    }
  };

  const updateProgress = async () => {
    try {
      if (!updatingProgress) {
        setUpdatingProgress(true);
        const ApiData = {
          id: uniqueKey,
          userAddress: entityInfo.activePublicKey,
        };
        const result =
          resultCalledFirstTime && (await getImageCreationStatus(ApiData));
        if (result.status == 1) {
          if (progress <= 100) {
            setProgress(result.progress);
            if (result.progress == 100) {
              setImages(result.result);
              setLoader(false);
            }
          }
        }
        setUpdatingProgress(false);
      }
    } catch (e) {
      toast.error(e.response.data.message);
      setLoader(false);
    }
  };
  useEffect(() => {
    if (progress < 100 && resultCalledFirstTime) {
      const timer = setTimeout(() => {
        updateProgress();
      }, 5000); // Call updateProgress every 5 seconds

      // Cleanup function to clear the timer when the component unmounts or progress reaches 100%
      return () => clearTimeout(timer);
    }
  }, [progress, resultCalledFirstTime, updatingProgress]); // Re-run effect when progress changes
  useEffect(() => {
    getImages();
  }, []);
  const handleRegenerateImage = async (index) => {
    try {
      setLoader(true);
      const ImageInfo = {
        userAddress: entityInfo?.activePublicKey,
        uniqueKey: uniqueKey,
        imageIndex: index,
      };

      const newImage = await RegenerateSpecificAIImage(ImageInfo);
      if (newImage.status == 1) {
        const updatedItems = [...images];
        // Modify the item at the specified index
        updatedItems[index] = `${newImage.result}?v=${Date.now()}`;
        // Update the state with the modified array
        setImages([]);
        setImages(updatedItems);
        setLoader(false);
      }
    } catch (e) {
      toast.error(e.response.data.message);
      setLoader(false);
    }
  };
  const handleConfirmCollection = async () => {
    try {
      setLoader(true);
      const collectionInfo = {
        id: uniqueKey,
        userAddress: entityInfo?.activePublicKey,
      };

      const response = await ConfirmGeneratingCollection(collectionInfo);
      if (response.status == 1) {
        setGeneratedURI(response.result.baseUri);
        setLoader(false);
        handleClose();
      }
    } catch (e) {
      toast.error(e.response.data.message);
      setLoader(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="password-modal"
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className=" mt-2 text-center"
        >
          Preview Collection NFTs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" min-h-screen">
          <Preloader show={loader} />
          <ProgressBar now={progress} label={`${progress}%`} className="mb-5" />
          <div className="row">
            {images &&
              images.map((url, index) => (
                <div className="col-lg-3 col-md-2 col-sm-12" key={index}>
                  <div className=" position-relative d-inline-block">
                    <img src={url} alt="nft" className="nft-preview-image" />{' '}
                    <FontAwesomeIcon
                      size="xl"
                      icon={faRefresh}
                      className="position-absolute refresh-ai-icon"
                      onClick={() => handleRegenerateImage(index)}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal.Body>
      <CloseButton
        onClick={handleClose}
        style={{ position: 'absolute', right: '20px', top: '20px' }}
      />

      <Modal.Footer className="justify-content-around">
        <button
          className="fugu--outline-btn"
          disabled={images == undefined}
          onClick={() => {
            handleConfirmCollection();
          }}
        >
          <span>Confirm</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default PreviewCollectionModalModal;
