import { ethers } from "ethers";
import polygonIcon from "../../../assets/images/networks-icons/polygon.png";
import binanceIcon from "../../../assets/images/networks-icons/binance.png";
import ethIcon from "../../../assets/images/networks-icons/eth.png";
import flareIcon from "../../../assets/images/networks-icons/flare.png";
import telosIcon from "../../../assets/images/networks-icons/telos.png";
import baseIcon from "../../../assets/images/networks-icons/base.png";
import { baseSepolia, base, mainnet, sepolia, polygon } from "viem/chains";
const networkType = process.env.REACT_APP_NETWORK_TYPE;

export const RPC_NODES = {
  ETHEREUM: {
    TESTNET: "https://rpc.sepolia.org/",
    MAINNET: "https://mainnet.infura.io/v3/853c7c382bc74e69ac0540c9792ceec4",
  },
  BSC: {
    TESTNET: "https://data-seed-prebsc-1-s3.binance.org:8545",
    MAINNET: "https://bsc-dataseed.binance.org/",
  },
  POLYGON: {
    TESTNET:
      "https://rpc.ankr.com/polygon_amoy/b41db2216dd6dbbe201da8a228de7a50d33be808ac02c48a77b8ea34d8005399",
    // TESTNET:  "https://rpc-mumbai.maticvigil.com",
    MAINNET: "https://polygon-rpc.com",
  },
  TELOS: {
    TESTNET: "https://testnet.telos.net/evm",
    MAINNET: "https://mainnet.telos.net/evm",
  },
  FLARE: {
    TESTNET: "https://coston-api.flare.network/ext/bc/C/rpc",
    MAINNET: "https://flare-api.flare.network/ext/C/rpc",
  },
  BASE: {
    TESTNET:
      "https://rpc.ankr.com/base_sepolia/b41db2216dd6dbbe201da8a228de7a50d33be808ac02c48a77b8ea34d8005399",
    MAINNET:
      "https://rpc.ankr.com/base/b41db2216dd6dbbe201da8a228de7a50d33be808ac02c48a77b8ea34d8005399",
  },
};

export const CHAIN_ID_NETWORK = {
  8453: "BASE",
  84532: "BASE",
};

export const CHAIN_ID_NETWORK_INSTACNE = {
  84532: baseSepolia,
  8453: base,
  1: mainnet,
  11155111: sepolia,
  137: polygon,
  // 80002: amoy
};

export const CHAIN_SCAN = {
  ETHEREUM: {
    TESTNET: "https://sepolia.etherscan.io/",
    MAINNET: "https://etherscan.io/",
  },
  BSC: {
    TESTNET: "https://testnet.bscscan.com/",
    MAINNET: "https://bscscan.com/",
  },
  POLYGON: {
    TESTNET: "https://amoy.polygonscan.com/",
    MAINNET: "https://polygonscan.com/",
  },
  TELOS: {
    TESTNET: "https://testnet.teloscan.io/",
    MAINNET: "https://www.teloscan.io/",
  },
  FLARE: {
    TESTNET: "https://coston-explorer.flare.network/",
    MAINNET: "https://flarescan.com/",
  },
  CASPER: {
    TESTNET: "https://testnet.cspr.live/",
    MAINNET: "https://cspr.live/",
  },
  BASE: {
    TESTNET: "https://sepolia.basescan.org/",
    MAINNET: "https://basescan.org/",
  },
};

export const getEthersProvider = (network) => {
  if (!network) {
    throw new Error("Invalid network");
  }

  if (network === "NON_CUSTODIAL") {
    return new ethers.providers.Web3Provider(window.ethereum);
  }

  const options = RPC_NODES[network];
  const networkType = process.env.REACT_APP_NETWORK_TYPE;

  if (!options || !options[networkType]) {
    throw new Error("Invalid network");
  }

  return new ethers.providers.JsonRpcProvider(options[networkType]);
};

export const getSupportedOption = (network, loginOption) => {
  if (!network || !loginOption) {
    return null;
  }
  const options = SUPPORTED_OPTIONS[network];
  if (!options) {
    return null;
  }
  return options[loginOption];
};

export const SUPPORTED_OPTIONS = {
  ETHEREUM: {
    custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: true,
    },
  },
  BSC: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: true,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: true,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: true,
    },
  },
  POLYGON: {
    custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: networkType === "TESTNET" ? true : false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: true,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: true,
    },
  },
  TELOS: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: true,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
    },
  },
  FLARE: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: true,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
    },
  },
  CASPER: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: true,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
    },
  },
  BASE: {
    custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: true,
    },
  },
};

export const NATIVE_TOKENS = {
  POLYGON: {
    TESTNET: {
      name: "MUMBAI",
      symbol: "MATIC",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: polygonIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "POLYGON",
      symbol: "MATIC",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: polygonIcon,
      balance: 0,
      decimals: 18,
    },
  },
  ETHEREUM: {
    TESTNET: {
      name: "ETHERIUM",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: ethIcon,
      balance: 0,
      decimals: 18,
    },
  },
  BSC: {
    TESTNET: {
      name: "BINANCE",
      symbol: "BNB",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: binanceIcon,
      balance: 0,
      decimals: 18,
    },
  },
  TELOS: {
    TESTNET: {
      name: "TELOS",
      symbol: "TLS",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: telosIcon,
      balance: 0,
      decimals: 18,
    },
  },
  FLARE: {
    TESTNET: {
      name: "FLARE",
      symbol: "FLR",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: baseIcon,
      balance: 0,
      decimals: 18,
    },
  },
  BASE: {
    TESTNET: {
      name: "BASE",
      symbol: "BASE",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: baseIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "BASE",
      symbol: "BASE",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: baseIcon,
      balance: 0,
      decimals: 18,
    },
  },
};
