import React from "react";
import { Modal, CloseButton } from "react-bootstrap";



export default function NormaAlert({
    show,
    handleCloseParent,
    message,
    agreeAction
}) {
    //handle closing modal
    const handleClose = () => {
        handleCloseParent();
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="password-modal"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className=" m-5 text-center">{message}</Modal.Title>
                <CloseButton
                    className="btn-close-white"
                    onClick={handleClose}
                    style={{ position: "absolute", right: "20px", top: "20px" }}
                />
            </Modal.Header>
            <Modal.Footer  className="justify-content-around">
                <div className="d-flex mb-1">
                    <div className="fugu--portfolio-btn mx-4 ">
                        <button
                            className="fugu--outline-btn"
                            onClick={agreeAction}
                        >
                            <span>Confirm</span>
                        </button>
                    </div>
                    <div className="fugu--portfolio-btn">
                        <button
                            className="fugu--outline-btn"
                            onClick={handleClose}
                        >
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
