import { ethers, Contract, Signer } from 'ethers';
import toast, { Toaster } from 'react-hot-toast';
import {
  HORUS_FACTORY_CONTRACT_ADDRESS,
  HORUS_FACTORY_ABI,
  HORUS_NFT_ABI,
} from './HorusFactory_Constansts';
import { NFTS_PROXY_CONTRACT_ADDRESS } from '../nfts-proxy/NftsProxy_Constants';
import { signTransaction, hashWalletIds } from '../../utils/utils';
import { sendNewUserEmail } from '../../../../api/sendEmail';

import FormData from 'form-data';
import axios from 'axios';
import fetch from 'node-fetch';
import { writeToContractByWagmi, readFromContract } from '../../utils/utils';

export const createCollection_nonCustodial = async (
  network,
  collectionInfo,
  isLayered = false,
  isAILayered = false,
  isAILayersHasURI = false
) => {
  try {
    let uri;
    if (isLayered) {
      uri = await createLayeredBaseURI(collectionInfo);
    } else if (isAILayered) {
      uri = await createLayeredAIBaseURI(collectionInfo.layers);
    } else if (isAILayersHasURI) {
      uri = collectionInfo.confirmedURI;
    } else {
      uri = await createBaseURI(collectionInfo);
    }
    await writeToContractByWagmi(
      {
        address:
          HORUS_FACTORY_CONTRACT_ADDRESS[network.chain][
            process.env.REACT_APP_NETWORK_TYPE
          ],
        abi: HORUS_FACTORY_ABI,
      },
      {
        functionName: 'createCollection',
        args: [
          collectionInfo.name,
          collectionInfo.symbol,
          uri + '/',
          collectionInfo.maxNfts,
        ],
      },
      network.chain,
      'Creating Collection'
    );
  } catch (err) {
    console.log(err);
    toast.error('Create collection Failed');
  }
};

export const createCollection_custodial = async (
  walletId,
  walletIndex,
  network,
  collectionInfo,
  password,
  isLayered = false,
  isAILayered = false,
  isAILayersHasURI = false
) => {
  try {
    let uri;
    if (isLayered) {
      uri = await createLayeredBaseURI(collectionInfo);
    } else if (isAILayered) {
      uri = await createLayeredAIBaseURI(collectionInfo.layers);
    } else if (isAILayersHasURI) {
      uri = collectionInfo.confirmedURI;
    } else {
      uri = await createBaseURI(collectionInfo);
    }
    const tx = await signTransaction(
      walletId,
      walletIndex,
      network.chain,
      password,
      [
        {
          contractAddress:
            HORUS_FACTORY_CONTRACT_ADDRESS[network.chain][
              process.env.REACT_APP_NETWORK_TYPE
            ],
          abi: HORUS_FACTORY_ABI,
          functionName: 'createCollection',
          params: [
            collectionInfo.name,
            collectionInfo.symbol,
            uri + '/',
            collectionInfo.maxNfts,
          ],
        },
      ],
      'Create Collection'
    );
    console.log(tx);
  } catch (err) {
    console.log(err);
    toast.error('Create collection Failed');
    throw err;
  }
};

export const getCollections = async (network, address) => {
  const modifiedCollections = [];
  let collections = await readFromContract(
    {
      address:
        HORUS_FACTORY_CONTRACT_ADDRESS[network.chain][
          process.env.REACT_APP_NETWORK_TYPE
        ],
      abi: HORUS_FACTORY_ABI,
    },
    {
      functionName: 'getCollections',
      args: [address],
    },
    network
  );

  for (let i = 0; i < collections.length; i++) {
    const baseUri = collections[i].baseTokenURI;
    try {
      const response = await fetch(`${baseUri}0`);
      const json = await response.json();
      const currentNftBig = ethers.BigNumber.from(collections[i].currNFts);
      const currentMaxNft = ethers.BigNumber.from(collections[i].maxNfts);
      const modifiedCollection = {
        ...collections[i],
        image: json.image,
        contractAddress: collections[i][0],
        currNFts: currentNftBig.toNumber(),
        maxNfts: currentMaxNft.toNumber(),
      };
      modifiedCollections.push(modifiedCollection);
    } catch (error) {
      console.error(
        `Error fetching/parsing JSON for collection ${collections[i].id}: ${error.message}`
      );
    }
  }

  console.log('Modified Collections: ', modifiedCollections);
  return modifiedCollections;
};

export const airdrop_nonCustodial = async (
  collectionAddress,
  recipients,
  network
) => {
  try {
    await writeToContractByWagmi(
      {
        address: collectionAddress,
        abi: HORUS_NFT_ABI,
      },
      {
        functionName: 'airdrop',
        args: [recipients],
      },
      network.chain,
      'Airdrop NFTs'
    );
  } catch (err) {
    console.log(err);
    toast.error('Airdrop NFTs Failed');
  }
};

export const airdrop_custodial = async (
  walletId,
  walletIndex,
  network,
  collectionAddress,
  recipients,
  password
) => {
  try {
    const tx = await signTransaction(
      walletId,
      walletIndex,
      network.chain,
      password,
      [
        {
          contractAddress: collectionAddress,
          abi: HORUS_NFT_ABI,
          functionName: 'airdrop',
          params: [recipients],
        },
      ],
      'Airdrop NFTs'
    );
    tx && toast.success('Airdropped NFTs');
    console.log(tx);
    return tx;
  } catch (err) {
    console.log(err);
    toast.error('Airdrop NFTs Failed');
  }
};

export const depositNfts_custodial = async (
  walletId,
  walletIndex,
  network,
  collectionAddress,
  recepints,
  sendEmail,
  password
) => {
  try {
    const hashedWallets = hashWalletIds(recepints, network.chain);
    const tx = await signTransaction(
      walletId,
      walletIndex,
      network.chain,
      password,
      [
        {
          contractAddress: collectionAddress,
          abi: HORUS_NFT_ABI,
          functionName: 'depositNfts',
          params: [
            hashedWallets,
            NFTS_PROXY_CONTRACT_ADDRESS[network.chain][
              process.env.REACT_APP_NETWORK_TYPE
            ],
          ],
        },
      ],
      'Airdrop NFTs'
    );
    if (tx && sendEmail) {
      for (let i = 0; i < recepints.length; i++) {
        sendNewUserEmail(recepints[i], '', 'new NFT', 'NFT');
      }
    }

    return tx;
  } catch (err) {
    console.log(err);
    toast.error('Airdrop NFTs Failed');
  }
};

export const depositNfts_nonCustodial = async (
  network,
  collectionAddress,
  recepints,
  sendEmails
) => {
  try {
    const hashedWallets = hashWalletIds(recepints, network.chain);
    await writeToContractByWagmi(
      {
        address: collectionAddress,
        abi: HORUS_NFT_ABI,
      },
      {
        functionName: 'depositNfts',
        args: [
          hashedWallets,
          NFTS_PROXY_CONTRACT_ADDRESS[network.chain][
            process.env.REACT_APP_NETWORK_TYPE
          ],
        ],
      },
      network.chain,
      'Airdrop NFTs'
    );
    if (sendEmails) {
      for (let i = 0; i < recepints.length; i++) {
        sendNewUserEmail(recepints[i], '', 'new NFT', 'NFT');
      }
    }
  } catch (err) {
    console.log(err);
    toast.error('Airdrop NFTs Failed');
  }
};

// export const getCollectionNftsCount = async (collectionAddress) => {
//   const counter = await readFromContract(
//     {
//       address: collectionAddress,
//       abi: HORUS_NFT_ABI,
//     },
//     {
//       functionName: "getCurrentTokenId",
//       args: [],
//     },
//   );
//   const bigNumberValue = ethers.BigNumber.from(counter);
//   const readableCounterValue = bigNumberValue.toNumber();
//   return readableCounterValue;
// };

// export const getCollectionMaxNfts = async (collectionAddress) => {
//   const maxNfts = await readFromContract(
//     {
//       address: collectionAddress,
//       abi: HORUS_NFT_ABI,
//     },
//     {
//       functionName: "getMaxNfts",
//       args: [],
//     }
//   );
//   const bigNumberValue = ethers.BigNumber.from(maxNfts);
//   const readableMaxNftValue = bigNumberValue.toNumber();
//   return readableMaxNftValue;
// };

const createBaseURI = async (collectionInfo) => {
  const endpoint =
    'https://69fmb7tdif.execute-api.us-east-1.amazonaws.com/generateNFTs';

  let formData = new FormData();
  formData.append('file', collectionInfo.image);
  formData.append('maxNFTs', collectionInfo.maxNfts);
  let nftAttributes = {};
  for (let i = 0; i < collectionInfo.traits.length; i++) {
    nftAttributes[collectionInfo.traits[i].traitKey] =
      collectionInfo.traits[i].traitValue;
  }
  formData.append('nftAttributes', JSON.stringify(nftAttributes));

  console.log(formData);

  const uri = await axios({
    method: 'post',
    url: endpoint,
    data: formData,
    headers: {
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  });

  return uri.data.baseURI;
};

const createLayeredBaseURI = async (collectionInfo) => {
  const apiName = 'generateArtNfts';
  const url = `${process.env.REACT_APP_GENERATE_LAYERS_IMAGE_API}${apiName}`;
  let formData = new FormData();
  formData.append('file', collectionInfo.content);
  formData.append('layersInfo', JSON.stringify(collectionInfo.layersInfo));
  formData.append('format', JSON.stringify(collectionInfo.imageDimensions));
  formData.append('nftsCount', collectionInfo.maxNfts);

  try {
    const response = await axios({
      method: 'post',
      url: url,
      data: formData,
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    }).catch((e) => {
      console.log(e);
    });
    return response.data.result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const createLayeredAIBaseURI = async (collectionInfo) => {
  const apiName = 'generateArtNftsAI';
  const url = `${process.env.REACT_APP_GENERATE_LAYERS_IMAGE_API}${apiName}`;

  try {
    const response = await axios({
      method: 'post',
      url: url,
      data: JSON.stringify(collectionInfo),
      headers: {
        'Content-Type': `application/json`,
      },
    }).catch((e) => {
      console.log(e);
      throw e;
    });
    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createLayeredAIPreviewImages = async (collectionInfo) => {
  const apiName = 'generativeEntryPoint';
  const url = `${process.env.REACT_APP_GENERATE_LAYERS_IMAGE_API}${apiName}`;

  try {
    const response = await axios({
      method: 'post',
      url: url,
      data: JSON.stringify(collectionInfo),
      headers: {
        'Content-Type': `application/json`,
      },
    }).catch((e) => {
      console.log(e);
      throw e;
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getImageCreationStatus = async (ApiData) => {
  const apiName = 'getGenerativeResult';
  const url = `${process.env.REACT_APP_GENERATE_LAYERS_IMAGE_API}${apiName}`;

  try {
    const response = await axios({
      method: 'post',
      url: url,
      data: JSON.stringify(ApiData),
      headers: {
        'Content-Type': `application/json`,
      },
    }).catch((e) => {
      console.log(e);
      throw e;
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const RegenerateSpecificAIImage = async (imageInfo) => {
  const apiName = 'changeImage';
  const url = `${process.env.REACT_APP_GENERATE_LAYERS_IMAGE_API}${apiName}`;

  try {
    const response = await axios({
      method: 'post',
      url: url,
      data: JSON.stringify(imageInfo),
      headers: {
        'Content-Type': `application/json`,
      },
    }).catch((e) => {
      console.log(e);
      throw e;
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const ConfirmGeneratingCollection = async (collectionInfo) => {
  const apiName = 'finalizeGenerativeRequest';
  const url = `${process.env.REACT_APP_GENERATE_LAYERS_IMAGE_API}${apiName}`;

  try {
    const response = await axios({
      method: 'post',
      url: url,
      data: JSON.stringify(collectionInfo),
      headers: {
        'Content-Type': `application/json`,
      },
    }).catch((e) => {
      console.log(e);
      throw e;
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
